@charset "UTF-8";

body {
  width: 100% !important;
}

body .hs-menu-level-select .module-menu::after,
body .hs-refresh-text::before,
body .hs-search .gender.female::after,
body .hs-select .next a::after,
body .hs-select .prev a::after,
body .hs-select [class^="module-"]::after,
body .hs-timeline .hs-timeline-overtime .hs-button::after,
body .hs-timeline-normal .hs-button::after,
body .jArrowNext::after,
body .jArrowPrev::after,
body .jSlideVertical .jArrowNext::after,
body .jSlideVertical .jArrowPrev::after,
body .module-calendar-extended .entry:not([data-rounds="1"]) .space-arrow::after,
body .tablesorter-header:not(.sorter-false)::after,
body .tablesorter-header:not(.sorter-false)::before {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body article,
body aside,
body details,
body figcaption,
body figure,
body footer,
body header,
body main,
body menu,
body nav,
body section,
body summary {
  display: block;
  position: relative;
}

body audio,
body canvas,
body progress,
body video {
  display: inline-block;
}

body audio:not([controls]) {
  display: none;
  height: 0;
}

body progress {
  vertical-align: baseline;
}

body [hidden],
body template {
  display: none;
}

body a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

body a:active,
body a:hover {
  outline-width: 0;
}

body abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

body b,
body strong {
  font-weight: inherit;
}

body b,
body strong {
  font-weight: bolder;
}

body dfn {
  font-style: italic;
}

body h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

body mark {
  background-color: #ff0;
  color: #000;
}

body small {
  font-size: 80%;
}

body sub,
body sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

body sub {
  bottom: -0.25em;
}

body sup {
  top: -0.5em;
}

body img {
  border-style: none;
  max-width: 100%;
  vertical-align: middle;
}

body svg:not(:root) {
  overflow: hidden;
}

body code,
body kbd,
body pre,
body samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

body figure {
  margin: 1em 40px;
}

body hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

body button,
body input,
body optgroup,
body select,
body textarea {
  font: inherit;
  margin: 0;
}

body optgroup {
  font-weight: 700;
}

body button,
body input {
  overflow: visible;
}

body button,
body select {
  text-transform: none;
}

body [type="button"],
body [type="reset"],
body [type="submit"],
body button {
  -webkit-appearance: button;
}

body [type="button"]::-moz-focus-inner,
body [type="reset"]::-moz-focus-inner,
body [type="submit"]::-moz-focus-inner,
body button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

body [type="button"]:-moz-focusring,
body [type="reset"]:-moz-focusring,
body [type="submit"]:-moz-focusring,
body button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

body fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

body legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

body textarea {
  overflow: auto;
}

body [type="checkbox"],
body [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

body [type="number"]::-webkit-inner-spin-button,
body [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

body [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

body [type="search"]::-webkit-search-cancel-button,
body [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

body ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

body ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

body .hs-grid {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-gap: var(--grid-row-gap) var(--grid-col-gap);
}

body .hs-grid > :not(.hs-grid--item) {
  grid-column: 1/-1;
}

body .hs-grid--item {
  grid-column: span 4;
  min-width: 0;
  min-height: 0;
}

body .hs-grid--item:empty {
  display: none;
}

body .hs-col-all {
  grid-column: 1/-1;
}

body .hs-row-all {
  grid-row: 1/-1;
}

body .hs-grid--item-12 {
  grid-column: 1/-1;
}

body .hs-col-12 {
  grid-column: span 12;
}

body .hs-row-12 {
  grid-row: span 12;
}

body .hs-grid--item-11 {
  grid-column: span 11;
}

body .hs-col-11 {
  grid-column: span 11;
}

body .hs-row-11 {
  grid-row: span 11;
}

body .hs-grid--item-10 {
  grid-column: span 10;
}

body .hs-col-10 {
  grid-column: span 10;
}

body .hs-row-10 {
  grid-row: span 10;
}

body .hs-grid--item-9 {
  grid-column: span 9;
}

body .hs-col-9 {
  grid-column: span 9;
}

body .hs-row-9 {
  grid-row: span 9;
}

body .hs-grid--item-8 {
  grid-column: span 8;
}

body .hs-col-8 {
  grid-column: span 8;
}

body .hs-row-8 {
  grid-row: span 8;
}

body .hs-grid--item-7 {
  grid-column: span 7;
}

body .hs-col-7 {
  grid-column: span 7;
}

body .hs-row-7 {
  grid-row: span 7;
}

body .hs-grid--item-6 {
  grid-column: span 6;
}

body .hs-col-6 {
  grid-column: span 6;
}

body .hs-row-6 {
  grid-row: span 6;
}

body .hs-grid--item-5 {
  grid-column: span 5;
}

body .hs-col-5 {
  grid-column: span 5;
}

body .hs-row-5 {
  grid-row: span 5;
}

body .hs-grid--item-4 {
  grid-column: span 4;
}

body .hs-col-4 {
  grid-column: span 4;
}

body .hs-row-4 {
  grid-row: span 4;
}

body .hs-grid--item-3 {
  grid-column: span 3;
}

body .hs-col-3 {
  grid-column: span 3;
}

body .hs-row-3 {
  grid-row: span 3;
}

body .hs-grid--item-2 {
  grid-column: span 2;
}

body .hs-col-2 {
  grid-column: span 2;
}

body .hs-row-2 {
  grid-row: span 2;
}

body .hs-grid--item-1 {
  grid-column: span 1;
}

body .hs-col-1 {
  grid-column: span 1;
}

body .hs-row-1 {
  grid-row: span 1;
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-12 {
    grid-column: span 12;
  }

  body .hs-col-xl-12 {
    grid-column: span 12;
  }

  body .hs-row-xl-12 {
    grid-row: span 12;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-11 {
    grid-column: span 11;
  }

  body .hs-col-xl-11 {
    grid-column: span 11;
  }

  body .hs-row-xl-11 {
    grid-row: span 11;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-10 {
    grid-column: span 10;
  }

  body .hs-col-xl-10 {
    grid-column: span 10;
  }

  body .hs-row-xl-10 {
    grid-row: span 10;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-9 {
    grid-column: span 9;
  }

  body .hs-col-xl-9 {
    grid-column: span 9;
  }

  body .hs-row-xl-9 {
    grid-row: span 9;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-8 {
    grid-column: span 8;
  }

  body .hs-col-xl-8 {
    grid-column: span 8;
  }

  body .hs-row-xl-8 {
    grid-row: span 8;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-7 {
    grid-column: span 7;
  }

  body .hs-col-xl-7 {
    grid-column: span 7;
  }

  body .hs-row-xl-7 {
    grid-row: span 7;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-6 {
    grid-column: span 6;
  }

  body .hs-col-xl-6 {
    grid-column: span 6;
  }

  body .hs-row-xl-6 {
    grid-row: span 6;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-5 {
    grid-column: span 5;
  }

  body .hs-col-xl-5 {
    grid-column: span 5;
  }

  body .hs-row-xl-5 {
    grid-row: span 5;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-4 {
    grid-column: span 4;
  }

  body .hs-col-xl-4 {
    grid-column: span 4;
  }

  body .hs-row-xl-4 {
    grid-row: span 4;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-3 {
    grid-column: span 3;
  }

  body .hs-col-xl-3 {
    grid-column: span 3;
  }

  body .hs-row-xl-3 {
    grid-row: span 3;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-2 {
    grid-column: span 2;
  }

  body .hs-col-xl-2 {
    grid-column: span 2;
  }

  body .hs-row-xl-2 {
    grid-row: span 2;
  }
}

@media screen and (max-width: 1152px) {
  body .hs-grid--item-xl-1 {
    grid-column: span 1;
  }

  body .hs-col-xl-1 {
    grid-column: span 1;
  }

  body .hs-row-xl-1 {
    grid-row: span 1;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-12 {
    grid-column: span 12;
  }

  body .hs-col-l-12 {
    grid-column: span 12;
  }

  body .hs-row-l-12 {
    grid-row: span 12;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-11 {
    grid-column: span 11;
  }

  body .hs-col-l-11 {
    grid-column: span 11;
  }

  body .hs-row-l-11 {
    grid-row: span 11;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-10 {
    grid-column: span 10;
  }

  body .hs-col-l-10 {
    grid-column: span 10;
  }

  body .hs-row-l-10 {
    grid-row: span 10;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-9 {
    grid-column: span 9;
  }

  body .hs-col-l-9 {
    grid-column: span 9;
  }

  body .hs-row-l-9 {
    grid-row: span 9;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-8 {
    grid-column: span 8;
  }

  body .hs-col-l-8 {
    grid-column: span 8;
  }

  body .hs-row-l-8 {
    grid-row: span 8;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-7 {
    grid-column: span 7;
  }

  body .hs-col-l-7 {
    grid-column: span 7;
  }

  body .hs-row-l-7 {
    grid-row: span 7;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-6 {
    grid-column: span 6;
  }

  body .hs-col-l-6 {
    grid-column: span 6;
  }

  body .hs-row-l-6 {
    grid-row: span 6;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-5 {
    grid-column: span 5;
  }

  body .hs-col-l-5 {
    grid-column: span 5;
  }

  body .hs-row-l-5 {
    grid-row: span 5;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-4 {
    grid-column: span 4;
  }

  body .hs-col-l-4 {
    grid-column: span 4;
  }

  body .hs-row-l-4 {
    grid-row: span 4;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-3 {
    grid-column: span 3;
  }

  body .hs-col-l-3 {
    grid-column: span 3;
  }

  body .hs-row-l-3 {
    grid-row: span 3;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-2 {
    grid-column: span 2;
  }

  body .hs-col-l-2 {
    grid-column: span 2;
  }

  body .hs-row-l-2 {
    grid-row: span 2;
  }
}

@media screen and (max-width: 960px) {
  body .hs-grid--item-l-1 {
    grid-column: span 1;
  }

  body .hs-col-l-1 {
    grid-column: span 1;
  }

  body .hs-row-l-1 {
    grid-row: span 1;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-12 {
    grid-column: span 12;
  }

  body .hs-col-m-12 {
    grid-column: span 12;
  }

  body .hs-row-m-12 {
    grid-row: span 12;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-11 {
    grid-column: span 11;
  }

  body .hs-col-m-11 {
    grid-column: span 11;
  }

  body .hs-row-m-11 {
    grid-row: span 11;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-10 {
    grid-column: span 10;
  }

  body .hs-col-m-10 {
    grid-column: span 10;
  }

  body .hs-row-m-10 {
    grid-row: span 10;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-9 {
    grid-column: span 9;
  }

  body .hs-col-m-9 {
    grid-column: span 9;
  }

  body .hs-row-m-9 {
    grid-row: span 9;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-8 {
    grid-column: span 8;
  }

  body .hs-col-m-8 {
    grid-column: span 8;
  }

  body .hs-row-m-8 {
    grid-row: span 8;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-7 {
    grid-column: span 7;
  }

  body .hs-col-m-7 {
    grid-column: span 7;
  }

  body .hs-row-m-7 {
    grid-row: span 7;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-6 {
    grid-column: span 6;
  }

  body .hs-col-m-6 {
    grid-column: span 6;
  }

  body .hs-row-m-6 {
    grid-row: span 6;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-5 {
    grid-column: span 5;
  }

  body .hs-col-m-5 {
    grid-column: span 5;
  }

  body .hs-row-m-5 {
    grid-row: span 5;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-4 {
    grid-column: span 4;
  }

  body .hs-col-m-4 {
    grid-column: span 4;
  }

  body .hs-row-m-4 {
    grid-row: span 4;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-3 {
    grid-column: span 3;
  }

  body .hs-col-m-3 {
    grid-column: span 3;
  }

  body .hs-row-m-3 {
    grid-row: span 3;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-2 {
    grid-column: span 2;
  }

  body .hs-col-m-2 {
    grid-column: span 2;
  }

  body .hs-row-m-2 {
    grid-row: span 2;
  }
}

@media screen and (max-width: 768px) {
  body .hs-grid--item-m-1 {
    grid-column: span 1;
  }

  body .hs-col-m-1 {
    grid-column: span 1;
  }

  body .hs-row-m-1 {
    grid-row: span 1;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-12 {
    grid-column: span 12;
  }

  body .hs-col-s-12 {
    grid-column: span 12;
  }

  body .hs-row-s-12 {
    grid-row: span 12;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-11 {
    grid-column: span 11;
  }

  body .hs-col-s-11 {
    grid-column: span 11;
  }

  body .hs-row-s-11 {
    grid-row: span 11;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-10 {
    grid-column: span 10;
  }

  body .hs-col-s-10 {
    grid-column: span 10;
  }

  body .hs-row-s-10 {
    grid-row: span 10;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-9 {
    grid-column: span 9;
  }

  body .hs-col-s-9 {
    grid-column: span 9;
  }

  body .hs-row-s-9 {
    grid-row: span 9;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-8 {
    grid-column: span 8;
  }

  body .hs-col-s-8 {
    grid-column: span 8;
  }

  body .hs-row-s-8 {
    grid-row: span 8;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-7 {
    grid-column: span 7;
  }

  body .hs-col-s-7 {
    grid-column: span 7;
  }

  body .hs-row-s-7 {
    grid-row: span 7;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-6 {
    grid-column: span 6;
  }

  body .hs-col-s-6 {
    grid-column: span 6;
  }

  body .hs-row-s-6 {
    grid-row: span 6;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-5 {
    grid-column: span 5;
  }

  body .hs-col-s-5 {
    grid-column: span 5;
  }

  body .hs-row-s-5 {
    grid-row: span 5;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-4 {
    grid-column: span 4;
  }

  body .hs-col-s-4 {
    grid-column: span 4;
  }

  body .hs-row-s-4 {
    grid-row: span 4;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-3 {
    grid-column: span 3;
  }

  body .hs-col-s-3 {
    grid-column: span 3;
  }

  body .hs-row-s-3 {
    grid-row: span 3;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-2 {
    grid-column: span 2;
  }

  body .hs-col-s-2 {
    grid-column: span 2;
  }

  body .hs-row-s-2 {
    grid-row: span 2;
  }
}

@media screen and (max-width: 640px) {
  body .hs-grid--item-s-1 {
    grid-column: span 1;
  }

  body .hs-col-s-1 {
    grid-column: span 1;
  }

  body .hs-row-s-1 {
    grid-row: span 1;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-12 {
    grid-column: span 12;
  }

  body .hs-col-xs-12 {
    grid-column: span 12;
  }

  body .hs-row-xs-12 {
    grid-row: span 12;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-11 {
    grid-column: span 11;
  }

  body .hs-col-xs-11 {
    grid-column: span 11;
  }

  body .hs-row-xs-11 {
    grid-row: span 11;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-10 {
    grid-column: span 10;
  }

  body .hs-col-xs-10 {
    grid-column: span 10;
  }

  body .hs-row-xs-10 {
    grid-row: span 10;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-9 {
    grid-column: span 9;
  }

  body .hs-col-xs-9 {
    grid-column: span 9;
  }

  body .hs-row-xs-9 {
    grid-row: span 9;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-8 {
    grid-column: span 8;
  }

  body .hs-col-xs-8 {
    grid-column: span 8;
  }

  body .hs-row-xs-8 {
    grid-row: span 8;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-7 {
    grid-column: span 7;
  }

  body .hs-col-xs-7 {
    grid-column: span 7;
  }

  body .hs-row-xs-7 {
    grid-row: span 7;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-6 {
    grid-column: span 6;
  }

  body .hs-col-xs-6 {
    grid-column: span 6;
  }

  body .hs-row-xs-6 {
    grid-row: span 6;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-5 {
    grid-column: span 5;
  }

  body .hs-col-xs-5 {
    grid-column: span 5;
  }

  body .hs-row-xs-5 {
    grid-row: span 5;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-4 {
    grid-column: span 4;
  }

  body .hs-col-xs-4 {
    grid-column: span 4;
  }

  body .hs-row-xs-4 {
    grid-row: span 4;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-3 {
    grid-column: span 3;
  }

  body .hs-col-xs-3 {
    grid-column: span 3;
  }

  body .hs-row-xs-3 {
    grid-row: span 3;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-2 {
    grid-column: span 2;
  }

  body .hs-col-xs-2 {
    grid-column: span 2;
  }

  body .hs-row-xs-2 {
    grid-row: span 2;
  }
}

@media screen and (max-width: 420px) {
  body .hs-grid--item-xs-1 {
    grid-column: span 1;
  }

  body .hs-col-xs-1 {
    grid-column: span 1;
  }

  body .hs-row-xs-1 {
    grid-row: span 1;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-12 {
    grid-column: span 12;
  }

  body .hs-col-xxs-12 {
    grid-column: span 12;
  }

  body .hs-row-xxs-12 {
    grid-row: span 12;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-11 {
    grid-column: span 11;
  }

  body .hs-col-xxs-11 {
    grid-column: span 11;
  }

  body .hs-row-xxs-11 {
    grid-row: span 11;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-10 {
    grid-column: span 10;
  }

  body .hs-col-xxs-10 {
    grid-column: span 10;
  }

  body .hs-row-xxs-10 {
    grid-row: span 10;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-9 {
    grid-column: span 9;
  }

  body .hs-col-xxs-9 {
    grid-column: span 9;
  }

  body .hs-row-xxs-9 {
    grid-row: span 9;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-8 {
    grid-column: span 8;
  }

  body .hs-col-xxs-8 {
    grid-column: span 8;
  }

  body .hs-row-xxs-8 {
    grid-row: span 8;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-7 {
    grid-column: span 7;
  }

  body .hs-col-xxs-7 {
    grid-column: span 7;
  }

  body .hs-row-xxs-7 {
    grid-row: span 7;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-6 {
    grid-column: span 6;
  }

  body .hs-col-xxs-6 {
    grid-column: span 6;
  }

  body .hs-row-xxs-6 {
    grid-row: span 6;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-5 {
    grid-column: span 5;
  }

  body .hs-col-xxs-5 {
    grid-column: span 5;
  }

  body .hs-row-xxs-5 {
    grid-row: span 5;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-4 {
    grid-column: span 4;
  }

  body .hs-col-xxs-4 {
    grid-column: span 4;
  }

  body .hs-row-xxs-4 {
    grid-row: span 4;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-3 {
    grid-column: span 3;
  }

  body .hs-col-xxs-3 {
    grid-column: span 3;
  }

  body .hs-row-xxs-3 {
    grid-row: span 3;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-2 {
    grid-column: span 2;
  }

  body .hs-col-xxs-2 {
    grid-column: span 2;
  }

  body .hs-row-xxs-2 {
    grid-row: span 2;
  }
}

@media screen and (max-width: 319px) {
  body .hs-grid--item-xxs-1 {
    grid-column: span 1;
  }

  body .hs-col-xxs-1 {
    grid-column: span 1;
  }

  body .hs-row-xxs-1 {
    grid-row: span 1;
  }
}

body .hs-flex {
  display: flex;
}

body .hs-hide {
  display: none;
}

body .hs-show {
  display: block;
}

@media screen and (max-width: 1152px) {
  body .hs-show-xl {
    display: block;
  }

  body .hs-show-xl.hs-flex {
    display: flex;
  }

  body .hs-show-xl.hs-grid {
    display: grid;
  }

  body .hs-hide-xl {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  body .hs-show-l {
    display: block;
  }

  body .hs-show-l.hs-flex {
    display: flex;
  }

  body .hs-show-l.hs-grid {
    display: grid;
  }

  body .hs-hide-l {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  body .hs-show-m {
    display: block;
  }

  body .hs-show-m.hs-flex {
    display: flex;
  }

  body .hs-show-m.hs-grid {
    display: grid;
  }

  body .hs-hide-m {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  body .hs-show-s {
    display: block;
  }

  body .hs-show-s.hs-flex {
    display: flex;
  }

  body .hs-show-s.hs-grid {
    display: grid;
  }

  body .hs-hide-s {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  body .hs-show-xs {
    display: block;
  }

  body .hs-show-xs.hs-flex {
    display: flex;
  }

  body .hs-show-xs.hs-grid {
    display: grid;
  }

  body .hs-hide-xs {
    display: none;
  }
}

@media screen and (max-width: 319px) {
  body .hs-show-xxs {
    display: block;
  }

  body .hs-show-xxs.hs-flex {
    display: flex;
  }

  body .hs-show-xxs.hs-grid {
    display: grid;
  }

  body .hs-hide-xxs {
    display: none;
  }
}

body {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  color: #3c3c3b;
  /*font-family: "Source Sans Pro", sans-serif;*/
  font-size: 1.4rem;
  line-height: 1.4;
  overflow: auto;
  margin: 0 auto;
}

body * {
  box-sizing: border-box;
}

body td,
body th {
  box-sizing: content-box;
}

@media screen and (max-width: 319px) {
  body {
    width: 319px;
  }
}

body .hs-has-link,
body a {
  cursor: pointer;
}

body a {
  color: #3c3c3b;
  text-decoration: none;
}

body a:hover {
  color: #595959;
}

body b,
body strong {
  font-weight: 600;
}

body [class^="module-"] {
  height: auto;
  width: 100%;
}

body [class^="module-"]:empty {
  display: none;
}

body .hs-block {
  margin-top: 5px;
}

body [class^="hs-block-"] {
  position: relative;
}

body .hs-more_link {
  color: #3c3c3b;
  font-weight: 700;
  font-style: italic;
  margin-top: 20px;
}

body .hs-display-none {
  display: none;
}

body .hs-comment {
  font-size: 12px;
  margin-top: 10px;
}

body iframe {
  border: none;
}

#hs-content {
  clear: left;
  float: left;
  background: #fff;
  /* width: calc(100% - 320px); */
  width: 100%;
  padding: 32px 20px 32px 20px;
  z-index: 3;
}

@media screen and (max-width: 768px) {
  #hs-content {
    float: none;
    padding-right: 20px;
    width: 100%;
  }
}

#hs-sidebar {
  /* float: left; */
  background: #fff;
  /* width: 320px; */
  padding: 32px 20px 32px 20px;
  width: 100%;
  float: none;
  margin: 0;
}

@media screen and (max-width: 768px) {
  #hs-sidebar {
    width: 100%;
    float: none;
    margin: 0;
  }
}

#hs-sidebar:empty {
  display: none;
}

#hs-content > .hs-block:first-child,
#hs-sidebar > .hs-block:first-child {
  margin-top: 0;
}

#hs-content > .hs-block:first-child h2,
#hs-sidebar > .hs-block:first-child h2 {
  margin-top: 0;
}

body .person-image .playerCompetitionStatisticsImage {
  object-fit: cover !important;
  object-position: top !important;
  border-style: solid !important;
  border-color: #f4c400 !important;
  border-width: medium !important;
  border-radius: 50%;
}

body .person-image .teamCompetitionStatisticsImage {
  border-radius: 0 !important;
}

body .player-related-element-news-image {
  object-fit: cover !important;
  object-position: top !important;
  border-style: solid !important;
  border-color: #f4c400 !important;
  border-width: medium !important;
  border-radius: 50%;
}

.related-element-news-row {
  display: flex;
  margin-bottom: 10px;
  margin-left: 10px;
}

.related-element-news {
  display: flex;
  align-items: center;
}

.related-element-news-text {
  font-size: 1.6rem;
  font-weight: 600;
  margin-left: 15px;
}

body .person-flag img,
body .person_country-flag img {
  width: auto;
  height: auto;
}

body .college-autoimage img,
body .team-autoimage img,
body .team-flag img,
body .team-image img {
  width: auto;
  height: auto;
}

body .country-flag img {
  width: auto;
  height: auto;
}

body .company-logo img {
  width: auto;
  height: auto;
}

body .person-image {
  width: 65px;
}

body .person-image-home {
  display: flex;
  align-items: center;
  justify-content: center;
}

body .person-image-home {
  display: flex;
}

body .person-image-home a {
  width: 68px;
  height: 68px;
  display: grid;
}

body .team-image-home-list {
  width: 55px;
}

body .team-image-home-list a {
  width: 40px;
  height: 40px;
  display: grid;
}

body .person-image:not(th)::before {
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 125%;
}

body .person-image:not(th)::after {
  content: "";
  display: table;
  clear: both;
}

body .person-flag,
body .person_country-flag {
  width: 30px;
}

body .college-autoimage,
body .team-autoimage,
body .team-flag,
body .team-image {
  width: 30px;
}

body .country-flag {
  width: 70px;
}

body .company-logo {
  width: 40px;
}

body li,
body ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

body dl {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

/* body dd,
body dt {
  flex-grow: 1;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 50%;
} */

body dt {
  font-weight: 400;
}

/* body dd {
  padding: 7px 10px;
  text-align: center;
  width: 35%;
} */

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-weight: 600;
  margin: 0;
  padding: 0;
}

/*body h1 {*/
/*  text-transform: uppercase;*/
/*}*/

body .hs-block-header {
  background: #3c3c3b;
  color: #fff;
  margin: 0 0 5px;
  padding: 7px;
  font-size: 26px;
  border-radius: 5px;
}

body header {
  background: #3c3c3b;
}

body .hs-page-header {
  background: #fff;
}

body footer {
  clear: both;
  z-index: 1;
}

body.hs-widget {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 319px) {
  body.hs-widget {
    width: 100%;
  }
}

body.hs-widget #hs-content {
  float: none;
  clear: none;
  width: 100%;
}

body .hs-block-header {
  margin-top: 0;
}

body .hs-block-header:empty {
  display: none;
}

body[style*="height:"] {
  overflow: hidden;
}

body[style*="height:"] [id^="hs-widget-"] {
  overflow: hidden;
}

body [id^="hs-widget-"] {
  height: 100%;
}

body table {
  width: 100%;
  position: relative;
  clear: both;
  border-collapse: collapse;
  color: #3c3c3b;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
}

body .hs-table-fixed table {
  table-layout: fixed;
}

body caption {
  height: 26px;
  color: #6c6c6c;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
}

body tr:first-child td {
  border-top: none;
}

body tr:last-child td {
  border-bottom: none;
}

body tr.lastelement td {
  border-bottom: none;
}

body tr .company-name,
body tr .company-shortname,
body tr .company_person-firstname,
body tr .company_person-name,
body tr .company_person-surname,
body tr .competition-name,
body tr .competition-name_extended,
body tr .competition-shortname,
body tr .competition-shortname_extended,
body tr .competition-type,
body tr .competition-type_named,
body tr .contract-name,
body tr .country,
body tr .country-code,
body tr .country-name,
body tr .event,
body tr .federation-name,
body tr .honour-name,
body tr .hs-winner,
body tr .industry-name,
body tr .industry-shortname,
body tr .match-incident,
body tr .name,
body tr .person,
body tr .person-firstname,
body tr .person-fullname,
body tr .person-name,
body tr .person-surname,
body tr .person_country-code,
body tr .person_country-name,
body tr .sport-name,
body tr .team,
body tr .team-name,
body tr .team-name_extended,
body tr .team-shortname,
body tr .team-shortname_extended,
body tr .team_detail-place,
body tr .winner-shortname,
body tr > .address-town {
  text-align: left;
}

body tr > .honour-name:first-child {
  text-align: center;
}

body tr .points,
body tr .standing-points,
body tr > .match_result-display {
  text-align: right;
}

body tr .person_stats-rank,
body tr [class*="standing-"] {
  width: 30px;
}

body tr .position-shortname,
body tr .role-shortname {
  width: 45px;
}

body tr .standing-goaldiff {
  width: 50px;
}

body tr .team_person-period {
  width: 110px;
}

body tr .honour-name:first-child,
body tr .team_person-period-start {
  width: 70px;
}

body th {
  position: relative;
  height: 26px;
  color: #6c6c6c;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
}

@-moz-document url-prefix() {
  body th {
    background-clip: padding-box;
  }
}

body th.college-autoimage,
body th.team-autoimage,
body th.team-flag {
  color: transparent;
}

body th.empty {
  text-align: center;
  height: 36px;
}

body td {
  position: relative;
  height: 36px;
  padding: 0 2px;
  border-bottom: 1px solid #d6d6d6;
  text-align: center;
  vertical-align: middle;
}

@-moz-document url-prefix() {
  body td {
    background-clip: padding-box;
  }
}

body .hs-breadcrumb {
  list-style-type: none;
  overflow: auto;
  padding: 8px 5px;
  margin: 0;
  color: #787878;
  font-size: 1.2rem;
  line-height: 1.1;
}

body .hs-breadcrumb a {
  color: #787878;
  text-decoration: none;
}

body .hs-breadcrumb li {
  float: left;
}

body .hs-breadcrumb li:not(:last-child)::after {
  bottom: 1px;
  content: "»";
  padding: 0 5px 0 7px;
  position: relative;
}

body .hs-calendar .country-flag {
  width: 30px;
}

body .hs-calendar .season-period,
body .hs-calendar [class*="match-date"],
body .hs-calendar [class*="match-shortdate"] {
  white-space: nowrap;
}

body .hs-calendar .match-incident {
  color: red;
}

body .module-calendar-extended .match-for-competition {
  display: none;
}

body .module-calendar-extended .match-for-competition .round-name {
  text-align: left;
}

body .module-calendar-extended .match-for-competition .space:last-child {
  padding-left: 0;
  padding-right: 0;
}

body .module-calendar-extended .space-arrow {
  border-left: none;
  padding-left: 0;
  padding-right: 0;
  width: 0;
}

body .module-calendar-extended .entry:not([data-rounds="1"]) {
  cursor: pointer;
}

body .module-calendar-extended .entry:not([data-rounds="1"]) .space-arrow::after {
  content: "";
  color: #d6d6d6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  right: 0;
  transition: 0.3s transform linear;
}

body .module-calendar-extended .entry:not([data-rounds="1"]) .space-arrow:hover::after {
  color: #59994b;
}

body .module-calendar-extended .entry:not([data-rounds="1"]).row-extended .space-arrow::after {
  transform: translateY(-50%) rotate(-90deg);
}

body .hs-calendar .competition-name + .competition-shortname,
body .hs-calendar .match-incident,
body .hs-calendar .space-headline,
body .hs-calendar [data-incident] .hs-winner:empty {
  display: none;
}

body .hs-calendar [data-incident] .hs-winner:empty + .match-incident {
  display: table-cell;
}

@media screen and (max-width: 640px) {
  body .hs-calendar .competition-name + .competition-shortname,
  body .hs-calendar thead .space-headline {
    display: table-cell;
  }
}

@media screen and (max-width: 640px) {
  body .hs-calendar .competition-name,
  body .hs-calendar .finished [class*="date"],
  body .hs-calendar thead .match-shortdate,
  body .hs-calendar thead .season-period,
  body .hs-calendar thead .winner-shortname {
    display: none;
  }
}

body #hs-sidebar .hs-calendar .competition-name + .competition-shortname,
body #hs-sidebar .hs-calendar thead .space-headline {
  display: table-cell;
}

body #hs-sidebar .hs-calendar .competition-name,
body #hs-sidebar .hs-calendar .finished .hs-winner,
body #hs-sidebar .hs-calendar .finished [class*="date"],
body #hs-sidebar .hs-calendar thead .match-shortdate,
body #hs-sidebar .hs-calendar thead .season-period,
body #hs-sidebar .hs-calendar thead .winner-shortname {
  display: none;
}

body .dailymotion-container iframe,
body .facebook-container iframe,
body .gfycat-container iframe,
body .giphy-container iframe,
body .instagram-container iframe,
body .vimeo-container iframe,
body .vine-container iframe {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

body .dailymotion-container,
body .gfycat-container,
body .giphy-container,
body .streamable-container,
body .vidme-container,
body .vimeo-container,
body .vine-container,
body .youtube-container {
  width: 100%;
  display: block;
  position: relative;
}

body .dailymotion-container::after,
body .gfycat-container::after,
body .giphy-container::after,
body .streamable-container::after,
body .vidme-container::after,
body .vimeo-container::after,
body .vine-container::after,
body .youtube-container::after {
  display: block;
  content: "";
}

body .dailymotion-container iframe,
body .gfycat-container iframe,
body .giphy-container iframe,
body .streamable-container iframe,
body .vidme-container iframe,
body .vimeo-container iframe,
body .vine-container iframe,
body .youtube-container iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

body .vine-container {
  max-width: 600px;
  margin: 0 auto;
}

body .vine-container::after {
  padding-top: 100%;
}

body .dailymotion-container::after,
body .gfycat-container::after,
body .giphy-container::after,
body .streamable-container::after,
body .vidme-container::after,
body .vimeo-container::after,
body .youtube-container::after {
  padding-top: 56.25%;
}

body .hs-image-container > *,
body .hs-socialmedia-container > * {
  margin-left: auto;
  margin-right: auto;
}

body .hs-field-dummy .hs-field {
  background: #529900;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

body .hs-field-dummy .hs-field div {
  flex: 1 0 auto;
}

body .hs-field-dummy .hs-field div:nth-child(odd) {
  background: #5bab00;
}

body .hs-field-dummy .hs-center-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  width: 80px;
  height: 80px;
  border: 2px solid #fff;
  border-radius: 50%;
}

body .hs-field-dummy .hs-center-line {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 2px;
  margin-left: -1px;
  background: #fff;
}

body .hs-field-dummy .hs-center-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3px 0 0 -3px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

body .hs-field-dummy .hs-sideline {
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  z-index: 3;
}

body .hs-field-dummy .attackzone,
body .hs-field-dummy .goalzone,
body .hs-field-dummy .outer-circle,
body .hs-field-dummy .penaltydot {
  position: absolute;
}

body .hs-field-dummy .attackzone,
body .hs-field-dummy .goalzone {
  border: 2px solid #fff;
  border-top: none;
  width: 60%;
  height: 15%;
  left: 20%;
}

body .hs-field-dummy .goalzone {
  width: 60%;
  height: 30%;
  left: 20%;
}

body .hs-field-dummy .penaltydot {
  border-radius: 50%;
  background: #fff;
  bottom: 25%;
  height: 6px;
  width: 6px;
  left: 50%;
  margin-left: -3px;
}

body .hs-field-dummy .outer-circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border-bottom: 2px solid #fff;
  bottom: -30px;
  left: 50%;
  margin-left: -30px;
}

body .hs-field-dummy .attackzone.home {
  top: 0;
}

body .hs-field-dummy .attackzone.away {
  bottom: 0;
  transform: rotate(-180deg);
}

body .hs-gameplan .hs-head > div {
  background: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  border-radius: 5px;
}

body .hs-gameplan .hs-head .match-sport {
  color: #000;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 40px 0 0;
  padding: 0.76em 0;
}

body .hs-gameplan .hs-head .match-competition {
  text-align: left;
  margin: 20px 0 0;
  padding: 0 10px;
}

body .hs-gameplan .hs-head .match-round {
  color: #000;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: left;
  margin: 20px 0 0;
  padding: 0.76em 10px;
}

body .hs-gameplan .hs-head .match-date {
  background: 0 0;
  color: #767676;
  font-size: 1.3rem;
  line-height: 40px;
  text-align: left;
  margin: 0 0 -40px;
  padding: 0 15px;
  z-index: 2;
}

@media screen and (max-width: 640px) {
  body .hs-gameplan .hs-head .match-date {
    margin: 20px 0 0;
  }
}

body .hs-gameplan .hs-head:first-child div {
  margin-top: 0;
}

body .hs-gameplan .match {
  display: flex;
  align-items: center;
  border-radius: 2px;
  min-height: 70px;
  padding: 0px 5px;
}

body .hs-gameplan .match > .match-result {
  text-align: center;
  flex: 0 0 50px;
}

body .hs-gameplan .odd {
  background: #f0f0f0;
}

body .hs-gameplan .even {
  background: #fff;
}

body .hs-gameplan .person-flag,
body .hs-gameplan .team-flag,
body .hs-gameplan .team-image {
  flex: 0 0 auto;
  width: 44px;
  padding: 0 5px;
}

body .hs-gameplan .person-image {
  flex: 0 0 auto;
  width: 40px;
}

body .hs-gameplan .team-microname,
body .hs-gameplan .team-name,
body .hs-gameplan .team-shortname {
  overflow: hidden;
  flex: 1 1 auto;
  width: calc(50% - 70px);
}

body .hs-gameplan .team-microname-home,
body .hs-gameplan .team-name-home,
body .hs-gameplan .team-shortname-home {
  text-align: right;
}

body .hs-gameplan .match-pairing span {
  display: inline;
}

body .hs-gameplan .competition-name,
body .hs-gameplan .competition-shortname,
body .hs-gameplan .current-minute,
body .hs-gameplan .hs-rank,
body .hs-gameplan .match [class="empty"],
body .hs-gameplan .match-attendance,
body .hs-gameplan .match-incident,
body .hs-gameplan .match-result-away,
body .hs-gameplan .match-result-home,
body .hs-gameplan .match-result-intermediate,
body .hs-gameplan .match > div:empty,
body .hs-gameplan .match_meta,
body .hs-gameplan .round-name,
body .hs-gameplan .town-name,
body .hs-gameplan [data-competition_type="club"] .person-image,
body .hs-gameplan [data-competition_type="double"] .person-image,
body .hs-gameplan [data-competition_type="national"] .person-image {
  display: none;
}

body .hs-gameplan .match-more,
body .hs-gameplan .match-more a {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

body .hs-gameplan [data-round-mode="Ergebnisliste"] {
  justify-content: space-between;
}

body .hs-gameplan [data-round-mode="Ergebnisliste"] .competition-name,
body .hs-gameplan [data-round-mode="Ergebnisliste"] .competition-shortname,
body .hs-gameplan [data-round-mode="Ergebnisliste"] .round-fullname,
body .hs-gameplan [data-round-mode="Ergebnisliste"] .round-name {
  display: block;
}

body .hs-gameplan [data-round-mode="Ergebnisliste"] .empty,
body .hs-gameplan [data-round-mode="Ergebnisliste"] .match-result-0 {
  display: none;
}

body .hs-gameplan [data-round-mode="Ergebnisliste"] .hs-rank {
  display: inline-flex;
  align-items: center;
}

body .hs-gameplan [data-round-mode="Ergebnisliste"] .hs-rank span {
  display: inline-block;
}

body .hs-gameplan [data-round-mode="Ergebnisliste"] .hs-rank span + span {
  margin-left: 5px;
}

body .hs-gameplan [data-round-mode="Ergebnisliste"] .hs-rank .match_result-rank::after {
  content: ".";
}

body .hs-gameplan [data-sport_id="5"] {
  flex-flow: row wrap;
}

body .hs-gameplan [data-sport_id="5"]::after {
  content: "";
  width: 100%;
  order: 4;
}

body .hs-gameplan [data-sport_id="5"] .match-result-0,
body .hs-gameplan [data-sport_id="5"] .match-result-intermediate {
  display: none;
}

body .hs-gameplan [data-sport_id="5"] .team-flag-home,
body .hs-gameplan [data-sport_id="5"] .team-image-home {
  order: 1;
}

body .hs-gameplan [data-sport_id="5"] .team-microname-home,
body .hs-gameplan [data-sport_id="5"] .team-name-home,
body .hs-gameplan [data-sport_id="5"] .team-shortname-home {
  order: 2;
}

body .hs-gameplan [data-sport_id="5"] .match-result-home {
  order: 3;
}

body .hs-gameplan [data-sport_id="5"] .match-result-away,
body .hs-gameplan [data-sport_id="5"] .match-result-home {
  display: inline-flex;
}

body .hs-gameplan [data-sport_id="5"] > .match-result {
  flex: 0 0 25px;
  text-align: left;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .hs-gameplan [data-sport_id="5"] > .match-result {
    flex-grow: 1 !important;
  }
}

body .hs-gameplan [data-sport_id="5"] > .match-result .match-result {
  display: inline-block;
  text-align: center;
  width: 25px;
}

body .hs-gameplan [data-sport_id="5"] > .match-result .match-result-0 {
  display: none;
}

body .hs-gameplan [data-sport_id="5"] .team-flag-away,
body .hs-gameplan [data-sport_id="5"] .team-image-away {
  order: 5;
}

body .hs-gameplan [data-sport_id="5"] .team-microname-away,
body .hs-gameplan [data-sport_id="5"] .team-name-away,
body .hs-gameplan [data-sport_id="5"] .team-shortname-away {
  order: 6;
}

body .hs-gameplan [data-sport_id="5"] .match-result-away {
  order: 7;
}

body .hs-gameplan [data-sport_id="5"] .match-date,
body .hs-gameplan [data-sport_id="5"] .match-shortdate {
  display: none;
}

body .hs-gameplan [data-sport_id="5"] .team-microname,
body .hs-gameplan [data-sport_id="5"] .team-name,
body .hs-gameplan [data-sport_id="5"] .team-shortname {
  flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

body .hs-gameplan [data-round_mode_id="18"] {
  display: none;
}

body .hs-gameplan.hs-history .match {
  flex-flow: row wrap;
}

body .hs-gameplan.hs-history .match::before {
  content: "";
  width: 100%;
  order: 2;
}

body .hs-gameplan.hs-history .match::after {
  content: "";
  width: 100%;
  order: 4;
}

body .hs-gameplan.hs-history .match-result,
body .hs-gameplan.hs-history .match-time,
body .hs-gameplan.hs-history .team-flag,
body .hs-gameplan.hs-history .team-image,
body .hs-gameplan.hs-history .team-microname,
body .hs-gameplan.hs-history .team-name,
body .hs-gameplan.hs-history .team-shortname {
  order: 3;
}

body .hs-gameplan.hs-history .match-date,
body .hs-gameplan.hs-history .person-image,
body .hs-gameplan.hs-history .person-name,
body .hs-gameplan.hs-history .team-flag:first-child,
body .hs-gameplan.hs-history .team-flag:first-child + .team-name,
body .hs-gameplan.hs-history .team-image:first-child,
body .hs-gameplan.hs-history .team-image:first-child + .team-name,
body .hs-gameplan.hs-history [class*="days"],
body .hs-gameplan.hs-history [class*="years"] {
  order: 1;
}

body .hs-gameplan.hs-history .person-image {
  width: 40px;
  margin-right: 10px;
}

body .hs-gameplan.hs-history .team-flag:first-child + .team-name,
body .hs-gameplan.hs-history .team-image:first-child + .team-name {
  display: block;
  flex: 0 1 auto;
}

body .hs-gameplan.hs-history .person-name::after,
body .hs-gameplan.hs-history .team-flag:first-child + .team-name::after,
body .hs-gameplan.hs-history .team-image:first-child + .team-name::after {
  content: ": ";
  margin-right: 4px;
}

body .hs-gameplan.hs-history [class*="-years"]::after {
  content: " Jahre, ";
  margin-right: 4px;
}

body .hs-gameplan.hs-history [class*="-days"]::after {
  content: " Tage am ";
  margin-right: 4px;
}

body .hs-gameplan.hs-history .match-attendance {
  display: block;
  flex: 0 0 100%;
  order: 5;
  text-align: center;
}

body .hs-gameplan .match.finished .match-time,
body .hs-gameplan .match.live .match-time,
body .hs-gameplan .match.upcoming .match-result,
body .hs-gameplan .match > .team-microname,
body .hs-gameplan .match > .team-shortname {
  display: none;
}

@media screen and (max-width: 640px) {
  body .hs-gameplan .match > .team-name,
  body .hs-gameplan .match > div.empty-match-date:empty {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  body .hs-gameplan .match > .team-shortname {
    display: block;
  }
}

@media screen and (max-width: 319px) {
  body .hs-gameplan .match:not([data-sport_id="5"]) > .team-shortname {
    display: none;
  }
}

@media screen and (max-width: 319px) {
  body .hs-gameplan .match:not([data-sport_id="5"]) > .team-microname {
    display: block;
  }
}

body #hs-sidebar .hs-gameplan .match > .team-name {
  display: none;
}

body #hs-sidebar .hs-gameplan .match > .team-shortname {
  display: block;
}

body .hs-lineup-list {
  display: flex;
  flex-wrap: wrap;
}

body .hs-lineup-list .hs-block-header {
  width: 100%;
}

body .hs-lineup-list .hs-starter {
  order: 1;
}

body .hs-lineup-list .hs-bench {
  order: 2;
}

body .hs-lineup-list > div {
  width: 50%;
  padding: 5px;
}

@media screen and (max-width: 640px) {
  body .hs-lineup-list > div {
    width: 100%;
  }

  body .hs-lineup-list > div.away {
    order: 3;
  }
}

body .hs-lineup-list .event {
  min-height: 36px;
  border-bottom: 1px solid #d6d6d6;
  overflow: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: inherit;
}

body .hs-lineup-list .match_event {
  text-align: right;
  flex: 0 0 auto;
  width: 47px;
}

body .hs-lineup-list .match_event > div {
  background-position: left;
}

body .hs-lineup-list .match_event div {
  padding-right: 5px;
}

body .hs-lineup-list .match_event:empty {
  display: none;
}

body .hs-lineup-list .team_person-shirtnumber {
  width: 25px;
  text-align: center;
}

body .hs-lineup-list .person-name,
body .hs-lineup-list .person-shortname {
  padding-right: 10px;
  flex-grow: 2;
}

body .hs-lineup-list .person-shortname {
  display: none;
}

body .hs-lineup-list h3 {
  height: 30px;
  width: 100%;
  position: relative;
  padding: 5px;
  line-height: 22px;
}

body .hs-lineup-list h3 .team-flag,
body .hs-lineup-list h3 .team-image {
  position: relative;
  float: left;
  padding-right: 5px;
}

body .hs-lineup-list h3 .team-flag img,
body .hs-lineup-list h3 .team-image img {
  display: block;
  width: 20px;
}

body .hs-lineup-graphical {
  display: block;
}

@media screen and (max-width: 420px) {
  body .hs-lineup-graphical {
    display: none;
  }
}

body .hs-lineup-graphical + .hs-lineup-list {
  display: none;
}

@media screen and (max-width: 420px) {
  body .hs-lineup-graphical + .hs-lineup-list {
    display: flex;
  }
}

body[data-tosp="1"] .hs-lineup .module-ranking {
  background-color: #666;
  overflow: hidden;
  padding-top: 50px;
}

body[data-tosp="1"] .hs-lineup .hs-ms-position {
  background-color: #fff;
  border: 1px solid #fff;
  margin: 10px;
  position: relative;
  width: calc(50% - 20px);
}

body[data-tosp="1"] .hs-lineup .person-image {
  float: left;
}

body[data-tosp="1"] .hs-lineup .person-name,
body[data-tosp="1"] .hs-lineup .person-surname,
body[data-tosp="1"] .hs-lineup .team-name {
  left: 93px;
  padding-left: 5px;
  position: absolute;
}

body[data-tosp="1"] .hs-lineup .person-name,
body[data-tosp="1"] .hs-lineup .person-surname {
  font-weight: 600;
  padding-top: 5px;
  top: 0;
}

body[data-tosp="1"] .hs-lineup .person-surname {
  display: none;
}

body[data-tosp="1"] .hs-lineup .team-name {
  bottom: 0;
  font-size: 0.9em;
  padding-top: 30px;
  right: 0;
  top: 0;
}

@media screen and (max-width: 420px) {
  body[data-tosp="1"] .hs-lineup .team-name {
    display: block;
  }
}

body[data-tosp="1"] .hs-lineup .match_result-rank {
  background-color: rgba(50, 50, 50, 0.9);
  color: #fff;
  display: table-cell;
  float: left;
  font-weight: 600;
  height: 81px;
  line-height: 81px;
  text-align: center;
  width: 25px;
}

body[data-tosp="1"] .hs-lineup .team-image {
  width: 40px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

body[data-tosp="1"] .hs-lineup .hs-odd {
  float: left;
  margin-right: 0;
}

body[data-tosp="1"] .hs-lineup .hs-even {
  float: right;
  margin-left: 0;
}

body[data-tosp="1"] .hs-lineup .first {
  margin-top: -40px;
}

@media screen and (max-width: 640px) {
  body[data-tosp="1"] .hs-lineup .hs-ms-position {
    width: calc(100% - 20px);
  }

  body[data-tosp="1"] .hs-lineup .team-image {
    width: 70px;
  }

  body[data-tosp="1"] .hs-lineup .hs-even {
    float: left;
    margin-right: 0;
    margin-left: 10px;
  }
}

body .hs-liveticker {
  font-size: 1.5rem;
}

body .hs-liveticker .hs-no-data,
body .hs-liveticker .liveticker {
  overflow: hidden;
  padding: 10px;
  align-content: center;
  display: inline-grid;
  grid-template-areas: "liveticker-minute match-event-icon liveticker-headline" "liveticker-minute match-event-icon liveticker-content";
  grid-template-columns: 30px 40px 1fr;
  grid-auto-flow: dense;
  grid-auto-rows: auto 1fr;
  border-radius: 5px;
  width: 100%;
}

body .hs-liveticker .liveticker:nth-child(odd) {
  background-color: #f0f0f0;
}

body .hs-liveticker .liveticker:nth-child(even) {
  background-color: #fff;
}

body .hs-liveticker .team-flag,
body .hs-liveticker .team-image,
body .hs-liveticker span.team-flag img,
body .hs-liveticker span.team-image img {
  width: 26px;
}

body .hs-liveticker .match-pairing {
  grid-area: match-pairing;
}

body .hs-liveticker .hs-no-data {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

body .hs-liveticker .liveticker-datetime,
body .hs-liveticker .liveticker-minute {
  float: left;
  white-space: nowrap;
  margin-right: 5px;
  text-align: center;
  grid-area: liveticker-minute;
}

body .hs-liveticker .liveticker-datetime:empty,
body .hs-liveticker .liveticker-minute:empty {
  display: none;
}

body .hs-liveticker .liveticker-minute:not(.empty) + .liveticker-datetime {
  display: none;
}

body .hs-liveticker .liveticker-headline {
  width: auto;
  font-weight: 600;
  grid-area: liveticker-headline;
  margin-bottom: 10px;
}

body .hs-liveticker .liveticker-headline:empty {
  display: none;
}

body .hs-liveticker .match_event {
  height: 20px;
  width: 20px;
  grid-area: match-event-icon;
  justify-self: center;
}

body .hs-liveticker .liveticker-headline:not(:empty) ~ .liveticker-content,
body .hs-liveticker .team-flag-away:not(:empty) ~ .liveticker-content,
body .hs-liveticker .team-image-away:not(:empty) ~ .liveticker-content {
  clear: both;
}

body .hs-liveticker .liveticker-content {
  grid-area: liveticker-content;
}

body .hs-liveticker .person-image {
  z-index: 2;
}

body .hs-liveticker .person-image:empty {
  display: none;
}

body .hs-liveticker .liveticker-minute:not(.empty):not(:empty)::after {
  content: "'";
}

body[data-sp=""] .hs-liveticker .liveticker-minute:not(.empty):not(:empty)::after {
  content: "";
}

body[data-tosp="1"] .hs-liveticker .liveticker-minute:not(.empty):not(:empty)::after {
  content: ". Rd.";
}

body .hs-liveticker-filter {
  width: 100%;
  display: table;
  table-layout: fixed;
  border: 1px solid #eee;
  margin: 20px 0;
}

body .hs-liveticker-filter .hs-tab {
  display: table-cell;
  width: 1%;
  text-align: center;
  cursor: pointer;
  height: 25px;
  vertical-align: middle;
  border-right: 1px solid #eee;
}

body .hs-liveticker-filter .hs-tab:last-child {
  border: none;
}

body .hs-liveticker-filter .hs-tab.active {
  font-weight: 700;
}

body .hs-refresh-button {
  display: none;
  float: right;
  cursor: pointer;
  width: auto;
  background: #ebebeb;
  color: #5c5c5c;
}

body .hs-refresh-button.visible {
  display: block;
}

body .hs-refresh-button.loading {
  opacity: 0.5;
}

body .hs-refresh-text {
  padding: 10px 8px 5px;
}

body .hs-refresh-text::before {
  content: "";
  font-size: 1.3em;
  vertical-align: bottom;
  padding-right: 2px;
}

body .hs-refresh-progress {
  height: 7px;
  background: rgba(255, 255, 255, 0.2);
}

body .hs-refresh-progress-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background: #5c5c5c;
}

body .hs-in-progress .hs-refresh-progress-bar {
  animation-name: progress;
  animation-duration: var(--animation-duration, 60s);
  background: #5c5c5c;
}

@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

body .hs-slider-matchbar {
  overflow: hidden;
  height: 80px;
  font-size: 14px;
  line-height: 18px;
}

body .hs-slider-matchbar .module-gameplan {
  height: 80px;
}

body .hs-slider-matchbar .match {
  flex: 1 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 160px;
  height: 80px;
  min-width: 160px;
  border-right: 1px solid #8aa6aa;
}

body .hs-slider-matchbar .match::after,
body .hs-slider-matchbar .match::before {
  content: "";
  width: 100%;
  order: 2;
}

body .hs-slider-matchbar .match::after {
  order: 4;
}

body .hs-slider-matchbar .finished .current-minute,
body .hs-slider-matchbar .finished .match-date,
body .hs-slider-matchbar .finished .match-datetime,
body .hs-slider-matchbar .finished .match-shortdate,
body .hs-slider-matchbar .finished .match-time,
body .hs-slider-matchbar .finished[data-incident] .match-status {
  display: none;
}

body .hs-slider-matchbar .upcoming .current-minute,
body .hs-slider-matchbar .upcoming .match-result,
body .hs-slider-matchbar .upcoming .match-status {
  display: none;
}

body .hs-slider-matchbar .live .current-minute:not(:empty) + .match-status,
body .hs-slider-matchbar .live .match-date,
body .hs-slider-matchbar .live .match-datetime,
body .hs-slider-matchbar .live .match-shortdate,
body .hs-slider-matchbar .live .match-time {
  display: none;
}

body .hs-slider-matchbar .live a {
  color: inherit;
  font-weight: inherit;
}

body .hs-slider-matchbar .person-image,
body .hs-slider-matchbar .team-flag,
body .hs-slider-matchbar .team-image {
  order: 1;
  flex: 0 0 auto;
  text-align: center;
  width: calc(50% - 35px);
}

body .hs-slider-matchbar .person-image img,
body .hs-slider-matchbar .team-flag img,
body .hs-slider-matchbar .team-image img {
  width: 45px;
}

body .hs-slider-matchbar .match-pairing {
  flex: 0 0 100%;
  text-align: center;
}

body .hs-slider-matchbar .person-shortname,
body .hs-slider-matchbar .person-surname,
body .hs-slider-matchbar .team-microname,
body .hs-slider-matchbar .team-name,
body .hs-slider-matchbar .team-shortname {
  order: 3;
  text-align: center;
  flex: 0 0 auto;
  width: calc(50% - 35px);
}

body .hs-slider-matchbar .current-minute,
body .hs-slider-matchbar .match-date,
body .hs-slider-matchbar .match-datetime,
body .hs-slider-matchbar .match-result-intermediate,
body .hs-slider-matchbar .match-shortdate,
body .hs-slider-matchbar .match-status,
body .hs-slider-matchbar .match-time,
body .hs-slider-matchbar .match > .match-result-0 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}

body .hs-slider-matchbar .match-time-unknown {
  display: none;
}

body .hs-slider-matchbar .match-date,
body .hs-slider-matchbar .match-shortdate,
body .hs-slider-matchbar .match-winner,
body .hs-slider-matchbar .match > .match-result-0 {
  font-weight: 600;
}

body .hs-slider-matchbar .match > .match-result-0 {
  font-size: 20px;
  line-height: 1em;
  margin-top: -15px;
}

body .hs-slider-matchbar .match-result-intermediate {
  display: none;
}

body .hs-slider-matchbar .current-minute,
body .hs-slider-matchbar .match-status {
  font-size: 11px;
  margin-top: 5px;
}

body .hs-slider-matchbar .current-minute::after {
  content: ". Min";
}

body .hs-slider-matchbar .match-more,
body .hs-slider-matchbar .match-more a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
}

body .hs-menu {
  overflow: hidden;
}

body .hs-menu a {
  position: relative;
  display: block;
}

body .hs-menu a {
  color: inherit;
  padding: 0 10px;
  text-decoration: none;
}

body .hs-menu .hs-menu--item {
  font-size: 15px;
  padding: 0 8px;
  text-transform: uppercase;
}

body .hs-menu .hs-menu--item:hover {
  color: #4a4a4a;
}

/*body .hs-menu a:active {*/
/*  color: #f4c400;*/
/*}*/
/*body .hs-menu .hs-menu--item-active > a {*/
/*  color: #f4c400;*/
/*}*/
body .hs-menu select .hs-menu--list {
  display: initial;
}

body .hs-menu--list {
  display: flex;
  /* min-width: 0; */
  justify-content: center;
  align-items: center;
}

body .hs-menu--item {
  position: relative;
}

body .hs-menu--item-parent:not(.hs-menu--more):hover > .hs-menu--list {
  display: flex;
}

body .hs-menu--list-sub {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  flex-direction: column;
}

body .hs-menu--list-sub .hs-menu--item {
  display: block;
  white-space: nowrap;
}

body .hs-menu--list-sub .hs-menu-list {
  left: auto;
  right: auto;
  top: auto;
  position: relative;
  width: 100%;
}

body .hs-menu--hover,
body .hs-menu--item:hover {
  z-index: 1000;
}

body .hs-menu--more > a {
  cursor: pointer;
}

body .hs-menu--more:hover .hs-menu--list-more {
  display: flex;
}

body .hs-menu--list-more {
  left: auto;
  right: 0;
}

body .hs-menu--list-more .hs-menu--item {
  display: block;
}

body .hs-menu--hover > .hs-menu--list {
  display: flex;
}

body .hs-menu--back {
  display: none;
  justify-content: space-between;
}

body .hs-menu--hover > .hs-menu--back {
  display: flex;
}

body .hs-menu--list-more .hs-menu--hover-subsub > :not(.hs-menu--list) {
  display: none;
}

body .hs-menu--hover-sub .hs-menu--list {
  left: 0;
  top: auto;
  position: relative;
}

body .hs-menu--hover-sub > .hs-menu--item:not(.hs-menu--hover) {
  display: none;
}

body .hidden {
  display: none;
  opacity: 0;
}

body .visible {
  display: block;
  opacity: 1;
}

body .hs-menu-level-select .module-menu::after {
  content: "";
  color: #d6d6d6;
  font-size: 1.3em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  right: 10px;
  pointer-events: none;
}

body .hs-menu-level-select .module-menu:hover::after {
  color: #59994b;
}

body .hs-menu-level-select select {
  outline: 0;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-overflow: "";
}

body .hs-menu-level-select select::-ms-expand {
  display: none;
}

body .hs-menu-level-select option {
  color: inherit;
}

body .hs-menu-level-1 {
  color: #888888;
  font-size: 22px;
  font-weight: 600;
  /*top: 10px;*/
}

@media screen and (max-width: 768px) {
  body .hs-menu-level-1 {
    display: none;
  }
}

body .hs-menu-level-statistics {
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  color: #3c3c3b;
  font-size: 1.5rem;
  line-height: 1.9rem;
  margin: 20px 0;
  padding: 20px 0;
}

body .hs-menu-level-statistics a {
  background: #f1f1f1;
  border-radius: 5px;
  color: inherit;
  padding: 12px 15px;
}

body .hs-menu-level-statistics hs-menu--item:active {
  background: #f4c400;
  color: inherit;
}

/*body .hs-menu-level-statistics .hs-menu--item-active > a {*/
/*  background: #f4c400;*/
/*  color: inherit;*/
/*}*/
body .hs-menu-level-statistics .hs-menu--list {
  justify-content: flex-start;
  flex-wrap: wrap;
}

body .hs-menu-level-statistics .hs-menu--item {
  margin: 5px;
}

body .hs-menu-level-competition {
  color: #3c3c3b;
  font-size: 1.5rem;
  line-height: 1.9rem;
  padding: 20px 0 20px;
}

body .hs-menu-level-competition a {
  background: #f1f1f1;
  border-radius: 5px;
  color: inherit;
  padding: 12px 15px;
}

body .hs-menu-level-competition a:active {
  background: #f4c400;
  color: inherit;
}

body .hs-menu-level-competition .hs-menu--item-active > a {
  background: #f4c400;
  color: inherit;
}

body .hs-menu-level-competition .hs-menu--list {
  justify-content: flex-start;
  flex-wrap: wrap;
}

body .hs-menu-level-competition .hs-menu--item {
  margin: 5px;
}

body .hs-menu-level-match {
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  color: #3c3c3b;
  font-size: 1.5rem;
  line-height: 1.9rem;
  margin: 20px 0;
  padding: 5px 0;
}

body .hs-menu-level-match a {
  background: #f1f1f1;
  border-radius: 5px;
  color: inherit;
  padding: 12px 15px;
}

body .hs-menu-level-match a:active {
  background: #f4c400;
  color: inherit;
}

body .hs-menu-level-match .hs-menu--item-active > a {
  background: #f4c400;
  color: inherit;
}

body .hs-menu-level-match .hs-menu--list {
  justify-content: space-evenly;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  body .hs-menu-level-match .hs-menu--list {
    justify-content: flex-start;
  }
}

body .hs-menu-level-match .hs-menu--item {
  margin: 5px;
}

body .hs-menu-level-burger {
  display: none;
  height: 52px;
  flex: 0 0 52px;
  padding: 0;
  width: 104px;
  top: 10px;
}

@media screen and (max-width: 768px) {
  body .hs-menu-level-burger {
    display: flex;
    align-self: flex-start;
  }
}

body .hs-menu-level-burger .hs-menu--list-sub {
  right: 0;
  padding: 0;
}

body .hs-menu-level-burger .hs-menu--list-sub a {
  min-height: 28px;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 28px;
  /*border-bottom: solid 1px #fff;*/
}

body .hs-menu-level-burger .hs-menu--more {
  height: 35px;
  text-align: left;
}

body .hs-menu-level-burger .hs-menu--more > a {
  height: 35px;
  width: 35px;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #3c3c3b;
  border-radius: 100%;
}

body .hs-menu-level-burger .hs-menu--list-more {
  background: #3c3c3b;
  /*border: 1px solid #fff;*/
  border-radius: 5px;
  margin-top: 20px;
  max-height: calc(100vh - var(--menu_group_item_offset));
  max-width: 400px;
  overflow-x: auto;
  width: 100vw;
}

body .hs-menu-level-burger .hs-menu--list-more a:hover {
  background-color: transparent;
}

@media screen and (max-width: 420px) {
  body .hs-persons .country-flag,
  body .hs-persons .country-name,
  body .hs-persons .person_country-flag,
  body .hs-persons .person_country-name {
    display: none;
  }
}

body .hs-profile::after {
  content: "";
  display: table;
  clear: both;
}

body .hs-profile .team-image {
  margin: 10px 0;
  width: 100%;
  text-align: center;
}

body .hs-profile .person-image {
  float: left;
  width: 200px;
}

@media screen and (max-width: 640px) {
  body .hs-profile .person-image {
    float: none;
    margin: 0 auto;
  }
}

body .hs-profile dt {
  font-weight: 400;
  width: 35%;
  font-size: 1.3rem;
  text-align: left;
}

body .hs-profile dd {
  width: 65%;
  font-size: 1.3rem;
  text-align: right;
}

body .hs-profile.hs-person dl {
  float: left;
  width: calc(100% - 200px);
}

@media screen and (max-width: 640px) {
  body .hs-profile.hs-person dl {
    float: none;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  body .hs-career .role-name {
    display: none;
  }
}

#hs-sidebar .hs-profile .person-image {
  float: none;
  margin: 0 auto;
}

#hs-sidebar .hs-profile.hs-person dl {
  float: none;
  width: 100%;
}

body .module-ranking th.ranking-first,
body .module-ranking th.ranking-second,
body .module-ranking th.ranking-third {
  color: transparent;
}

body .module-ranking th.ranking-first::before,
body .module-ranking th.ranking-second::before,
body .module-ranking th.ranking-third::before {
  content: "";
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  height: 26px;
  width: 26px;
}

body .module-ranking th.ranking-first::before {
  background: #fd0;
}

body .module-ranking th.ranking-second::before {
  background: #c5c6c8;
}

body .module-ranking th.ranking-third::before {
  background: #905e36;
}

body .hs-ranking .team-shortname {
  display: none;
}

@media screen and (max-width: 640px) {
  body .hs-ranking .country-flag,
  body .hs-ranking .person_country-flag,
  body .hs-ranking .team_country-flag {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  body .hs-ranking .person-name ~ .team-name {
    display: none;
  }
}

body #hs-sidebar .hs-ranking .person-name + .team-name {
  display: none;
}

body .hs-scoreboard {
  overflow: auto;
  width: 100%;
  clear: both;
  background: #fff;
  border-radius: 5px;
  margin-top: 10px;
}

body .hs-scoreboard .match {
  font-size: 25px;
  line-height: 31px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

body .hs-scoreboard .match > div {
  order: 6;
}

body .hs-scoreboard .match div:empty {
  display: none;
}

body .hs-scoreboard .match .empty-space:empty {
  display: block;
  flex: 0 0 100%;
  height: 10px;
}

body .hs-scoreboard .match .person-image,
body .hs-scoreboard .match .team-flag,
body .hs-scoreboard .match .team-image {
  flex: 0 0 auto;
}

body .hs-scoreboard .match .team-flag,
body .hs-scoreboard .match .team-image {
  width: 103px;
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard .match .team-flag,
  body .hs-scoreboard .match .team-image {
    text-align: center;
    order: 7;
    background: #fff;
  }
}

body .hs-scoreboard .match .team-microname-home,
body .hs-scoreboard .match .team-name-home,
body .hs-scoreboard .match .team-shortname-home {
  text-align: right;
}

body .hs-scoreboard .match .team-microname-away,
body .hs-scoreboard .match .team-name-away,
body .hs-scoreboard .match .team-shortname-away {
  text-align: left;
}

body .hs-scoreboard .match .team-microname,
body .hs-scoreboard .match .team-name,
body .hs-scoreboard .match .team-shortname {
  flex: 1 1 auto;
  width: calc(50% - 103px - 120px);
  padding: 0 10px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 900;
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard .match .team-microname,
  body .hs-scoreboard .match .team-name,
  body .hs-scoreboard .match .team-shortname {
    width: 50%;
    text-align: center;
    order: 8;
  }
}

body .hs-scoreboard .match > .match-result-0 {
  text-align: center;
  flex: 0 0 auto;
  font-weight: 900;
  -moz-box-flex: 0;
  width: 70px;
  line-height: 1.1;
  font-size: 26px;
  margin-right: 35px;
  margin-left: 35px;
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard .match > .match-result-0 {
    width: 100%;
    font-size: 40px;
    line-height: 1em;
    margin-bottom: 10px;
  }

  body .hs-scoreboard .match > .match-result-0 > div {
    width: 110px;
    margin: auto;
  }
}

body .hs-scoreboard .match .match-result-intermediate {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

body .hs-scoreboard .match .match-result-intermediate::before {
  content: "(";
}

body .hs-scoreboard .match .match-result-intermediate::after {
  content: ")";
}

body .hs-scoreboard .match .match-result-intermediate:empty {
  display: none;
}

body .hs-scoreboard .match .match-result-intermediate span:empty {
  display: none;
}

body .hs-scoreboard .match .match-result-intermediate span:not(:first-child)::before {
  content: " | ";
}

body .hs-scoreboard .match .match-incident,
body .hs-scoreboard .match .match-result-away,
body .hs-scoreboard .match .match-result-home,
body .hs-scoreboard .match .person-image {
  display: none;
}

body .hs-scoreboard .match .current-minute,
body .hs-scoreboard .match .match-datetime,
body .hs-scoreboard .match .match-status,
body .hs-scoreboard .match .match > .match-incident,
body .hs-scoreboard .match .round-fullname {
  text-align: center;
  width: 100%;
}

body .hs-scoreboard .match .current-minute,
body .hs-scoreboard .match .match-incident,
body .hs-scoreboard .match .match-status {
  /*display: none;*/
  text-transform: uppercase;
}

body .hs-scoreboard .match .current-minute:not(.hs-calculated)::after {
  content: ". Min";
}

body .hs-scoreboard .match .match-more,
body .hs-scoreboard .match .match-more a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

body .hs-scoreboard .live .current-minute,
body .hs-scoreboard .live .match-status {
  display: block;
}

body .hs-scoreboard .live .current-minute + .match-status {
  display: none;
}

body .hs-scoreboard .finished .match-status {
  display: block;
}

body .hs-scoreboard [data-sport_id="22"] .match-result-away,
body .hs-scoreboard [data-sport_id="22"] .match-result-home {
  display: block;
  flex: 1 1 auto;
}

body .hs-scoreboard [data-sport_id="22"] .match-result-away .match-result,
body .hs-scoreboard [data-sport_id="22"] .match-result-home .match-result {
  float: left;
}

body .hs-scoreboard [data-sport_id="22"] .person-image {
  display: block;
}

body .hs-scoreboard [data-sport_id="22"].upcoming .team-flag,
body .hs-scoreboard [data-sport_id="22"].upcoming > .team-shortname {
  display: none;
}

body .hs-scoreboard [data-sport_id="5"] .match-result-0,
body .hs-scoreboard [data-sport_id="5"] .match-result-intermediate,
body .hs-scoreboard [data-sport_id="5"] .team-image {
  display: none;
}

body .hs-scoreboard [data-sport_id="5"] .match-result-away,
body .hs-scoreboard [data-sport_id="5"] .match-result-home {
  flex: 1 0 auto;
  display: flex;
  width: 150px;
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard [data-sport_id="5"] .match-result-away,
  body .hs-scoreboard [data-sport_id="5"] .match-result-home {
    flex-grow: 0;
  }
}

body .hs-scoreboard [data-sport_id="5"] > .match-result .match-result {
  font-size: 26px;
  text-align: center;
  width: 30px;
}

body .hs-scoreboard [data-sport_id="5"] > .match-result .hs-winner {
  font-weight: 600;
}

body .hs-scoreboard [data-sport_id="5"] .person-image {
  display: block;
  margin-left: 10px;
}

@media screen and (max-width: 420px) {
  body .hs-scoreboard [data-sport_id="5"] .person-image {
    display: none;
  }
}

body .hs-scoreboard [data-sport_id="5"] .team-microname,
body .hs-scoreboard [data-sport_id="5"] .team-name,
body .hs-scoreboard [data-sport_id="5"] .team-shortname {
  width: 25%;
  text-align: left;
}

body .hs-scoreboard [data-sport_id="5"] .team-microname-home,
body .hs-scoreboard [data-sport_id="5"] .team-name-home,
body .hs-scoreboard [data-sport_id="5"] .team-shortname-home {
  text-align: left;
}

body .hs-scoreboard > .module-ranking {
  font-size: 1.6rem;
  line-height: 1.2;
}

@media screen and (max-width: 420px) {
  body .hs-scoreboard > .module-ranking {
    font-size: 1.44rem;
  }
}

body .hs-scoreboard > .module-ranking ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "second-place first-place third-place";
}

body .hs-scoreboard > .module-ranking li {
  position: relative;
  text-align: center;
  padding: 10px;
}

@media screen and (max-width: 420px) {
  body .hs-scoreboard > .module-ranking li {
    padding: 10px 0;
  }
}

body .hs-scoreboard > .module-ranking li:nth-child(1) {
  grid-area: first-place;
}

body .hs-scoreboard > .module-ranking li:nth-child(2) {
  grid-area: second-place;
  margin: 30px 0 0 0;
}

body .hs-scoreboard > .module-ranking li:nth-child(3) {
  grid-area: third-place;
  margin: 60px 0 0 0;
}

body .hs-scoreboard > .module-ranking .person-image {
  margin: 0 auto;
  width: 100px;
}

@media screen and (max-width: 420px) {
  body .hs-scoreboard > .module-ranking .person-image {
    width: 65px;
  }
}

body .hs-scoreboard > .module-ranking .team-flag,
body .hs-scoreboard > .module-ranking .team-image {
  margin: 0 auto;
}

body .hs-scoreboard > .module-ranking .person-name,
body .hs-scoreboard > .module-ranking .person-shortname,
body .hs-scoreboard > .module-ranking .person-surname,
body .hs-scoreboard > .module-ranking .team-name,
body .hs-scoreboard > .module-ranking .team-shortname {
  border-bottom: 1px solid #000;
}

body .hs-scoreboard > .module-ranking .match_result-rank {
  padding: 2px 5px;
  position: absolute;
  font-size: 1.5em;
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard > .module-ranking .match_result-rank {
    position: relative;
  }
}

body .hs-scoreboard > .module-ranking .person-image ~ .country-flag {
  height: 20px;
  left: 50%;
  margin-left: -52px;
  top: 115px;
  position: absolute;
  width: 30px;
  background-color: #f5f5f5;
}

@media screen and (max-width: 420px) {
  body .hs-scoreboard > .module-ranking .person-image ~ .country-flag {
    margin-left: -38px;
    top: 95px;
  }
}

body .hs-scoreboard > .module-ranking .person-image ~ .country-flag img {
  vertical-align: top;
  width: 20px;
  height: auto;
}

body .hs-scoreboard > .module-ranking .person-shortname,
body .hs-scoreboard > .module-ranking .person-surname,
body .hs-scoreboard > .module-ranking .team-shortname {
  display: none;
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard > .module-ranking .person-name,
  body .hs-scoreboard > .module-ranking .team-name {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard > .module-ranking .person-surname,
  body .hs-scoreboard > .module-ranking .team-shortname {
    display: block;
  }
}

body .hs-events {
  font-size: 1.1rem;
  padding: 0 0 20px 0;
}

@media screen and (max-width: 640px) {
  body .hs-events {
    padding: 10px 0 20px 0;
  }
}

body .hs-events .module-events {
  width: auto;
  padding: 0;
  margin: 0;
}

body .hs-events .event {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  body .hs-events .event {
    width: 35%;
  }
}

@media screen and (max-width: 640px) {
  body .hs-events .event {
    width: 50%;
  }
}

body .hs-events .event[class*="away"] {
  justify-content: flex-start;
  margin: 0 0 0 70%;
}

@media screen and (max-width: 768px) {
  body .hs-events .event[class*="away"] {
    margin: 0 0 0 65%;
  }
}

@media screen and (max-width: 640px) {
  body .hs-events .event[class*="away"] {
    margin: 0 0 0 50%;
  }
}

body .hs-events .event[class*="away"] .match_event:not(.match_event-kind):empty {
  order: 1;
}

body .hs-events .event .match_event-empty {
  display: block;
  flex: 0 0 13px;
  background-size: 13px;
}

body .hs-events .event div {
  order: 2;
  white-space: nowrap;
  margin: 0 2px;
}

body .hs-events .own-goal .match_event-kind {
  font-style: italic;
}

body .hs-events .match_event-extra,
body .hs-events .person-additional,
body .hs-events .person-name,
body .hs-events .person-surname {
  overflow: hidden;
  text-overflow: ellipsis;
}

body .hs-events .match_event-extra,
body .hs-events .match_event-kind,
body .hs-events .person-additional,
body .hs-events .person-surname {
  display: none;
}

body .hs-events .person-name:empty + .match_event-extra {
  display: block;
}

@media screen and (max-width: 640px) {
  body .hs-events .person-surname {
    display: inline;
  }
}

body .hs-events .pso .match_event-minute {
  display: none;
}

body .hs-scoreboard-details {
  text-align: center;
  font-size: 1.1rem;
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard-details {
    display: none;
  }
}

body .hs-scoreboard-details div {
  display: inline-block;
}

@media screen and (max-width: 640px) {
  body .hs-scoreboard-details > div {
    display: block;
  }
}

body .hs-scoreboard-details .hs-headline {
  text-transform: uppercase;
}

body .hs-scoreboard-details .hs-headline::after {
  content: ": ";
}

body .hs-scoreboard-details .hs-value {
  padding: 0 10px 0 0;
}

body[data-sport_id="40"] .hs-slider-ranking .match_result-rank {
  line-height: 20px;
}

body[data-sport_id="40"] .hs-slider-ranking .country-flag,
body[data-sport_id="40"] .hs-slider-ranking .person-name {
  height: 20px;
  line-height: 20px;
}

body .hs-search--input-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

body .hs-search--input-wrapper input {
  border-radius: 5px;
  border: 1px solid #595959;
  flex: 5 1 auto;
  margin-right: 5px;
  padding: 5px 10px;
}

body .hs-search--icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  font-size: 0;
  height: 30px;
  width: 30px;
}

body .hs-search--filters {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}

body .hs-search--filters .filter {
  cursor: pointer;
  flex: 1 0 1px;
  text-align: center;
  margin: 0 5px;
  padding: 5px 0;
}

body .hs-search--filters .filter::before {
  content: "";
  display: block;
  height: 40px;
  position: relative;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
}

body .hs-search--filters .unavailable {
  pointer-events: none;
  opacity: 0.5;
}

body .hs-search--filters .hs-menu--item-active {
  font-weight: 600;
}

body .hs-search .pagination {
  display: flex;
  margin-top: 10px;
}

body .hs-search .page {
  cursor: pointer;
  flex: 1 0 1px;
  margin: 0 5px;
  text-align: center;
}

body .hs-search .page.active {
  font-weight: 600;
}

body .hs-search .page.disabled {
  color: transparent;
  font-size: 0;
}

body .hs-search .matchday,
body .hs-search .round,
body .hs-search .sport:not(.icon) {
  display: none;
}

body .hs-search .results > a {
  border-bottom: 1px solid #d6d6d6;
  display: block;
  margin: 10px 0 0;
  padding: 0 0 10px;
}

body .hs-search .gender {
  height: 20px;
  line-height: 26px;
}

body .hs-search .gender.female {
  display: block;
}

body .hs-search .gender.female::after {
  content: "";
  font-size: 18px;
}

body .hs-search [data-type="damon-match"] .detail,
body .hs-search [data-type="damon-match"] .header {
  align-items: center;
  display: flex;
}

body .hs-search [data-type="damon-match"] .detail > div,
body .hs-search [data-type="damon-match"] .header > div {
  margin-right: 10px;
}

body .hs-search [data-type="damon-match"] .date {
  font-weight: 600;
}

body .hs-search [data-type="damon-match"] .gender:not(.female),
body .hs-search [data-type="damon-match"] .shortname {
  display: none;
}

body .hs-search [data-type="damon-match"] .female {
  margin-left: -5px;
}

@media screen and (max-width: 640px) {
  body .hs-search [data-type="damon-match"] .shortname {
    display: block;
  }

  body .hs-search [data-type="damon-match"] .name {
    display: none;
  }
}

body .hs-search section:not([data-type="damon-match"]) .result {
  display: flex;
}

body .hs-search section:not([data-type="damon-match"]) .image {
  flex: 0 0 40px;
}

body .hs-search section:not([data-type="damon-match"]) .content {
  flex-grow: 1;
  margin-left: 10px;
}

body .hs-search section:not([data-type="damon-match"]) .header {
  font-size: 18px;
}

body .hs-search section:not([data-type="damon-match"]) .gender {
  order: 1;
}

body .hs-search section:not([data-type="damon-match"]) .detail {
  display: flex;
  align-items: center;
}

body .hs-search section:not([data-type="damon-match"]) .detail > div {
  margin-right: 5px;
}

body .hs-search section:not([data-type="damon-match"]) .detail div:not(.female):empty {
  display: none;
}

body .hs-search section:not([data-type="damon-match"]) .icon {
  flex-basis: 20px;
}

body .hs-select {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: 640px) {
  body .hs-select {
    grid-template-columns: 1fr;
  }
}

body .hs-select:empty {
  display: none;
}

body .hs-select [class^="module-"]::after {
  content: ">";
  color: #d6d6d6;
  font-size: 1.3em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  right: 7px;
  pointer-events: none;
}

body .hs-select [class^="module-"]:hover::after {
  color: #59994b;
}

body .hs-select [class^="module-"][data-pagination="true"] {
  padding-left: 30px;
  padding-right: 30px;
}

body .hs-select [class^="module-"][data-pagination="true"]::after {
  right: 37px;
}

body .hs-select .next,
body .hs-select .prev {
  border: none;
  height: 100%;
  position: absolute;
  top: 0;
  width: 30px;
}

body .hs-select .next a,
body .hs-select .prev a {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

body .hs-select .next {
  right: 0;
}

body .hs-select .next a::after {
  content: "";
  color: #d6d6d6;
  font-size: 1.3em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  text-align: center;
}

body .hs-select .next a:hover::after {
  color: #59994b;
}

body .hs-select .prev {
  left: 0;
}

body .hs-select .prev a::after {
  content: "";
  color: #d6d6d6;
  font-size: 1.3em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  left: 0;
  width: 100%;
  text-align: center;
}

body .hs-select .prev a:hover::after {
  color: #59994b;
}

body .hs-select select {
  background: 0 0;
  border: 1px solid #d6d6d6;
  padding: 0 30px 0 10px;
  height: 30px;
  width: 100%;
  border-radius: 5px;
  outline: 0;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-overflow: "";
  color: white;
}

@media screen and (max-width: 992px) {
  body .hs-select select {
    color: black;
  }
}

body .hs-select select::-ms-expand {
  display: none;
}

body .hs-select option {
  color: inherit;
  padding: 2.5px 5px;
}

body .hs-select-letter {
  width: 100%;
}

body .hs-select-letter .module-select::after {
  display: none;
}

body .hs-select-letter ul {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
    1fr;
  grid-template-columns: repeat(26, 1fr);
  grid-template-rows: auto;
}

body .hs-select-letter li {
  text-align: center;
}

body .jSlideContainer {
  width: 100%;
  overflow: hidden;
}

body .jSlideContainer div[class^="module-"] {
  padding: 0;
  margin: 0;
  height: 100%;
}

body .jSlideContainer div[class^="module-"]::after {
  display: none;
}

body .jSlideContainer.hideArrows .jArrow,
body .jSlideContainer:not(.jContainer) .jArrow {
  display: none;
}

body .jArrow {
  height: 100%;
  cursor: pointer;
  z-index: 3;
  width: 30px;
}

body .jArrow.disabled {
  cursor: default;
  display: none;
}

body .jArrow.disabled:hover::after {
  color: inherit;
}

body .jArrowPrev {
  float: left;
}

body .jArrowPrev::after {
  content: "";
  color: #d6d6d6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  left: 0;
  width: 100%;
  text-align: center;
}

body .jArrowPrev:hover::after {
  color: #f4c400;
}

body .jArrowNext {
  float: right;
}

body .jArrowNext::after {
  content: "";
  color: #d6d6d6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  text-align: center;
}

body .jArrowNext:hover::after {
  color: #f4c400;
}

body .jSlideWrapper {
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: 1;
  width: 100%;
}

body .jSlideWrapper ul {
  height: 100%;
  width: 100%;
  display: inline-flex;
  list-style-type: none;
  overflow: hidden;
  scroll-snap-type: both mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

body .jSlideWrapper ul.jSlideNoAnimation {
  scroll-behavior: auto;
}

body .jSlideWrapper li {
  flex: 0 0 auto;
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  scroll-snap-align: flex-start;
}

body .jSlideWrapper a {
  color: inherit;
}

body .jSlideScroll.jSlideHorizontal .jSlideWrapper ul {
  overflow-x: auto;
  height: calc(100% + 50px);
  padding-bottom: 50px;
  scroll-padding: 0 var(--itemmargin);
}

body .jSlideScroll.jSlideHorizontal .jSlideWrapper ul::after {
  content: "";
  flex: 0 0 var(--itemmargin);
  overflow: hidden;
  margin-left: calc(-1 * var(--itemmargin, "0"));
}

body .jSlideScroll.jSlideVertical .jSlideWrapper ul {
  overflow-y: auto;
  width: calc(100% + 50px);
  padding-right: 50px;
  scroll-padding: var(--itemmargin) 0;
}

body .jSlideScroll.jSlideVertical .jSlideWrapper ul::after {
  content: "";
  flex: 0 0 var(--itemmargin);
  overflow: hidden;
  margin-top: calc(-1 * var(--itemmargin, "0"));
}

body .jSlideVertical {
  height: 100%;
}

body .jSlideVertical .jSlideWrapper ul {
  flex-direction: column;
}

body .jSlideVertical .jSlideWrapper li {
  height: auto;
  width: 100%;
}

body .jSlideVertical .jArrow {
  width: 100%;
  float: none;
  height: 30px;
}

body .jSlideVertical .jArrowPrev::after {
  content: "";
  color: #d6d6d6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  left: 0;
  width: 100%;
  text-align: center;
}

body .jSlideVertical .jArrowPrev:hover::after {
  color: #f4c400;
}

body .jSlideVertical .jArrowNext {
  position: absolute;
  bottom: 0;
}

body .jSlideVertical .jArrowNext::after {
  content: "";
  color: #d6d6d6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  left: 0;
  width: 100%;
  text-align: center;
}

body .jSlideVertical .jArrowNext:hover::after {
  color: #f4c400;
}

body .jSlideIndicator {
  list-style-type: none;
  position: absolute;
  z-index: 2;
  text-align: center;
  list-style: none;
  width: 100%;
}

body .jSlideIndicator li {
  display: inline-block;
  cursor: pointer;
}

body .hs-slider-matchday li {
  text-align: center;
  overflow: hidden;
  font-size: 14px;
  cursor: pointer;
}

body .hs-slider-matchdate li {
  text-align: center;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
}

body .hs-slider-matchdate li a {
  text-decoration: none;
}

body .hs-slider-matchdate [class^="module-"] {
  border: none;
}

body .hs-slider-matchdate [class^="module-"]::after {
  display: none;
}

body .hs-slider-matchdate .day,
body .hs-slider-matchdate .weekday {
  display: block;
  line-height: 1.1em;
  margin-top: 7px;
  font-weight: 600;
}

body .hs-slider-matchdate .weekday {
  font-size: 12px;
  margin-top: 5px;
}

body .hs-slider-matchdate .day {
  font-size: 18px;
}

body .hs-slider-matchdate .month,
body .hs-slider-matchdate .year {
  font-size: 10px;
  line-height: 1.2em;
}

body .hs-slider-relatedmatches div[class^="module-"] {
  height: auto;
}

body .hs-slider-relatedmatches ul {
  overflow: hidden;
}

body .hs-slider-relatedmatches li {
  text-align: center;
  float: left;
  height: auto;
  position: relative;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 140px;
  padding: 5px 5px 0;
}

body .hs-slider-relatedmatches li:first-child {
  border-left-width: 2px;
}

body .hs-slider-relatedmatches li:last-child {
  border-right-width: 2px;
}

body .hs-slider-relatedmatches .competition-shortname,
body .hs-slider-relatedmatches .round-name {
  line-height: 16px;
  width: 100%;
  font-weight: 600;
}

body .hs-slider-relatedmatches .match-date,
body .hs-slider-relatedmatches .match-datetime {
  font-size: 11px;
  position: relative;
  bottom: 0;
  width: 100%;
}

body .hs-slider-relatedmatches .match-more,
body .hs-slider-relatedmatches .match-more a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body .hs-slider-ranking li {
  float: left;
  text-align: center;
}

body .hs-slider-ranking li:first-child .match_result-display {
  display: none;
}

body .hs-slider-ranking div {
  line-height: 20px;
}

body .hs-slider-ranking .match_result-rank {
  display: block;
  text-align: center;
  font-weight: 600;
}

body .hs-slider-ranking .match_result-rank::after {
  content: ".";
}

body .hs-slider-ranking .match_result-display {
  position: absolute;
  top: 0;
  width: auto;
  left: 5px;
}

body .hs-slider-ranking .country-flag,
body .hs-slider-ranking .person-name,
body .hs-slider-ranking .team-name,
body .hs-slider-ranking .team-shortname {
  display: inline-block;
  height: 30px;
  line-height: 30px;
}

body .hs-slider-ranking .country-flag {
  margin-right: 5px;
}

body .hs-slider-ranking .country-flag img {
  vertical-align: middle;
}

body .hs-slider-matchday .active {
  color: #f4c400;
}

body .hs-slider-matchdate {
  background: #fff;
  color: #fff;
}

body .hs-slider-matchdate li {
  background: #595959;
  border-color: #fff;
}

body .hs-slider-matchdate .current {
  color: #fff;
  background: #3c3c3b;
}

body .hs-slider-ranking li::after {
  background-color: red;
  top: 5px;
  content: "";
  height: calc(100% - 10px);
  position: absolute;
  right: 0;
  width: 1px;
}

body .hs-slider-seasontopic li {
  background: #fff;
}

body .hs-slider-seasontopic .active {
  background: #3ea046;
}

body .hs-slider-news,
body .hs-slider-news .jSlideWrapper,
body .hs-slider-news .jSlideWrapper li {
  height: 400px;
}

body .hs-slider-matchbar,
body .hs-slider-matchbar .jSlideWrapper,
body .hs-slider-matchbar .jSlideWrapper li {
  height: 200px;
}

body .hs-standing tbody .color-1 td:first-child {
  background-image: linear-gradient(90deg, #0e4274 3px, transparent 0);
  background-repeat: no-repeat;
  background-size: 3px 21px;
  background-position: 0 2px;
}

body .hs-standing tbody .color-2 td:first-child {
  background-image: linear-gradient(90deg, #54789c 3px, transparent 0);
  background-repeat: no-repeat;
  background-size: 3px 21px;
  background-position: 0 2px;
}

body .hs-standing tbody .color-3 td:first-child {
  background-image: linear-gradient(90deg, #a7b8ca 3px, transparent 0);
  background-repeat: no-repeat;
  background-size: 3px 21px;
  background-position: 0 2px;
}

body .hs-standing tbody .color-4 td:first-child {
  background-image: linear-gradient(90deg, #a7b8ca 3px, transparent 0);
  background-repeat: no-repeat;
  background-size: 3px 21px;
  background-position: 0 2px;
}

body .hs-standing tbody .color-5 td:first-child {
  background-image: linear-gradient(90deg, #f7b000 3px, transparent 0);
  background-repeat: no-repeat;
  background-size: 3px 21px;
  background-position: 0 2px;
}

body .hs-standing tbody .color-6 td:first-child {
  background-image: linear-gradient(90deg, #f7b000 3px, transparent 0);
  background-repeat: no-repeat;
  background-size: 3px 21px;
  background-position: 0 2px;
}

body .hs-standing tbody .color-7 td:first-child {
  background-image: linear-gradient(90deg, #e20413 3px, transparent 0);
  background-repeat: no-repeat;
  background-size: 3px 21px;
  background-position: 0 2px;
}

body .hs-standing tbody .color-8 td:first-child {
  background-image: linear-gradient(90deg, #e20413 3px, transparent 0);
  background-repeat: no-repeat;
  background-size: 3px 21px;
  background-position: 0 2px;
}

body .hs-standing tbody .current-away-team,
body .hs-standing tbody .current-home-team,
body .hs-standing tbody .current-team {
  background: #09589e !important;
}

body .hs-standing tbody .current-away-team a,
body .hs-standing tbody .current-away-team td,
body .hs-standing tbody .current-home-team a,
body .hs-standing tbody .current-home-team td,
body .hs-standing tbody .current-team a,
body .hs-standing tbody .current-team td {
  color: #fff;
}

body .hs-standing td {
  height: 25px;
  border-bottom: 1px solid #c8c8c8;
  line-height: 25px;
}

body .hs-standing .team-autoimage,
body .hs-standing .team-flag,
body .hs-standing .team-image {
  width: 20px;
}

body .hs-standing .group {
  margin: 5px 0;
  height: 26px;
  color: #6c6c6c;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
}

body .hs-standing [data-sort="percentage"] .standing-percentage,
body .hs-standing [data-sort="points"] .standing-points,
body .hs-standing [data-sort="quotient"] .standing-rel_points {
  font-weight: 600;
}

body .hs-standing .standing-points {
  padding-right: 5px;
}

body .hs-standing .team-shortname {
  display: none;
}

@media screen and (max-width: 640px) {
  body .hs-standing .team-shortname {
    display: table-cell;
  }
}

body #hs-sidebar .hs-standing .standing-draw,
body #hs-sidebar .hs-standing .standing-draw_on_extra_time,
body #hs-sidebar .hs-standing .standing-draw_on_penalty,
body #hs-sidebar .hs-standing .standing-goaldiff,
body #hs-sidebar .hs-standing .standing-lost,
body #hs-sidebar .hs-standing .standing-lost-on-extra-time,
body #hs-sidebar .hs-standing .standing-lost-on-penalty,
body #hs-sidebar .hs-standing .standing-lost-regular,
body #hs-sidebar .hs-standing .standing-lost_on_extra_time,
body #hs-sidebar .hs-standing .standing-lost_on_penalty,
body #hs-sidebar .hs-standing .standing-lost_regular,
body #hs-sidebar .hs-standing .standing-win,
body #hs-sidebar .hs-standing .standing-win-on-extra-time,
body #hs-sidebar .hs-standing .standing-win-on-penalty,
body #hs-sidebar .hs-standing .standing-win-regular,
body #hs-sidebar .hs-standing .standing-win_on_extra_time,
body #hs-sidebar .hs-standing .standing-win_on_penalty,
body #hs-sidebar .hs-standing .standing-win_regular,
body #hs-sidebar .hs-standing .team-name {
  display: none;
}

body #hs-sidebar .hs-standing .team-shortname {
  display: table-cell;
}

body .round-marker {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

body .round-marker li {
  font-size: 1.26rem;
  line-height: 2rem;
  white-space: nowrap;
  position: relative;
  margin-top: 5px;
  padding: 0 5px 0 8px;
  margin: 0 5px 10px 0;
}

body .round-marker li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 21px;
}

body .marker-color-1::before {
  background: #0e4274;
}

body .marker-color-2::before {
  background: #54789c;
}

body .marker-color-3::before {
  background: #a7b8ca;
}

body .marker-color-4::before {
  background: #a7b8ca;
}

body .marker-color-5::before {
  background: #f7b000;
}

body .marker-color-6::before {
  background: #f7b000;
}

body .marker-color-7::before {
  background: #e20413;
}

body .marker-color-8::before {
  background: #e20413;
}

body #hs-sidebar .round-marker li,
body.hs-widget .round-marker li {
  width: 50%;
}

@media screen and (max-width: 319px) {
  body #hs-sidebar .round-marker li,
  body.hs-widget .round-marker li {
    width: 100%;
  }
}

body .hs-statistics th[class*="-card_yellow"] {
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  color: transparent;
  text-indent: -9999px;
}

body .hs-statistics th[class*="-card_yellow_red"] {
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  color: transparent;
  text-indent: -9999px;
}

body .hs-statistics th[class*="-card_red"] {
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  color: transparent;
  text-indent: -9999px;
}

body .hs-statistics th[class*="-card_substitute-in"] {
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  color: transparent;
  text-indent: -9999px;
}

body .hs-statistics th[class*="-card_substitute-out"] {
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  color: transparent;
  text-indent: -9999px;
}

body .hs-statistics .person-team-combined {
  vertical-align: middle;
  display: inline-block;
}

body .hs-statistics .person-team-combined a,
body .hs-statistics .person-team-combined span {
  display: block;
}

body .hs-statistics .module-team-stat-season img {
  width: auto;
}

body .hs-statistics .team-shortname {
  display: none;
}

/*body #hs-sidebar .hs-statistics .person-name ~ .team-name,*/
/*body #hs-sidebar .hs-statistics .person_stats-score_per_playing {*/
/*  display: none;*/
/*}*/

body #hs-sidebar .hs-statistics .person-image {
  display: block;
}

body .hs-comparison li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  font-weight: 600;
  margin-bottom: 5px;
  min-height: 24px;
}

body .hs-comparison .hs-head .hs-away,
body .hs-comparison .hs-head .hs-home {
  flex: 0 0 50%;
  display: flex;
}

body .hs-comparison .hs-head .hs-away {
  justify-content: flex-end;
}

body .hs-comparison .hs-name {
  text-align: center;
  flex: 0 0 auto;
  width: calc(100% - 200px);
  order: 2;
}

body .hs-comparison .hs-value {
  text-align: center;
  flex: 0 0 100px;
}

body .hs-comparison .hs-value-home {
  order: 1;
  padding-left: 10px;
  text-align: left;
  font-size: 20px;
}

body .hs-comparison .hs-value-away {
  order: 3;
  padding-right: 10px;
  text-align: right;
  font-size: 20px;
}

body .hs-comparison .hs-bar {
  order: 4;
  width: 100%;
}

body .hs-comparison .hs-bar-empty {
  fill: #3f6dac;
}

body .hs-comparison .hs-bar-home {
  /* fill: #ffea9a; */
  fill: var(--bs-yellow);
}

body .hs-comparison .hs-bar-away {
  /* fill: #f4c200; */
  fill: var(--bs-gray-100);
}

body .hs-sorted-table th:not(.sorter-false) {
  cursor: pointer;
}

body .hs-sorted-table th div:not(:empty) {
  width: 100%;
  height: 100%;
}

body .hs-sorted-value {
  font-weight: 600;
}

body .tablesorter-header:not(.sorter-false) {
  padding-left: 15px;
}

body .tablesorter-header:not(.sorter-false)::before {
  content: "";
  color: #80a2d1;
  position: absolute;
  top: 0;
  left: 0;
}

body .tablesorter-header:not(.sorter-false)::after {
  content: "";
  color: #80a2d1;
  position: absolute;
  bottom: 0;
  left: 0;
}

body .tablesorter-header.hs-locked-asc::after,
body .tablesorter-header.hs-locked-desc::before {
  display: none;
}

body .tablesorter-header.hs-sorted-down::after,
body .tablesorter-header.hs-sorted-up::before {
  color: #243e61;
}

body .hs-team-picture {
  text-align: center;
  margin-bottom: 20px;
}

body .team-profile .team-image {
  text-align: center;
}

body .team-profile .team-profile-value-shortname {
  padding: 5px;
  font-weight: 600;
}

body .hs-transfer .team-shortname_extended {
  display: none;
}

@media screen and (max-width: 640px) {
  body .hs-transfer .team-shortname_extended {
    display: table-cell;
  }

  body .hs-transfer .role-shortname,
  body .hs-transfer .team-image,
  body .hs-transfer .team-name_extended {
    display: none;
  }
}

body .hs-teams .team-autoimage,
body .hs-teams .team-flag,
body .hs-teams .team-image,
body .hs-teams .team-name {
  padding: 0 0 0 20px;
}

body .hs-timeline {
  clear: both;
  height: 50px;
  margin: 5px 0 10px;
}

body .hs-timeline > div {
  height: 100%;
}

body .hs-timeline .hs-field-dummy {
  position: absolute;
  margin-left: 24px;
  width: calc(100% - 24px);
}

body .hs-timeline.hs-extended .hs-field-dummy {
  width: calc(100% - 48px);
}

body .hs-timeline .hs-center-circle {
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
}

body .hs-timeline .hs-sideline {
  display: none;
}

body .hs-timeline .module-events {
  height: 100%;
  padding: 0;
  float: left;
  clear: none;
  width: calc(100% - 24px);
  margin-left: 24px;
}

body .hs-timeline.hs-extended .module-events {
  width: calc(100% - 48px);
}

body .hs-timeline .hs-button {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 50px;
  width: 24px;
}

body .hs-timeline.hs-extended .hs-button {
  display: block;
}

body .hs-timeline.hs-overtime .hs-timeline-normal {
  display: none;
}

body .hs-timeline.hs-overtime .hs-field-dummy {
  width: calc(50% - 48px);
  margin-left: 48px;
}

body .hs-timeline.hs-overtime .hs-timeline-overtime,
body .hs-timeline.hs-overtime .hs-timeline-pso {
  display: block;
}

body .hs-timeline [class^="match_event-"],
body .hs-timeline [data-action="match"] {
  display: none;
}

body .hs-timeline .match_event {
  height: 20px;
  width: 20px;
}

body .hs-timeline .event {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 0;
  transform: translate(50%, 0);
  height: 20px;
  width: 20px;
  margin: 3px 0 0 -20px;
}

body .hs-timeline .event:hover {
  z-index: 2;
}

body .hs-timeline .event:hover::after,
body .hs-timeline .event:hover::before {
  display: block;
}

body .hs-timeline .event::before {
  display: none;
  position: absolute;
  bottom: 28px;
  left: -10px;
  white-space: nowrap;
  padding: 5px;
  content: attr(data-minute) ". " attr(data-person);
  background: #0078be;
  color: #fff;
  box-shadow: 0 3px 20px #333;
  border-radius: 5px;
  z-index: 300;
}

body .hs-timeline .event::after {
  display: none;
  content: " ";
  height: 0;
  width: 0;
  bottom: 18px;
  z-index: 301;
  position: absolute;
  border-top: 10px solid #0078be;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

body .hs-timeline .event.own-goal::before {
  content: attr(data-minute) ". " attr(data-person) " (Eigentor)";
}

body .hs-timeline .event.substitution::before {
  content: attr(data-minute) ". " attr(data-person-out) " <-> " attr(data-person);
}

body .hs-timeline .event[class*="-away"]:not(.own-goal),
body .hs-timeline .event[class*="-home"].own-goal {
  top: 25px;
}

body .hs-timeline .hs-timeline-overtime {
  display: none;
  float: left;
  width: 50%;
  padding-left: 24px;
}

body .hs-timeline .hs-timeline-overtime .hs-timeline-emblem {
  left: 24px;
}

body .hs-timeline .hs-timeline-overtime .hs-button {
  left: 0;
}

body .hs-timeline .hs-timeline-overtime .hs-button::after {
  content: "";
  color: #0078be;
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  left: 0;
  width: 100%;
  text-align: center;
}

body .hs-timeline .hs-timeline-overtime .hs-button:hover::after {
  color: #59994b;
}

body .hs-timeline .hs-timeline-overtime .module-events {
  width: calc(100% - 24px);
}

body .hs-timeline .hs-timeline-pso {
  display: none;
  float: left;
  margin-left: 24px;
  width: calc(50% - 24px);
}

body .hs-timeline .hs-timeline-pso .module-events {
  width: 100%;
  margin: 0;
}

body .hs-timeline .hs-timeline-pso .event.pso {
  position: relative;
  left: auto;
  float: left;
  margin: 0;
  top: 0;
  height: 25px;
  width: 24px;
  background-size: 17px;
  background-color: #529900;
}

body .hs-timeline .hs-timeline-pso .event.pso:nth-child(odd) {
  background-color: #5bab00;
}

body .hs-timeline .hs-timeline-pso .event.pso:hover::before {
  content: attr(data-person);
  left: auto;
  right: -10px;
}

body .hs-timeline [data-minute="0"] {
  left: 0;
}

body .hs-timeline [data-minute="1"] {
  left: 1%;
}

body .hs-timeline [data-minute="2"] {
  left: 2%;
}

body .hs-timeline [data-minute="3"] {
  left: 3%;
}

body .hs-timeline [data-minute="4"] {
  left: 4%;
}

body .hs-timeline [data-minute="5"] {
  left: 6%;
}

body .hs-timeline [data-minute="6"] {
  left: 7%;
}

body .hs-timeline [data-minute="7"] {
  left: 8%;
}

body .hs-timeline [data-minute="8"] {
  left: 9%;
}

body .hs-timeline [data-minute="9"] {
  left: 10%;
}

body .hs-timeline [data-minute="10"] {
  left: 11%;
}

body .hs-timeline [data-minute="11"] {
  left: 12%;
}

body .hs-timeline [data-minute="12"] {
  left: 13%;
}

body .hs-timeline [data-minute="13"] {
  left: 14%;
}

body .hs-timeline [data-minute="14"] {
  left: 16%;
}

body .hs-timeline [data-minute="15"] {
  left: 17%;
}

body .hs-timeline [data-minute="16"] {
  left: 18%;
}

body .hs-timeline [data-minute="17"] {
  left: 19%;
}

body .hs-timeline [data-minute="18"] {
  left: 20%;
}

body .hs-timeline [data-minute="19"] {
  left: 21%;
}

body .hs-timeline [data-minute="20"] {
  left: 22%;
}

body .hs-timeline [data-minute="21"] {
  left: 23%;
}

body .hs-timeline [data-minute="22"] {
  left: 24%;
}

body .hs-timeline [data-minute="23"] {
  left: 26%;
}

body .hs-timeline [data-minute="24"] {
  left: 27%;
}

body .hs-timeline [data-minute="25"] {
  left: 28%;
}

body .hs-timeline [data-minute="26"] {
  left: 29%;
}

body .hs-timeline [data-minute="27"] {
  left: 30%;
}

body .hs-timeline [data-minute="28"] {
  left: 31%;
}

body .hs-timeline [data-minute="29"] {
  left: 32%;
}

body .hs-timeline [data-minute="30"] {
  left: 33%;
}

body .hs-timeline [data-minute="31"] {
  left: 34%;
}

body .hs-timeline [data-minute="32"] {
  left: 36%;
}

body .hs-timeline [data-minute="33"] {
  left: 37%;
}

body .hs-timeline [data-minute="34"] {
  left: 38%;
}

body .hs-timeline [data-minute="35"] {
  left: 39%;
}

body .hs-timeline [data-minute="36"] {
  left: 40%;
}

body .hs-timeline [data-minute="37"] {
  left: 41%;
}

body .hs-timeline [data-minute="38"] {
  left: 42%;
}

body .hs-timeline [data-minute="39"] {
  left: 43%;
}

body .hs-timeline [data-minute="40"] {
  left: 44%;
}

body .hs-timeline [data-minute="41"] {
  left: 46%;
}

body .hs-timeline [data-minute="42"] {
  left: 47%;
}

body .hs-timeline [data-minute="43"] {
  left: 48%;
}

body .hs-timeline [data-minute="44"] {
  left: 49%;
}

body .hs-timeline [data-minute="45"] {
  left: 50%;
}

body .hs-timeline [data-minute="46"] {
  left: 51%;
}

body .hs-timeline [data-minute="47"] {
  left: 52%;
}

body .hs-timeline [data-minute="48"] {
  left: 53%;
}

body .hs-timeline [data-minute="49"] {
  left: 54%;
}

body .hs-timeline [data-minute="50"] {
  left: 56%;
}

body .hs-timeline [data-minute="51"] {
  left: 57%;
}

body .hs-timeline [data-minute="52"] {
  left: 58%;
}

body .hs-timeline [data-minute="53"] {
  left: 59%;
}

body .hs-timeline [data-minute="54"] {
  left: 60%;
}

body .hs-timeline [data-minute="55"] {
  left: 61%;
}

body .hs-timeline [data-minute="56"] {
  left: 62%;
}

body .hs-timeline [data-minute="57"] {
  left: 63%;
}

body .hs-timeline [data-minute="58"] {
  left: 64%;
}

body .hs-timeline [data-minute="59"] {
  left: 66%;
}

body .hs-timeline [data-minute="60"] {
  left: 67%;
}

body .hs-timeline [data-minute="61"] {
  left: 68%;
}

body .hs-timeline [data-minute="62"] {
  left: 69%;
}

body .hs-timeline [data-minute="63"] {
  left: 70%;
}

body .hs-timeline [data-minute="64"] {
  left: 71%;
}

body .hs-timeline [data-minute="65"] {
  left: 72%;
}

body .hs-timeline [data-minute="66"] {
  left: 73%;
}

body .hs-timeline [data-minute="67"] {
  left: 74%;
}

body .hs-timeline [data-minute="68"] {
  left: 76%;
}

body .hs-timeline [data-minute="69"] {
  left: 77%;
}

body .hs-timeline [data-minute="70"] {
  left: 78%;
}

body .hs-timeline [data-minute="71"] {
  left: 79%;
}

body .hs-timeline [data-minute="72"] {
  left: 80%;
}

body .hs-timeline [data-minute="73"] {
  left: 81%;
}

body .hs-timeline [data-minute="74"] {
  left: 82%;
}

body .hs-timeline [data-minute="75"] {
  left: 83%;
}

body .hs-timeline [data-minute="76"] {
  left: 84%;
}

body .hs-timeline [data-minute="77"] {
  left: 86%;
}

body .hs-timeline [data-minute="78"] {
  left: 87%;
}

body .hs-timeline [data-minute="79"] {
  left: 88%;
}

body .hs-timeline [data-minute="80"] {
  left: 89%;
}

body .hs-timeline [data-minute="81"] {
  left: 90%;
}

body .hs-timeline [data-minute="82"] {
  left: 91%;
}

body .hs-timeline [data-minute="83"] {
  left: 92%;
}

body .hs-timeline [data-minute="84"] {
  left: 93%;
}

body .hs-timeline [data-minute="85"] {
  left: 94%;
}

body .hs-timeline [data-minute="86"] {
  left: 96%;
}

body .hs-timeline [data-minute="87"] {
  left: 97%;
}

body .hs-timeline [data-minute="88"] {
  left: 98%;
}

body .hs-timeline [data-minute="89"] {
  left: 99%;
}

body .hs-timeline [data-minute="90"] {
  left: 100%;
}

body .hs-timeline [data-minute="91"] {
  left: 3%;
}

body .hs-timeline [data-minute="92"] {
  left: 7%;
}

body .hs-timeline [data-minute="93"] {
  left: 10%;
}

body .hs-timeline [data-minute="94"] {
  left: 13%;
}

body .hs-timeline [data-minute="95"] {
  left: 17%;
}

body .hs-timeline [data-minute="96"] {
  left: 20%;
}

body .hs-timeline [data-minute="97"] {
  left: 23%;
}

body .hs-timeline [data-minute="98"] {
  left: 27%;
}

body .hs-timeline [data-minute="99"] {
  left: 30%;
}

body .hs-timeline [data-minute="100"] {
  left: 33%;
}

body .hs-timeline [data-minute="101"] {
  left: 37%;
}

body .hs-timeline [data-minute="102"] {
  left: 40%;
}

body .hs-timeline [data-minute="103"] {
  left: 43%;
}

body .hs-timeline [data-minute="104"] {
  left: 47%;
}

body .hs-timeline [data-minute="105"] {
  left: 50%;
}

body .hs-timeline [data-minute="106"] {
  left: 53%;
}

body .hs-timeline [data-minute="107"] {
  left: 57%;
}

body .hs-timeline [data-minute="108"] {
  left: 60%;
}

body .hs-timeline [data-minute="109"] {
  left: 63%;
}

body .hs-timeline [data-minute="110"] {
  left: 67%;
}

body .hs-timeline [data-minute="111"] {
  left: 70%;
}

body .hs-timeline [data-minute="112"] {
  left: 73%;
}

body .hs-timeline [data-minute="113"] {
  left: 77%;
}

body .hs-timeline [data-minute="114"] {
  left: 80%;
}

body .hs-timeline [data-minute="115"] {
  left: 83%;
}

body .hs-timeline [data-minute="116"] {
  left: 87%;
}

body .hs-timeline [data-minute="117"] {
  left: 90%;
}

body .hs-timeline [data-minute="118"] {
  left: 93%;
}

body .hs-timeline [data-minute="119"] {
  left: 97%;
}

body .hs-timeline [data-minute="120"] {
  left: 100%;
}

body .hs-timeline [data-minute="0"],
body .hs-timeline [data-minute="1"] {
  left: 10px;
}

body .hs-timeline [data-minute="106"],
body .hs-timeline [data-minute="46"] {
  left: calc(50% + 10px);
}

body .hs-timeline [data-minute="105"],
body .hs-timeline [data-minute="45"] {
  left: calc(50% - 10px);
}

body .hs-timeline [data-minute="120"],
body .hs-timeline [data-minute="90"] {
  left: auto;
  right: 10px;
}

body .hs-timeline-normal [data-minute="45"]::before,
body .hs-timeline-normal [data-minute="46"]::before,
body .hs-timeline-normal [data-minute="47"]::before,
body .hs-timeline-normal [data-minute="48"]::before,
body .hs-timeline-normal [data-minute="49"]::before,
body .hs-timeline-normal [data-minute^="5"]::before,
body .hs-timeline-normal [data-minute^="6"]::before,
body .hs-timeline-normal [data-minute^="7"]::before,
body .hs-timeline-normal [data-minute^="8"]::before,
body .hs-timeline-normal [data-minute^="9"]::before {
  left: auto;
  right: -10px;
}

body .hs-timeline-normal [data-minute="5"]::before,
body .hs-timeline-normal [data-minute="6"]::before,
body .hs-timeline-normal [data-minute="7"]::before,
body .hs-timeline-normal [data-minute="8"]::before,
body .hs-timeline-normal [data-minute="9"]::before {
  left: -10px;
  right: auto;
}

body .hs-timeline-normal .hs-button {
  right: 0;
}

body .hs-timeline-normal .hs-button::after {
  content: "";
  color: #0078be;
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  text-align: center;
}

body .hs-timeline-normal .hs-button:hover::after {
  color: #59994b;
}

body .hs-timeline-away,
body .hs-timeline-home {
  clear: both;
  width: 100%;
  height: 25px;
}

body .hs-timeline-emblem {
  position: absolute;
  left: 0;
  top: 0;
  height: 25px;
  line-height: 25px;
  width: 24px;
}

body .hs-timeline-emblem img {
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}

body .hs-timeline-emblem-away {
  top: 25px;
}

body .hs-timetable .hs-button {
  cursor: pointer;
}

body .hs-timetable .hs-button.hs-disabled {
  display: none;
}

body .hs-timetable .hs-medal-checkbox {
  margin: 0 10px;
}

body .hs-timetable .hs-medal-checkbox.hs-disabled {
  opacity: 0.4;
}

body .hs-timetable .module-gameplan .match[data-round_mode_id="18"] {
  display: none;
}

body .hs-timetable .module-gameplan .match .match_meta {
  display: none;
}

body .hs-timetable .module-gameplan .match[data-round-mode="KO-System"] .match_meta-medals,
body .hs-timetable .module-gameplan .match[data-round-mode="KO-System"] .team-country,
body .hs-timetable .module-gameplan .match[data-round-mode="KO-System"] .team-flag-.hs-winner,
body .hs-timetable .module-gameplan .match[data-round-mode="KO-System"] .team-name-.hs-winner {
  display: none;
}

body .hs-timetable .module-gameplan .match .match-datetime {
  margin-right: 100px;
}

body .hs-timetable .module-gameplan .match .sport-name {
  left: 88px;
  overflow: visible;
  padding-left: 50px;
}

body .hs-timetable .module-gameplan .match .person-image {
  margin: 0 5px;
}

.hs-urls .url_source-name::after {
  content: ": ";
}

body .hs-venue > div:first-child {
  margin-top: 0;
}

body .hs-venue .hs-venue-info .option {
  font-weight: 600;
}

body .hs-venue .hs-venue-info .option,
body .hs-venue .hs-venue-info .value {
  text-align: left;
  padding-left: 10px;
}

body .hs-venue .hs-venue-info .hs-venue-info-country img {
  height: 100%;
  width: auto;
  right: 10px;
}

body .hs-venue .hs-venue-info .hs-venue-info-country span {
  margin-left: 5px;
}

body .hs-venue .venue-image img {
  width: 100%;
  height: auto;
}

body .hs-venue .venue-description {
  padding: 10px;
}

body .hs-venues ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

body .hs-venues .venue {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  padding: 10px;
  width: calc(50% - 10px);
}

@media screen and (max-width: 420px) {
  body .hs-venues .venue {
    width: 100%;
  }
}

body .hs-venues .venue-name {
  font-size: 1.2em;
  font-weight: 600;
  flex: 0 0 auto;
  width: 100%;
}

body .hs-venues .venue-image {
  flex: 0 0 auto;
  width: 100%;
  margin: 10px 0;
}

body .hs-venues .venue-image img {
  display: block;
  margin: 0 auto;
}

body .hs-venues .country-name,
body .hs-venues .venue-town,
body .hs-venues [class*="venue_detail"] {
  flex: 1 1 auto;
  width: 50%;
}

body .hs-venues .country-name {
  text-align: right;
}

:root {
  font-size: 62.5%;
  --grid-row-gap: 20px;
  --grid-col-gap: 20px;
}

html {
  background-color: #000;
}

body {
  margin: 0;
  padding: 0;
}

body .hs-page-header {
  /*display: none;*/
}

body[data-template="layout"] #hs-content {
  float: none;
  padding: 32px 20px;
  width: 100%;
}

body[data-template="layout"] #hs-sidebar {
  display: none;
}

body[data-template="layout"] .hs-grid--item-4 .hs-widget-body.hs-gameplan .hs-head {
  display: none;
}

body[data-template="layout"] .hs-grid--item-4 .hs-widget-body.hs-gameplan .match > .empty-match-date {
  display: none;
}

body .hs-page-header {
  /*display: inline;*/
  padding: 20px 20px 0;
  width: 100%;
}

.competition-logo {
  width: 80px !important;
  float: left;
}

body .hs-page-header h3 {
  padding-left: 20px;
  font-size: 30px;
  width: calc(100% - 80px);
  float: left;
  margin-top: 15px;
}

body .hs-page-header .hs-menu-level-competition {
  width: 100%;
  float: left;
  clear: left;
}

body .hs-select {
  width: 100%;
}

body header {
  align-items: center;
  background-color: #ffffff;
  color: #888888;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 20px;
}

body .hs-claim {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  body .hs-claim {
    font-size: inherit;
  }
}

body .hs-logo {
  flex: 0 0 170px;
  margin-right: 20px;
}

body nav {
  background: #595959;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

body nav .hs-space-wrapper {
  flex: 1 0 auto;
}

body nav .hs-search--input-wrapper {
  margin-left: 10px;
}

body nav .hs-search--input-wrapper input {
  width: 150px;
  margin-left: auto;
  flex: 0 0 auto;
}

@media screen and (max-width: 768px) {
  body nav .hs-search--input-wrapper {
    width: 90px;
  }

  body nav .hs-search--input-wrapper input {
    width: 44px;
  }
}

body footer {
  color: #fff;
  padding: 20px 20px;
  background-color: #000000;
}

body .hs-copyright {
  color: #a8a8a8;
}

body .hs-events .match_event-minute {
  width: auto;
}

body .hs-events .match_event-minute::before {
  content: "(";
}

body .hs-events .match_event-minute::after {
  content: ")";
}

body .hs-events .own-goal .match_event-minute {
  margin-right: 0;
}

body .hs-events .own-goal .match_event-minute::after {
  display: none;
}

body .hs-events .own-goal .match_event-kind {
  display: block;
  margin-left: 0;
}

body .hs-events .own-goal .match_event-kind::before {
  content: "";
}

body .hs-events .own-goal .match_event-kind::after {
  content: ")";
}

@media screen and (min-width: 641px) {
  body .module-liveticker > div {
    columns: 2;
  }
}

body nav select {
  outline: 0;
  display: inline-block;
  cursor: pointer;
  text-overflow: "";
  border: none;
  background: #595959;
  color: #fff;
  font-size: 1.6rem;
  line-height: 2.3rem;
}

body nav select::-ms-expand {
  display: none;
}

@media screen and (max-width: 768px) {
  body nav select {
    font-size: 1.4rem;
  }
}

body nav option {
  background: inherit;
  color: inherit;
}

body .hs-select > div:only-child {
  grid-column: 1/-1;
}

body .hs-gameplan .match > div.empty-match-date {
  display: block;
  flex-basis: 80px;
  font-size: 1.3rem;
}

body .hs-gameplan .match-incident,
body .hs-gameplan .match-time {
  flex-basis: 60px;
  text-align: center;
}

body .hs-gameplan .match-incident,
body .hs-gameplan .match-result,
body .hs-gameplan .match-time {
  color: #6c6c6c;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.1;
}

body .hs-gameplan .team-microname,
body .hs-gameplan .team-name,
body .hs-gameplan .team-shortname {
  width: calc(50% - 90px);
}

body .hs-gameplan .team-microname-home,
body .hs-gameplan .team-name-home,
body .hs-gameplan .team-shortname-home {
  text-align: center;
}

body .hs-gameplan .team-microname-away,
body .hs-gameplan .team-name-away,
body .hs-gameplan .team-shortname-away {
  text-align: right;
}

body .hs-gameplan .upcoming .match-time-unknown ~ .match-incident:not(:empty) {
  display: block;
}

body #hs-sidebar .match > div.empty-match-date,
body[data-template="livetoday"] .match > div.empty-match-date {
  display: none;
}

body #hs-sidebar .hs-head .match-date {
  margin-bottom: 0;
}

body .hs-statistics--block div.entry {
  height: 75px;
  margin-top: 15px;
}

body .hs-statistics--block div.entry-team {
  height: 50px;
  margin-top: 5px;
}

body .hs-statistics--block div.entry.entry-1 {
  margin-top: 0;
}

body .hs-statistics--block div.entry div {
  position: absolute;
}

body .hs-statistics--block div.entry:not(:last-child)::after {
  background: #c2c2c2;
  bottom: -10px;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
}

body .hs-statistics--block .person_stats-rank {
  color: #6c6c6c;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 30px;
  left: 0;
  text-align: center;
  top: calc(50% - 15px);
  width: 25px;
}

body .hs-statistics--block .person-name {
  left: 105px;
  font-size: 1.6rem;
  font-weight: 600;
  top: 6px;
}

body .hs-statistics--block .team-name-list {
  left: 110px;
  font-size: 1.6rem;
  font-weight: 600;
  top: 20px;
}

body .hs-statistics--block .person-name ~ .team-name {
  display: block;
}

body .hs-statistics--block .person-image {
  left: 30px;
  top: 0;
}

body .hs-statistics--block .person-image img {
  border-radius: 50%;
}

body .hs-statistics--block .team-name {
  left: 105px;
  bottom: 6px;
  color: #6c6c6c;
  font-size: 1.3rem;
  font-weight: 600;
}

body .hs-statistics--block .role-name {
  color: #6c6c6c;
  font-size: 1.2rem;
  left: 105px;
  top: 32px;
}

body .hs-statistics--block .person_stats-playing {
  display: none;
}

body .hs-statistics--block [class*="person_stats"]:not(.person_stats-rank):not(.person_stats-playing) {
  align-self: center;
  background-color: #f4c400;
  border-radius: 5px;
  font-size: 1.7rem;
  font-weight: 600;
  /*line-height: 30px;*/
  right: 0;
  text-align: center;
  top: calc(50% - 15px);
  /*height: 30px;*/
  padding: 2px 4px 2px 4px;
  margin-right: 10px;
}

body
  .hs-statistics--block
  [class*="person_stats"]:not(.person_stats-rank):not(.person_stats-playing)
  ~ [class*="person_stats"] {
  display: none;
}

body .hs-comparison > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  line-height: 28px;
  font-size: 14px;
  font-weight: 400;
  border-style: solid;
  border-width: 1px 0 0 0;
}

body .hs-comparison .hs-name {
  font-weight: 600;
  font-size: 16px;
}

@media screen and (max-width: 700px) {
  body .hs-comparison .hs-name {
    font-size: 14px;
  }
}

body .jw-autostart-mute,
body .jw-buffer,
body .jw-captions,
body .jw-cast-container,
body .jw-controlbar,
body .jw-controls,
body .jw-controls-backdrop,
body .jw-flag-small-player .jw-settings-menu,
body .jw-float-icon,
body .jw-hidden-accessibility,
body .jw-idle-icon-text,
body .jw-info-overlay,
body .jw-knob,
body .jw-logo,
body .jw-media,
body .jw-nextup-close,
body .jw-nextup-container,
body .jw-overlay,
body .jw-overlays,
body .jw-plugin,
body .jw-plugin .jw-banner,
body .jw-preview,
body .jw-progress,
body .jw-rail,
body .jw-settings-menu,
body .jw-settings-menu .jw-tooltip,
body .jw-shortcuts-container .jw-switch .jw-switch-disabled,
body .jw-shortcuts-container .jw-switch .jw-switch-enabled,
body .jw-shortcuts-container .jw-switch .jw-switch-knob,
body .jw-skip,
body .jw-slider-time .jw-cue,
body .jw-title,
body .jw-tooltip,
body .jw-top,
body .jw-video-thumbnail-container .jw-video-thumbnail,
body .jw-video-thumbnail-container.jw-video-thumbnail-generated,
body .jw-wrapper,
body .jwplayer .jw-rightclick,
body .jwplayer .jw-shortcuts-tooltip,
body .jwplayer.jw-state-error.jw-flag-audio-player .jw-error-msg {
  position: absolute;
}

body .hs-standing .team-name,
body .hs-standing .team-shortname {
  font-weight: 600;
}

body .hs-menu-level-burger .hs-menu--item .hs-menu--close-svg {
  display: none;
}

body .hs-menu-level-burger .hs-menu--item.hs-menu--hover .hs-menu--burger-svg {
  display: none;
}

body .hs-menu-level-burger .hs-menu--item.hs-menu--hover .hs-menu--close-svg {
  display: block;
}

body .hs-more_link a::after {
  content: "→";
  margin-left: 10px;
}

body .hs-news-list .news-item {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  border: solid 1px;
  border-radius: 5px;
  min-height: 100px;
  background-color: #f6f6f6;
  border-color: #d3d3d3;
}

body .hs-news-list .news-item .news-image {
  float: left;
  width: 120px;
  height: 98px;
}

body .hs-news-list .news-item .news-image img {
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
}

body .hs-news-list .news-item .news-kicker {
  color: #f4c400;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  padding-top: 10px;
}

body .hs-news-list .news-item .news-headline,
body .hs-news-list .news-item .news-kicker {
  padding-left: 10px;
  float: right;
  width: calc(100% - 120px);
}

body .hs-slider-news {
  height: 550px;
}

body .hs-slider-news .jArrow {
  display: none;
}

body .hs-slider-news .jSlider li {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

body .hs-slider-news .jSlider .news-image {
  left: 0;
  position: absolute;
  top: 0;
}

body .hs-slider-news .jSlider .news-image img {
  object-fit: cover;
  min-height: 400px;
}

body .hs-slider-news .jSlider .news-headline {
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  color: #fff;
  font-size: 3.5rem;
  line-height: 1.1;
  padding: 10px 20px;
  z-index: 2;
}

body .hs-slider-news .jSlider .news-kicker {
  color: #f4c400;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  padding: 5px 20px;
  z-index: 2;
}

@media screen and (max-width: 640px) {
  body .hs-slider-news .jSlider .news-headline {
    font-size: 1.5rem;
  }

  body .hs-slider-news .jSlider .news-kicker {
    font-size: 1.2rem;
  }
}

body .hs-slider-news .jSlideIndicator {
  position: absolute;
  top: 400px;
  height: 150px;
  left: 0;
  width: 100%;
  display: inline-flex;
  list-style-type: none;
  overflow: hidden;
  scroll-snap-type: both mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  scroll-padding: 0 var(--itemmargin);
}

body .hs-slider-news .jSlideIndicator::after {
  content: "";
  flex: 0 0 var(--itemmargin);
  overflow: hidden;
  margin-left: calc(-1 * var(--itemmargin, "0"));
}

body .hs-slider-news .jSlideIndicator li {
  flex: 0 0 auto;
  height: 100%;
  width: 266px;
  border: 5px solid #fff;
  overflow: hidden;
  position: relative;
}

body .hs-slider-news .jSlideIndicator .active {
  border-color: #f4c400;
}

body .hs-slider-news .jSlideIndicator .news-headline {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  padding: 5px;
  position: absolute;
}

body .hs-scoreboard .match.upcoming .match-result {
  color: transparent;
}

body .hs-scoreboard .match.upcoming .match-result::after {
  content: "VS";
  position: absolute;
  left: 0;
  right: 0;
  color: #3c3c3b;
}

body .hs-scoreboard .team-flag,
body .hs-scoreboard .team-image {
  text-align: center;
  border-radius: 50%;
  background: #f5f5f5;
  height: 102px;
  border-style: solid;
  border-color: #282828;
  border-width: thin;
}

body .hs-scoreboard .team-flag img,
body .hs-scoreboard .team-image img {
  width: 59px;
  margin-top: 22px;
}

body .hs-scoreboard .match .competition-name_extended,
body .hs-scoreboard .match .match-datetime,
body .hs-scoreboard .match .round-fullname {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 15px;
  width: auto;
}

@media screen and (max-width: 700px) {
  body .hs-scoreboard .match .competition-name_extended,
  body .hs-scoreboard .match .match-datetime,
  body .hs-scoreboard .match .round-fullname {
    font-size: 11px;
  }
}

body .hs-scoreboard .match .competition-name_extended {
  flex: 0 0 auto;
  padding-left: 32.5px;
}

body .hs-scoreboard .match .country-flag {
  /*padding-left: 13.5px;*/
  margin-bottom: 20px;
}

body .hs-scoreboard .match .country-flag:not(:empty) + .competition-name_extended {
  padding-left: 6.54px;
}

body .hs-scoreboard .match .country-flag:not(:empty) + .competition-name_extended img {
  width: 12.46px;
  height: 8.9px;
}

body .hs-scoreboard .match .round-fullname {
  flex: 1 1 auto;
  justify-self: flex-start;
  text-align: left;
}

body .hs-scoreboard .match .match-datetime {
  color: #6c6c6c;
  font-weight: 400;
  text-align: right;
  flex: 1 1 auto;
  padding-right: 33px;
}

.big-match-competition-info {
  display: flex;
  width: 100%;
}

body .hs-scoreboard .match .competition-name_extended a::after {
  content: " - ";
}

body .hs-scoreboard .match .match-empty:empty {
  content: "";
  width: 100%;
  height: 1px;
  display: block;
}

body .hs-scoreboard-details {
  display: flex;
  flex-flow: column;
  line-height: 25px;
}

body .hs-scoreboard-details > div {
  text-align: left;
  display: flex;
  font-size: 15px;
}

body .hs-scoreboard-details > div .hs-image {
  flex: 1 1 9%;
  display: flex;
  justify-content: center;
  align-items: center;
}

body .hs-scoreboard-details > div .hs-headline,
body .hs-scoreboard-details > div .hs-value {
  flex: 1 1 41%;
}

body .hs-scoreboard-details > div .hs-value {
  flex: 1 1 50%;
}

body .hs-scoreboard-details > div .hs-headline {
  font-weight: 600;
  text-transform: none;
}

body .hs-scoreboard-details > div .hs-value {
  color: #6c6c6c;
  padding-left: 25px;
}

body .hs-scoreboard-details > div .hs-referee-country + .hs-value {
  padding-left: 12.12px;
  flex: 1 1 calc(50% - 12.88px);
}

body .hs-scoreboard-details > div .hs-referee-country {
  width: 12.88px;
}

#prj_160x600_sidebar_sx {
  position: absolute !important;
  max-width: 160px;
  max-height: 600px;
  overflow: visible;
  right: -160px;
  top: 162px;
}

#prj_160x600_sidebar_dx {
  position: absolute !important;
  max-width: 160px;
  max-height: 600px;
  overflow: visible;
  left: -160px;
  top: 162px;
}

.main-logo:hover {
  cursor: pointer;
}

.clickable:hover {
  cursor: pointer;
}

.match_event [class*="-start"],
.match_event[class*="-start"] {
  background: url(/event_icons/game.png) center/contain no-repeat;
}

.match_event [class*="-end"],
.match_event[class*="-end"] {
  background: url(/event_icons/game.png) center/contain no-repeat;
}

.match_event .additional-time,
.match_event.additional-time {
  background: url(/event_icons/additional-time.png) center/contain no-repeat;
}

.match_event .goal,
.match_event.goal {
  background: url(/event_icons/goal.png) center/contain no-repeat;
}

.match_event .missed-penalty,
.match_event.missed-penalty {
  background: url(/event_icons/missed-penalty.png) center/contain no-repeat;
}

.match_event .own-goal,
.match_event.own-goal {
  background: url(/event_icons/own-goal.png) center/contain no-repeat;
}

.match_event .pso.miss,
.match_event.pso.miss {
  background: url(/event_icons/missed-penalty-sp1.svg) center/contain no-repeat;
}

.hs-timeline .substitution .match_event {
  background: url(/event_icons/substitution.svg) center/contain no-repeat;
}

.match_event .substitute-in,
.match_event.substitute-in {
  background: url(/event_icons/substitute-ing.png) center/contain no-repeat;
}

.match_event .substitute-out,
.match_event.substitute-out {
  background: url(/event_icons/substitute-out.png) center/contain no-repeat;
}

.match_event .yellow,
.match_event.yellow {
  background: url(/event_icons/yellow.png) center/contain no-repeat;
  border: 0;
}

.match_event .yellow-red,
.match_event.yellow-red {
  background: url(/event_icons/yellow-red.png) center/contain no-repeat;
  border: 0;
}

.match_event .red,
.match_event.red {
  background: url(/event_icons/red.png) center/contain no-repeat;
  border: 0;
}

@media screen and (min-width: 1200px) {
  .news-headline {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  body .hs-scoreboard .match .competition-name_extended {
    padding: 0;
  }

  body .hs-scoreboard .match .match-datetime {
    padding: 0;
    width: calc(100% - 200px);
  }

  body header {
    padding: 0;
  }

  body footer {
    padding: 0 10px;
    padding-top: 10px;
    background: #3c3c3b !important;
  }

  .news-headline {
    font-size: calc(1.3rem + 0.2vw);
    padding-left: 8px !important;
  }
}

.hide {
  display: none;
}

.teamPlayersImage {
  text-align: center;
  margin-bottom: 10px;
}

.planetPay {
  border-radius: 5px !important;
}

.ant-pagination-item-active {
  border-color: #f4c400 !important;
}

.ant-pagination-item:hover {
  border-color: #f4c400 !important;
}

.ant-pagination-item-ellipsis {
  color: #f4c400 !important;
}

.ant-pagination-item-link:hover {
  color: #f4c400 !important;
  border-color: #f4c400 !important;
}

.ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #f4c400 !important;
}

.newsRadioButton {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsRadioContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px !important;
  margin-bottom: 5px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #f4c400 !important;
  border-color: #f4c400 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 600;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
  color: #f4c400 !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f4c400 !important;
}

.ant-select-item .ant-select-item-option .ant-select-item-option-active .ant-select-item-option-selected {
  background-color: #f4c400 !important;
}

.swiper-slide span {
  position: initial !important;
}

.header-competition-link {
  color: #888888;
  /*background-color: #3c3c3b;*/
  font-size: 14px;
  /*top: -10px;
  padding-bottom: 10px !important;
  margin-bottom: -10px;*/
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  border-bottom: 4px solid transparent;
  margin-bottom: -2.5px;
}

.bet-competition {
  color: #888888;
  /*background-color: #3c3c3b;*/
  font-size: 14px;
  /*top: -10px;
  padding-bottom: 10px !important;
  margin-bottom: -10px;*/
  text-align: center;
  /*display: flex;*/
  justify-content: center;
  border-bottom: 4px solid transparent;
  margin-bottom: -2.5px;
}

.all-numbers-row div:last-child {
  padding-right: 18px !important;
}

.header-competition-link a {
  color: #888888;
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
}

.header-competition-link span {
  margin-right: 10px;
}

.header-competition-link a:hover {
  color: #4a4a4a;
}

.header-competition-link:hover {
  border-bottom: 4px solid #f4c312;
}

.otherStandingElement {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 5px;
}

.otherStandingElement a {
  display: flex;
}

.otherStandingElement span {
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .otherStandingElement {
    justify-content: flex-start;
  }
}

.header-competition-links {
  display: flex;
  width: 80% !important;
  justify-content: flex-end;
  background-color: #3c3c3b;
}

@media screen and (max-width: 768px) {
  .header-competition-links {
    display: none !important;
  }

  .all-numbers {
    display: none !important;
  }
}

.competition-burger-link {
  display: inline-block !important;
}

.competition-burger-link span {
  margin-left: 4px;
}

.big-match-result {
  width: 70px !important;
}

@media screen and (max-width: 768px) {
  .big-match-result {
    width: 110px !important;
  }
}

.back-to-competition {
  font-size: 1.3rem;
  margin-left: 5px;
  margin-bottom: 5px;
}

.back-to-competition .anticon {
  display: contents;
}

.navigation {
  font-size: 1em;
}

.btn-match-menu:not(.hs-menu--item-active):hover {
  color: #f4c400;
}

.ant-spin-dot.ant-spin-dot-spin > i {
  color: #f4c400;
  background-color: #f4c400;
}

@media screen and (max-width: 768px) {
  .btn-match-menu {
    font-size: 14px;
  }
}

.header-menu-links {
  /*padding-bottom: 20px;*/
}

.news-list-author-div {
  position: absolute;
  bottom: 5px;
  left: 125px;
  color: #191919;
  font-style: italic;
  font-weight: normal;
}

.news-list-date-div {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #191919;
  font-style: italic;
  font-weight: normal;
}

.missingDataCell {
  color: #666666;
  font-style: italic;
}

.table {
  margin-bottom: 0;
}

.datatable-headbar {
  padding: 15px;
}

.datatable-footbar {
  margin-top: 7px;
}

.table td {
  vertical-align: middle !important;
}

.table td,
.table th {
  text-align: center !important;
}

.div-processing-container {
  display: block;
  height: 0;
  width: 100%;
}

.processing {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  text-align: center;
  padding: 1em 0;
  z-index: 999;
  background-color: #ffffff;
  border: 1px solid #191919 !important;
  /*background-color: var(--analytik-bg-color);*/
  border-radius: 2px 2px 0 0;
}

.fantastic-player-img {
  object-position: top;
}

body .hs-fantastic-player-list .fantastic-player-item {
  position: relative;
  width: 100%;
  /*margin-bottom: 10px;*/
  border: solid 1px;
  border-radius: 5px;
  background-color: #f6f6f6;
  border-color: #d3d3d3;
  padding: 5px;
}

body .hs-fantastic-player-list .fantastic-player-item .fantastic-player-image {
  /*float: left;*/
  width: 180px;
  /*height: 147px;*/
  margin-right: 15px;
}

body .hs-fantastic-player-list .fantastic-player-item .fantastic-player-text {
  width: calc(100% - 180px);
}

body .hs-fantastic-player-list .fantastic-player-item.disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
}

body .hs-fantastic-player-list .fantastic-player-item-a.disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
}

body .hs-fantastic-player-list .fantastic-player-item-container {
  display: inline-flex;
}

@media screen and (max-width: 768px) {
  body .hs-fantastic-player-list .fantastic-player-item-container {
    display: block;
  }

  body .hs-fantastic-player-list .fantastic-player-item .fantastic-player-image {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
  }

  body .hs-fantastic-player-list .fantastic-player-item .fantastic-player-headline {
    text-align: center;
  }

  body .hs-fantastic-player-list .fantastic-player-item .fantastic-player-text {
    width: fit-content;
    text-align: center;
    margin: auto;
  }
}

body .hs-fantastic-player-list .fantastic-player-item .fantastic-player-image img {
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
}

body .hs-fantastic-player-list .fantastic-player-item .fantastic-player-headline {
  font-size: 2.5rem;
}

body .hs-fantastic-player-list .fantastic-player-description {
  font-size: 1.4em;
}

body .hs-fantastic-player-list .fantastic-player-item .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgb(60, 60, 59, 0.7);
  border-radius: 5px;
}

body .hs-fantastic-player-list .fantastic-player-item:hover .overlay {
  opacity: 1;
}

body .hs-fantastic-player-list .fantastic-player-item:hover .overlay .text {
  color: white;
  font-size: 2.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.fantastic-player-banner {
  width: 100%;
  min-width: 300px;
  height: auto;
  margin-top: 5px !important;
  border-radius: 5px !important;
}

.telegram-large-banner {
  border-radius: 5px !important;
}

.telegram-small-banner {
  border-radius: 5px !important;
}

.group-standing-radio {
  display: block !important;
  text-align: center !important;
  margin-bottom: 10px !important;
  margin-top: 2px !important;
}

.match-list-element:hover {
  background-color: #f4c400;
  cursor: pointer !important;
  transition: 0.5s;
}

.match-list-element {
  padding: 5px !important;
  font-size: 1.1em;
}

.match-list-element-date {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #f0f0f0;
}

.match-list-element-live-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.match-list-element-status {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d6d6d6;
}

.match-list-element-status-live {
  color: #ff0046;
  font-weight: bold;
}

.match-list-element-team-result.match-list-element-team-result-live {
  color: #ff0046;
  font-weight: bold;
}

.match-list-element-status-postponed {
  color: #808080 !important;
}

.match-list-element-team-image {
  margin-right: 10px;
  margin-left: 20px;
  align-items: center;
  display: flex;
}

.match-list-element-team-result {
  align-items: center;
  display: flex;
  justify-content: center;
}

.match-date-selector .match-date-selector-button {
  width: 100%;
}

.match-date-selector .match-date-selector-select {
  width: 100%;
  padding-right: 2px;
  padding-left: 2px;
}

.matches-date .matches-date-radio {
  width: 100%;
}

.matches-date .matches-date-radio .ant-radio-button-wrapper {
  width: 25%;
  text-align: center;
}

.matches-date .matches-date-radio .ant-radio-button-wrapper > span:not(.ant-radio-button) {
  display: inline-block;
  width: 100%;
}

.match-accordions .ant-collapse-content-box {
  padding: 0 !important;
}

.match-accordions .match-accordions-show-matches-message {
  color: #808080 !important;
}

.match-accordions-missing-data-message {
  font-size: 14px;
  margin-bottom: 5px;
}

.match-accordions .go-to-competition:hover {
  text-decoration: none;
}

.match-accordions .go-to-competition:hover {
  text-decoration: underline;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #f4c400 !important;
  border-color: #f4c400 !important;
}

.ant-select-selector:hover,
.ant-select:focus {
  color: #f4c400 !important;
  border-color: #f4c400 !important;
}

.adv {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-card .ant-card-body {
  padding: 10px;
}

@media screen and (max-width: 640px) {
  .news-card .ant-card-body {
    padding: 0;
  }

  .news-card.ant-card-bordered {
    border: 0;
  }
}

.news-content-div ul,
.news-content-div ol {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2em;
}

.news-content-div ul li {
  list-style: disc outside none;
  display: list-item;
}

.news-content-div ol li {
  list-style: decimal outside none;
  display: list-item;
}

.news-content-div h1,
.news-content-div h2,
.news-content-div h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.news-content-div a {
  color: #f4c400 !important;
}

.news-content-div p {
  /*margin-bottom: 0 !important;*/
}

.news-content-div p br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 0;
}

.news-contend-div blockquote {
  display: block !important;
  margin: 1em 40px !important;
}

/*.oddspedia-iframe {*/
/*  overflow: hidden;*/
/*}*/

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3;
  border-radius: 999px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3b3535;
  border-radius: 999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3b3535;
}

.guide-description {
  font-size: 12px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.guide-list-date-div {
  position: absolute;
  right: 10px;
  color: #191919;
  font-style: italic;
  font-weight: normal;
}
