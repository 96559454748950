html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif !important;
  background-color: white !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.marginZero {
  margin: 0 !important;
}

.marginZeroBanners {
  margin: 30px 0 0 0 !important;
}

.marginTopPupiBanners {
  margin-top: 30px !important;
}

.marginTopBottomFive {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.marginBottomTen {
  margin-bottom: 10px !important;
}

.paddingFive {
  padding: 5px !important;
}
.paddingZero {
  padding: 0 !important;
}

.paddingLeftRightFive {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.paddingTopFive {
  padding-top: 5px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #f4c400;
}

.swiper-container {
  /*border-style: solid;*/
  border-color: #282828;
  border-width: thin;
  border-radius: 5px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-wrapper {
  background-color: #d3d3d3;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mainSwiper {
  height: 400px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.mainSwiperStaticRPP {
  height: 800px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 25px;
}

@media screen and (max-width: 600px) {
  .mainSwiperStaticRPP {
    height: 324px;
  }
  .mainSwiper .swiper-slide img {
    height: 322px;
  }
}

.thumbsSwiper {
  height: 140px;
  box-sizing: border-box;
  padding: 10px 0;
  margin-bottom: 5px;
}

.thumbsSwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.thumbsSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mainSwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 398px;
  object-fit: cover;
  object-position: top;
}

.mainSwiperStaticRPP .swiper-slide img {
  display: block;
  width: 100%;
  height: 798px;
  object-fit: cover;
  object-position: top;
}

.thumbsSwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 140px;
  object-fit: cover;
  object-position: top;
}

.mainSwiper .swiper-slide {
  max-width: 848px;
}

.thumbsSwiper .swiper-slide {
  max-width: 204px;
}

.swiper-slide .swiper-slide-active {
  width: 100%;
  margin-right: 0 !important;
}

.table {
  margin-bottom: 0;
}

.table-headbar {
  padding: 15px;
}

.table-footbar {
  margin-top: 7px;
}

.table td {
  vertical-align: middle !important;
}

.table td,
.table th {
  text-align: center !important;
}

.div-processing-container {
  display: block;
  height: 0;
  width: 100%;
}

.processing {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  text-align: center;
  padding: 1em 0;
  z-index: 999;
  background-color: #ffffff;
  border: 1px solid #999999 !important;
  /*background-color: var(--analytik-bg-color);*/
  border-radius: 2px 2px 0 0;
}

.missingDataCell {
  color: #666666;
  font-style: italic;
}

.anticon:not(.ant-menu-item-icon) {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hs-border-div {
  /*border-style: solid;*/
  border-color: #282828;
  border-width: thin;
  border-radius: 5px !important;
}

.last {
  margin-bottom: 10px;
}

/*iframe Betting*/
.odd-comparison-style {
  margin-bottom: 10px;
}

.home-match {
  background: linear-gradient(0deg, #ffffff, #d3d3d3 80%);
  padding: 10px;
}

.radarGroupsLoadingIcon {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;
  width: 100%;
  font-size: 80px;
  color: #282828;
}

.playerRadarSeasonDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playerRadarSeasonTitleDiv {
  margin-right: 20px;
  font-weight: bold;
  font-size: 13px;
}

.playerRadarTitleDiv {
  text-align: center;
  font-weight: bold;
  background-color: #d6d6d6;
  padding: 5px;
  font-size: 15px;
}

.radarGroupDiv {
  border: 1px solid #d6d6d6;
  padding: 5px;
  margin: 5px;
}

.playerRadarContainerDiv {
  margin-top: 10px;
}

.playerRadarNoDataDiv {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d6d6d6;
  font-size: 20px;
}

.playerRadarSeasonSelectDiv {
  display: flex;
  align-items: center;
}

.teamRadarContainerDiv {
  margin: 10px;
}

.anticon svg {
  height: 100%;
}

.authorsNews-span {
  text-align: left;
  float: left;
}

.authorsNews {
  text-align: right;
  color: #6c6c6c;
  font-style: italic;
}

.dateNews-span {
  text-align: right;
  float: right;
  color: white;
}

.dateNews {
  text-align: right;
  color: #6c6c6c;
  font-style: italic;
}

.hs-border-image {
  border-style: solid;
  border-color: #282828;
  border-width: thin;
  border-radius: 5px !important;
}

.person-image-player {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  object-fit: cover !important;
  object-position: top !important;
  border-style: solid !important;
  border-color: #f4c400 !important;
  border-width: medium !important;
}

.info-person-image img {
  object-fit: cover !important;
  object-position: top !important;
  border-style: solid !important;
  border-color: #f4c400 !important;
  border-width: medium !important;
}

.table-setting-player {
  line-height: 1;
  position: relative !important;
}
.table-setting-player td {
  padding-left: 10px;
  padding-right: 10px;
}
.div-table-setting-player {
  float: left;
  width: calc(100% - 200px);
  margin-top: 10px;
}

.table-text-title {
  text-align: left;
}
.table-text-body {
  text-align: right;
}
.select-size {
  font-size: 1.5rem;
}
.competition-container {
  margin-bottom: 5px;
}
.team-name {
  font-size: 1.4rem;
}
.team-shortname {
  font-size: 1.1rem;
}
.btn-match-menu {
  padding: 5px;
}
.info-team {
  height: 100% !important;
  align-items: center !important;
}
.info-team-image {
  width: 140px !important;
}
.info-team dt {
  width: 40% !important;
}
.info-team dd {
  width: 60% !important;
}
.head-players-list {
  border-bottom: 1px solid #d6d6d6;
}
.head-players-list th {
  color: #282828;
  font-size: 1.4rem;
}
.info-head-role {
  color: #5a5a5a;
  background-color: #f7f7f7;
}
.entry td {
  height: auto;
}
.entry img {
  border-radius: 50%;
  margin-top: 6px;
}
.team-image a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-image img {
  border-radius: 0;
}
.info-person-image {
  width: 48px;
}
.hs-search-btn {
  /*border-style: solid;*/
  border-color: #282828;
  /*border-width: thin;*/
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.module-select {
  border-radius: 5px;
}
.navigation {
  border-radius: 5px;
}
.hs-page-header-competition {
  padding: 0 !important;
}
@media screen and (max-width: 768px) {
  .swiper-slide-visible p {
    font-size: 8px !important;
  }
  .div-table-setting-player {
    width: 100%;
    margin-top: 0;
  }
}

.transformCorrelationStatImage {
  max-width: 20px;
  max-height: 20px;
}

.correlationGraphDataDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.correlationGraphDataValueDiv {
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  padding: 3px 10px;
  background-color: #1ed760;
  border-radius: 5px;
}

.hs-widget-newsletter-body > .hs-widget-input-div {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hs-widget-newsletter-body > .hs-widget-input-div-name {
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hs-widget-newsletter-body > .hs-widget-input-div > .hs-widget-input-value-div {
  width: 100%;
  margin-left: 10px;
  font-size: 12px;
}

.hs-widget-newsletter-body > .hs-widget-input-div-name > .hs-widget-input-value-div {
  width: 100%;
  font-size: 16px;
}

.hs-widget-input-text {
  background: transparent;
  border: none;
  border-bottom: 1px solid #d3d3d3;
}

.hs-widget-input-text:focus,
.hs-widget-input-text:focus-visible {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
}

.hs-widget-newsletter-body > .hs-widget-input-div > .hs-widget-label-div {
  font-weight: bold;
}

.hs-widget-newsletter-button-div {
  text-align: center;
  padding: 10px;
}

.hs-widget-newsletter-button {
  width: 100%;
  border-radius: 5px;
  background-color: #f4c200;
  border: none;
  padding: 5px;
  font-size: 1.8rem;
}

.hs-widget-match-container {
  padding: 3px 5px;
}

.hs-widget-match-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hs-widget-match-element-team {
  display: flex;
  width: 35%;
  align-items: center;
  justify-content: flex-start;
}

.hs-widget-match-element-team-image {
  max-width: 30px;
  max-height: 30px;
  height: auto;
  width: auto;
}

.hs-widget-match-element-away-team {
  justify-content: flex-end !important;
}

.hs-widget-match-element-team-name {
  margin: 0 5px;
  font-weight: bold;
}

.hs-widget-match-date {
  margin-bottom: 3px;
}

.big-match-detail-global-div {
  width: 100%;
}

.round-fullname {
  margin-left: 5px;
}

.last-five-match-result-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 58px);
  margin-top: 10px;
}

.single-match-result-div {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
}

.single-match-result-div-win {
  background-color: #52c77e;
}

.single-match-result-div-draw {
  background-color: #ffd326;
}

.single-match-result-div-lose {
  background-color: #c55;
}

.single-match-result {
  height: 14px;
  width: 14px;
  color: #fff;
  /*font-family: "Source Sans Pro";*/
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  text-align: center;
}

.match-result-separator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.last-five-match-result-home {
  justify-content: flex-end !important;
}

.team-flag-with-standing {
  position: relative;
}

.team-pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-standing-div {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #f4c400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-standing {
  color: #000;
  /*font-family: "Source Sans Pro";*/
  font-size: 12px;
  font-weight: 900;
  line-height: 15px;
  text-align: center;
}

.big-match-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hs-bg-component {
  margin: 0;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.img-round-white {
  border-radius: 50%;
  background-color: #fff;
  padding: 10px;
}

.progressbar {
  background-color: #282828;
  border-radius: 5px;
}

.progressbar > div {
  background-color: #f4c200;
  width: 72%;
  height: 5px;
  border-radius: 5px;
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

.arrow {
  border: solid #3cee82;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.arrow-black {
  border: solid #000000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.team-button-stats {
  display: flex;
  justify-content: flex-start;
  text-align: center;
}

.team-button-stats-away {
  justify-content: flex-end !important;
}

.single-button-stats {
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  /*font-family: "Source Sans Pro";*/
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: inherit;
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
  background: #f1f1f1;
  white-space: nowrap;
}

.single-button-stats-active {
  color: #000 !important;
  border-radius: 5px;
  background-color: #f4c200;
  border: none;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  white-space: nowrap;
}

.statsBiggerValue {
  font-weight: bold;
}

.team_person-sub {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}

.team_person-role {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.team_person-role-goalkeeper {
  background-color: lightgrey;
}

.team_person-role-defender {
  background-color: green;
}

.team_person-role-midfielder {
  background-color: lightblue;
}

.team_person-role-forward {
  background-color: yellow;
}

.hs-widget-newsletter-body-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
}

.hs-widget-input-footer-div {
  width: 50% !important;
}

.hs-widget-newsletter-global-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hs-widget-newsletter-button-div-footer {
  width: 30%;
}
.hs-block-header-footer {
  background-color: #000000 !important;
}

.card-video {
  box-shadow: 0 4px 8px 0 rgb(0 0 0);
  transition: 0.3s;
  cursor: pointer;
  margin: 0 0 10px 0 !important;
  border-radius: 5px;
}

.img-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hs-select-roll-of-honour {
  width: 100%;
  height: 1px;
}

.team-winner-not-available {
  color: lightgrey;
}

.team-winner-available {
  font-weight: bold;
}

.team-competition-season {
  width: 200px !important;
}

.hs-bg-component-live-event {
  margin: 0 !important;
  font-size: 13px;
}

.team-live-pointer {
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.social .text-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.social-logo {
  padding: 5px;
}

.footer-privacy {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}

.footer-privacy a {
  color: #a8a8a8;
  margin-right: 5px;
}

.footer-privacy a:hover {
  color: #ffffff;
}
.footer-desc-subtitle {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1rem;
}

.sub-in {
  display: block !important;
  width: 18px !important;
  height: 25px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sub-out {
  display: block !important;
  width: 18px !important;
  height: 25px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media screen and (max-width: 700px) {
  .single-button-stats {
    font-size: 9px;
  }
  .single-button-stats-active {
    font-size: 9px;
  }
  .footer-privacy {
    padding: 10px;
  }
  .footer-desc {
    padding: 10px;
  }
  .hs-copyright {
    padding: 10px;
  }
  .last-five-match-result-div {
    width: calc(50% - 22px);
    margin-bottom: 10px;
  }
  .all-numbers {
    padding: 0 !important;
    /*width: 100% !important;*/
  }
}

@media screen and (max-width: 400px) {
  .single-button-stats {
    font-size: 7px;
  }
  .single-button-stats-active {
    font-size: 7px;
  }
}

@media screen and (max-width: 350px) {
  .single-button-stats {
    font-size: 6px;
  }
  .single-button-stats-active {
    font-size: 6px;
  }
}

@media screen and (min-width: 701px) {
  .all-numbers {
    /*padding-left: 20px !important;
    padding-right: 20px !important;
    width: 100% !important;*/
  }
}

.swiper-pagination-bullet-active {
  background: #f4c400 !important;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.paddingBottom20 {
  padding-bottom: 20px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.paddingTop20 {
  padding-top: 20px !important;
}

.competitionSeasonLabel {
  font-size: 14px;
}

.competitionSeasonValue {
  font-size: 20px;
  font-weight: bold;
}

.competitionSeasonValueLarge {
  font-size: 44px;
  font-weight: bold;
  color: black;
}

.centerColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.paddingLeftRight15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.centerColumn .ant-progress-text {
  font-size: 20px;
  font-weight: bold;
}

.separator {
  width: 90%;
  margin: auto;
  color: #d6d6d6;
}

.colStats {
  border: 1px solid #d6d6d6;
  padding: 25px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.competitionSeasonStatsTitle {
  font-size: 20px;
  font-weight: bold;
  margin-left: 15px;
}

.all-numbers {
  color: #4a4a4a;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  /*top: -15px;
  padding-bottom: 10px !important;
  margin-bottom: -10px;
  width: 20% !important;*/
  text-transform: uppercase;
}

.hs-newsletter-name {
  font-size: 16px;
}

.newsletter-link {
  color: #000000;
  text-decoration: underline;
}

.newsletter-link:hover {
  color: #f4c200;
}

.newsletter-final-message {
  font-size: 16px;
  margin-top: 10px;
}

.team_person-shirtnumber {
  width: 10%;
}

.bottomBorder {
  border-bottom: 1px solid #d6d6d6;
}

@media screen and (max-width: 768px) {
  .leftBorder {
    border-left: unset !important;
  }
}

.leftBorder {
  border-left: 1px solid #d6d6d6;
}

.width70 {
  width: 70%;
}

.betting-stats-match {
  width: 100%;
}
.live-link-default {
  text-decoration: underline;
  cursor: pointer;
}
.live-text-default {
  font-size: 16px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f4c200 !important;
}

.ant-tabs-tab:hover {
  color: #f4c200 !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #f4c200 !important;
  pointer-events: none;
}

.countryImage {
  width: 20px;
  margin-right: 5px;
  border-radius: unset !important;
  margin-top: unset !important;
}

.countryImageFixNext {
  width: 0 !important;
  height: auto !important;
  min-width: 100% !important;
  max-width: 100% !important;
  min-height: auto !important;
  max-height: 100% !important;
}

.container .navigation-bar .ant-select.navigation.ant-select-single.ant-select-show-arrow {
  width: 100%;
  border: unset !important;
}

.container .navigation-bar .ant-select-selector {
  border-radius: 10px !important;
}

.container .navigation-bar .ant-select-arrow {
}

.footer-kama {
  padding-top: 10px;
}
.footer-kama,
.footer-kama a {
  color: #a8a8a8;
  font-size: 12px;
}
.footer-kama a:hover {
  color: #ffffff;
}

.newsImageCopyright {
  margin-top: 5px;
  color: #a8a8a8;
}

.match-of-the-day-div {
  width: 100%;
  display: flex;
  height: 250px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.match-of-the-day-div ins {
  width: 100%;
}

body .hs-gameplan .hs-head .match-date.match-date-comparison {
  margin: 0 0 -8px 0;
  line-height: 11px;
  font-size: 11px;
}

.country-table-header {
  background-color: #c8c8c8;
  border-radius: 5px !important;
}

.country-table-header > th {
  padding-left: 5px;
  padding-right: 5px;
  color: #000000;
  font-weight: 600 !important;
}

.competition-table-header {
  background-color: #c8c8c8 !important;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
}

.matchTeamComparisonGameplan > div > .empty-match-date {
  display: none !important;
}

.dugout-float {
  display: none;
  top: auto !important;
  bottom: 8px;
}

/*Trick per l'ADV*/
#HB_Footer_Close_prj_320x480_int {
  display: none !important;
}
#HB_Footer_Close_prj_300x250_fixed {
  display: none !important;
}
.vidcar4s {
  display: none !important;
}
#ogy-root-container-306266_default {
  display: none !important;
}

.countryImageDiv {
  display: flex;
  align-items: center;
}
.countryImageDiv:first-child {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

dd .countryImageDiv {
  justify-content: flex-end;
}

.table-text-body .countryImageDiv {
  justify-content: flex-end;
}

.countryImageDiv img {
  padding-right: 3px !important;
}

.player-profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.privacy-container {
  padding: 30px 20px;
}

#nprogress .bar {
  background: #f4c400 !important;
  height: 4px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #f4c400, 0 0 5px #f4c400 !important;
}

#nprogress .spinner-icon {
  width: 28px !important;
  height: 28px !important;
  border-top-color: #f4c400 !important;
  border-left-color: #f4c400 !important;
}

.empty.empty-match.empty-match-minutes {
  display: flex !important;
  align-items: center;
}

.empty.empty-match.empty-match-minutes > span {
  font-weight: 400;
  margin-left: 5px;
  margin-right: 10px;
  font-size: 14px;
}

.ant-btn .anticon {
  display: contents !important;
}

.match-select-date-calendar .ant-picker-date-panel {
  width: unset !important;
}

.match-select-date-calendar {
  margin-bottom: 10px;
  margin-top: 10px;
}
.match-select-date-calendar .ant-picker-calendar {
  padding: 5px;
  border: 1px solid #d9d9d9;
  margin-bottom: 5px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #000 !important;
  background: #f4c400 !important;
}

.ant-collapse-header {
  align-items: center !important;
  display: flex;
}

.ant-collapse-header > div {
  align-items: center;
  display: flex;
}

.match-select-date-calendar .button-select-date-div {
  display: block;
  text-align: right;
}

.match-select-date-calendar .button-select-date {
  color: #000 !important;
  background: #f4c400 !important;
  border-color: unset !important;
}

.match-select-date-calendar .button-select-date:hover {
  background: rgba(244, 196, 0, 0.7) !important;
}

.marginTop10 {
  margin-top: 10px;
}

.go-to-live-results-div {
  margin-bottom: 5px;
}

.go-to-live-results-div strong {
  color: #f4c200;
}

.go-to-live-results-div .arrow {
  border: solid #f4c200;
  border-width: 0 2px 2px 0;
}

.news-related-element-div {
  display: flex;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 5px;
}

.news-related-element-div:hover {
  cursor: pointer;
  border: 1px solid #f4c200;
  background-color: #f6f6f6;
}

.news-related-element-div .news-image-title {
  /*display: flex;*/
}

.news-related-element-div .news-image-div {
  /*display: flex;*/
  width: 120px;
}

.news-related-element-div .news-image {
  border-radius: 5px;
}

.news-related-element-div .news-title {
  font-weight: 600;
  font-size: 1.2em;
}

.news-related-element-div .news-title-content {
  margin-left: 5px;
}

/*.news-related-element-div:hover .news-title {*/
/*  color: #f4c200;*/
/*}*/

.ant-tabs-nav-list {
  display: flex;
  justify-content: center;
}

.team-h1 {
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 2.5em;
  background: #3c3c3b;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #3c3c3b;
}

.hs-content-404 {
  text-align: center;
  padding: 12px;
  border: 5px solid #f4c200;
  border-radius: 5px;
  color: #3c3c3b;
}

.p404 {
  font-size: 28px;
  font-weight: bold;
  line-height: 0.8em;
  margin-bottom: 10px;
  font-variant: small-caps;
}

.p404description {
  font-size: 22px;
  margin-bottom: 0;
}

.p404currentMatches {
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

.dugout-container {
  position: relative;
  height: 760px;
  overflow: hidden;
  border-radius: 5px;
}

@media screen and (max-width: 1100px) {
  .dugout-container {
    position: relative;
    padding-bottom: 115%;
    height: 0;
    overflow: hidden;
    border-radius: 5px;
  }
}

.mobile-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mobile-only {
    display: inline;
  }
}
