.pageContainer {
  display: flex;
  font-size: 14px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    .filterContainer {
      display: none;
    }
    .sideBookmakers {
      display: none;
    }
  }

  .filterContainer {
    padding: 0;
  }

  .bodyContainer {
    .headerBookmakers {
      display: flex;
      justify-content: center;
      align-items: center;
      position: sticky;
      z-index: 999 !important;
      top: 0;
      margin: 0;
      background-color: white;
    }
    .body {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .sideBookmakers {
    position: sticky;
    top: 0.5rem;
    height: 100%;
    margin-top: 0.5rem;
    padding: 0;
  }
}
