.global-search-input-container {
  width: 100%;

  .closedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.desktopContainer {
    position: static !important;
    .global-search-results-container {
      background-color: white;
      border: 2px solid #d9d9d9;
      top: 80px;
    }
  }

  .global-search-title {
    text-transform: initial;
    font-size: 16px;
    color: #ffffff;
    margin-right: 10px;
    min-width: 150px;
  }

  @media (min-width: 768px) {
    margin: 5px 0;
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: 768px) {
    .global-search-title {
      font-size: 14px;
      color: #ffffff;
      padding-bottom: 5px;
    }
  }

  .global-search-backdrop {
    position: fixed;
    left: 80px;
    right: 0;
    top: var(--admin-header-height);
    bottom: 0;
    z-index: 1027;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  .global-search-input {
    z-index: 999;
    border-style: solid;
    border-width: thin;
    background-color: transparent;
    border-radius: 10px;
    border-color: #3b3535;
  }
  .ant-input {
    background-color: transparent;
    &:placeholder-shown {
      font-size: 14px;
      color: #4a4a4a;
      background-color: transparent;
    }
    &::placeholder {
      color: #3b3535;
      font-weight: 600;
    }
  }
  .global-search-input:hover {
    border-style: solid !important;
    border-width: thin !important;
    border-color: #3b3535;
  }

  .global-search-results-container {
    position: absolute;
    top: 42px;
    width: 100%;
    @media (min-width: 768px) {
      height: 500px;
      width: 1000px;
    }
    right: 0;
    z-index: 1029;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    > .ant-card {
      height: calc(100% - 1px) !important;
      margin: 0px;
      flex: 1;
      background-color: transparent !important;

      &.ant-card-bordered {
        border: none;
      }

      > .ant-card-body {
        display: flex;
        height: 100%;
        padding: 0;
        > .ant-card-grid {
          box-shadow: none !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .global-search-results-container {
      height: auto;
      top: unset !important;
      margin-top: 15px;
    }
    .global-search-results-container > .ant-card > .ant-card-body {
      font-size: 15px !important;
      display: grid !important;
      .h4 {
        font-size: 15px !important;
      }
    }
    .global-search-results-grid--players,
    .global-search-results-grid--teams,
    .global-search-results-grid--liveMatches,
    .global-search-results-grid--matches,
    .global-search-results-grid--news {
      width: 100%;
      max-height: 220px;
    }
  }

  .global-search-results-grid {
    flex: 1;
    padding: 8px 8px;
    display: flex;
    flex-direction: column;

    &.global-search-results-grid--players {
      flex: 1.2;
    }
    &.global-search-results-grid--teams {
      flex: 1.2;
    }
    &.global-search-results-grid--matches {
      flex: 2;
    }
    &.global-search-results-grid--news {
      flex: 1;
    }

    .global-search-results-loading-container {
      margin: auto;
    }

    .global-search-results-no-results-container {
      text-transform: none;
      margin-top: 20px;
    }

    .global-search-results-list {
      overflow-y: auto;

      > div {
        border-bottom: 1px solid #888;
        margin-bottom: 4px;
        padding-bottom: 4px;
        cursor: pointer;
      }

      // PLAYER & TEAM
      .global-search-player,
      .global-search-team,
      .global-search-news {
        display: flex;
        flex-direction: row;

        .image-container {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 44px !important;
            height: 44px !important;
          }
        }

        .text-container {
          display: flex;
          flex-direction: column;
          margin: auto 0;
          margin-left: 6px;

          .title {
            &.news {
              font-weight: 400;
            }
          }

          .name {
            font-weight: 600;
            font-size: 1.1em;
            line-height: 1.1;
          }

          .team,
          .country {
            line-height: 1.1;
            font-weight: normal;
          }

          .role,
          .status {
            line-height: 1.1;
            font-size: 0.9em;
            font-style: italic;
          }
        }
      }

      .global-search-player > .image-container > img {
        border-style: solid;
        border-color: #f4c400;
        border-width: thin;
      }

      // MATCH
      .global-search-match {
        display: flex;
        flex-direction: row;

        .left-team,
        .right-team {
          display: flex;
          flex-direction: row;
          flex: 1;
          overflow: hidden;

          > div {
            vertical-align: middle;
          }

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 40px;
          }
          .name {
            font-weight: 600;
            font-size: 1.1em;
            line-height: 1.1;
            flex: 1;
            margin: auto 0;
            padding: 0px 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .score {
            margin: auto 0;
            text-align: end;
            font-weight: 600;
            font-size: 1.1em;
            line-height: 1.1;
          }
        }

        .right-team {
          flex-direction: row-reverse;

          .name {
            text-align: end;
          }
          .score {
            text-align: start;
          }
        }

        .score-separator {
          margin: auto 0;
          padding: 0 4px;
        }

        .date {
          margin: auto 0;
          line-height: 1.1;
          font-size: 0.8em;
          text-align: right;
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
  }
}
