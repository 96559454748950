.container-row {
  padding: 20px;
  background-color: white;

  @media screen and (max-width: 992px) {
    padding: 12px;
  }

  .page-mobile-col {
    @media screen and (max-width: 992px) {
      margin-bottom: 10px;
    }
  }

  .page-desktop-row {
    margin: 0 0 20px 0;
    @media screen and (max-width: 992px) {
      margin-bottom: 0;
    }
  }

  .page-mobile-row {
    @media screen and (max-width: 992px) {
      margin-bottom: 10px;
    }
  }
}
.container-row:has(.mainPageContainer) {
  padding: 0;

  // @media screen and (max-width: 992px) {
  //   padding: 12px;
  // }
}
