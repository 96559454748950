.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: white;

  .list {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 5px;
    width: 100%;
    margin: 0.5rem 0;
  }
  .card {
    cursor: pointer;
    display: flex;
    flex: 1;
    border-radius: 5px;
    padding: 0.8rem 0.4rem;
    box-sizing: border-box;
    min-height: 140px;

    .body {
      width: 100%;
      margin: 0;
    }
    .image {
      display: flex;
      align-items: center;
      flex-direction: column;

      .stars {
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
          display: none;
        }
        .condition {
          margin-left: 5px;
        }
      }
    }
    .review {
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      .text {
        line-height: 1.1;
        text-align: start;
        span {
          font-weight: 600;
        }
        .extra {
          font-weight: 500;
        }
      }
      .button {
        .text-button-bonus-cc {
          padding: 1rem;
          a {
            height: 30px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    .card {
      min-height: 125px;

      .body {
        font-size: 10px;
      }

      .image {
        width: 100%;
      }

      .review {
        width: 100%;
      }
    }
  }
}
