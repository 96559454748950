.backoffice-layout {
  min-height: 100vh;
  font-size: 12px;
}

.backoffice-layout .backoffice-content-div {
  margin-top: 55px;
}

.backoffice-layout .backoffice-header {
  height: 55px;
  position: fixed;
  z-index: 100;
  width: 100%;
  background: #001529;
}

.backoffice-layout .backoffice-content-inner-div {
  padding: 10px;
}

.backoffice-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 101;
}

.backoffice-sider.is-responsive .ant-layout-sider-trigger {
  display: none;
}

.logo {
  height: 55px;
  margin-bottom: 20px;
  background: #001529;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-form {
  width: 100%;
  max-width: 600px
}

.login-form .label {
  font-weight: bold;
}

.login-form .label-row {
  margin-bottom: 5px;
}

.login-form .input-row {
  margin-bottom: 15px;
}

.login-form .login-button {
  margin-top: 5px;
  width: 100%;
}

.backoffice-form {
  width: 100%;
  max-width: 800px;
}

.backoffice-form .label {
  font-weight: bold;
}

.backoffice-form .label-row {
  margin-bottom: 5px;
}

.backoffice-form .input-row {
  margin-bottom: 15px;
}

.backoffice-form .action-button {
  margin-top: 5px;
  width: 100%;
}

.backoffice-form-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.backoffice-form .ant-select {
  width: 100%;
}

.ant-picker-date-panel {
  width: 300px !important;
}

.backoffice-form .ant-picker {
  width: 100%
}

.image-handler-div .image-uploaded {
  max-height: 250px;
  max-width: 100%;
}

.image-handler-div {
  margin-bottom: 5px;
}

.image-handler-div .ant-empty {
  text-align: left;
}

.ant-spin-nested-loading > div > .ant-spin {
  margin-top: 50%;
  margin-bottom: 50%;
}

.action-toolbar {
  display: flex;
}

.action-toolbar .ant-btn {
  margin-right: 5px;
}

/*.success-modal .ant-modal-body {*/
/*  text-align: center !important;*/
/*}*/

.success-modal .ant-modal-confirm-body > .anticon {
  /*float: unset !important;*/
  /*margin-right: unset !important;*/
  /*font-size: 22px !important;*/
  color: #52c41a !important;
  /*margin-bottom: 10px !important;*/
}

/*.success-modal .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {*/
/*  margin-left: unset !important;*/
/*  text-align: justify !important;*/
/*}*/