.calciocom-header-block-header-div {
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
  }
}
.calciocom-header {
  margin-top: 1rem;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
  .headerMainContainer {
    padding: 0;
    border-bottom: 1px solid transparent;
    @media screen and (max-width: 768px) {
      border: none;
      padding-bottom: 0;
      padding: 8px;
    }
    padding-bottom: 1rem !important;
  }
  .headerContainer {
    border-bottom: 1px solid #888888;
    @media screen and (max-width: 768px) {
      border: none;
    }
  }
  .hs-menu--item {
    position: unset;
    a {
      padding: 0;
      border-bottom: 3px solid transparent;
    }
    &.liveAndResults {
      a {
        width: 124px;
      }
    }
  }
  .hs-menu--item-active {
    a {
      border-bottom: 3px solid #f5a623;
    }
  }
  @media screen and (max-width: 768px) {
    background-color: #eaeaea;
    .container {
      position: unset;
      .hs-menu-level-burger {
        position: unset;
        .module-double-menu {
          position: unset;
          .hs-menu--item {
            position: unset;
          }
        }
      }
    }
    .calciocom-header-background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .hs-menu-level-burger {
      width: unset;
      .module-double-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .hs-menu--list {
          margin: 0 5px;
        }
      }
      .hs-filter-and-search-menu {
        height: 75vh;
        width: 90%;
        &.filterOnly {
          height: 90vh;
        }
      }
      .hs-menu--list-burger {
        .hs-menu--list-sub {
          position: absolute;
          top: 80px;
          margin: 0;
        }
      }
      .hs-menu--list-search {
        .hs-menu--list-sub {
          position: fixed !important;
          top: 80px;
          bottom: 0;
          overflow-x: hidden;
        }
      }
      .hs-menu--burger-icon {
        font-size: 24px;
        color: #b8b8b8;
      }
      .hs-menu--list {
        background-color: #eaeaea;
        .hs-menu--competition-container-div {
          margin: 20px;
          .hs-menu--competition-label {
            color: #6e6e6e;
            font-weight: bold;
            text-transform: initial;
          }
          .competition-burger-link {
            background: #f7f7f7;
            width: 100%;
            border-radius: 10px;
            margin: 1px 0;
            .competition-burger-link-name {
              margin-left: 20px;
            }
          }
        }
      }
      .hs-menu--more {
        z-index: 1000;
        .hs-menu--list-more {
          display: flex;
        }
      }
    }
  }
}

.new-menu-item-with-sub-menu {
  position: relative;
  z-index: 10000;
  @media screen and (max-width: 768px) {
    a {
      padding-bottom: 0 !important;
    }
  }
  .sub-menu-container-div:hover {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .sub-menu-container-div {
    display: none;
    padding: 10px;
    border-radius: 6px;
    border: 0.5px solid #d3d3d3;
    background-color: #ffffff;
    position: fixed;
    width: fit-content;
    min-width: 110px;
    margin: 0 10px;
    z-index: 9999 !important;
    @media screen and (max-width: 768px) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      position: relative;
      border-radius: 0;
      border: 0;
      margin-top: 0;
      padding: 0 10px;
      background-color: unset;
      a {
        padding: 10px !important;
      }
    }
    @media screen and (min-width: 768px) {
      .sub-menu-element-div {
        color: #888888;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 0;
        text-align: left;
        a {
          padding-left: 0;
        }
      }
    }
    .sub-menu-element-div {
      text-transform: initial;
    }
  }
}
.new-menu-item-with-sub-menu:hover {
  .sub-menu-container-div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}

.calciocom-page {
  .container-row {
    border-radius: 10px;
    margin: 5px 0;
  }
  .paddingFive {
    border-radius: 10px;
    margin: 20px 0;
  }
  @media screen and (max-width: 768px) {
    .paddingFive {
      margin: 10px 0;
    }
  }
}

.filter-and-resarch-bar-div {
  background: #888888;
  .container {
    .row {
      align-items: center;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.shorcut-competition-div {
  background: #f7f7f7;
  border-bottom: 2px solid #eaeaea;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.home-page {
  padding: 0 !important;
  .liveWidget {
    padding: 0 2px;
  }
  .title-banner-cc {
    margin-bottom: 20px;
    margin-top: 50px;

    &.home-team-stats {
      margin-top: 0;
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }

  .pupi-banner-cc {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 992px) {
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }

  .e2-iframe-cc {
    padding: 0;
    margin: 0;
  }

  .bookmaker-bonus-list-cc {
    .header {
      .image-section {
        img {
          filter: invert(28%) sepia(0%) saturate(90%) hue-rotate(229deg) brightness(151%) contrast(100%);
        }
      }
    }
  }

  .news-preview-cc {
    @media screen and (max-width: 992px) {
      margin-bottom: 60px;
    }
  }

  .bookmaker-bonus-list-new-v-cc {
    .header {
      background: white;
      mix-blend-mode: unset;
      padding: 0;

      .image-section {
        img {
          filter: invert(28%) sepia(0%) saturate(90%) hue-rotate(229deg) brightness(91%) contrast(84%);
        }
      }

      .text-section {
        .title-div {
          color: #4a4a4a;
          font-size: 40px;
          font-weight: 900;
          line-height: 22px;

          @media screen and (max-width: 992px) {
            line-height: 40px;
            text-align: center;
          }
        }
        .text-div {
          color: #4a4a4a;
          font-size: 16px;
          line-height: 22px;
          margin: 20px 5px;
          text-align: justify;
        }
      }
    }
  }

  .news-preview-cc {
    @media screen and (max-width: 992px) {
      margin-bottom: 60px;
    }
  }

  .text-div {
    color: #4a4a4a;
    font-size: 16px;
    line-height: 22px;
    margin: 20px 0px;
    text-align: justify;
  }
}

.predictions-page {
  padding: 0 5px !important;
  .pupi-banner-cc {
    display: flex;
    justify-content: flex-end;
  }

  .e2-iframe-cc {
    padding: 0;
    margin: 0;
  }
}

.prediction-page {
  .bookmaker-bonus-list-cc {
    margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 992px) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}

.bet-page {
  .title-banner-cc {
    margin-top: 0 !important;
  }

  .bet-margin {
    margin-top: 30px;
  }
}

.bonus-page {
  .title-banner-cc {
    margin-top: 0 !important;
  }

  .bet-margin {
    margin-top: 30px;
  }

  .bookmaker-bonus-row-new-v-cc {
    @media screen and (max-width: 992px) {
      padding: 5px;
    }
  }
}

.text-div {
  color: #4a4a4a;
  font-size: 16px;
  line-height: 22px;
  margin: 20px 5px;
  text-align: start;
}

.accordion-body {
  color: #4a4a4a;
  font-size: 16px;
  line-height: 22px;
  margin: 20px 5px;
  text-align: justify;
}
