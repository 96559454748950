.bookmakerColumnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  padding: 1rem;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    padding: 9 1rem 1rem 1rem;
  }
  .bookmakerColumn {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }
    .name {
      font-size: 12px;
      a {
        color: #000;
        text-decoration: none;
        &:hover {
          color: #f4c400;
        }
      }
    }
  }
}
