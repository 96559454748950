.container {
  top: 0;
  padding: 0 1rem 1rem 0;
  @media screen and (max-width: 992px) {
    position: unset !important;
    .header {
      .headerIcon {
        justify-content: flex-end !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }

  .header,
  .selector,
  .championships {
    margin: 0;
    .teamsContainer {
      margin-left: 3rem;
      min-height: 0;
      overflow: hidden;
      transition: min-height 0.3s ease;
    }
    .championshipsRow {
      margin: 5px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .downArrow {
        cursor: pointer;
        color: #888;
        margin-left: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 20px;
      margin-right: 1rem;
    }

    @media screen and (max-width: 768px) {
      .teamsContainer {
        margin-left: 3.5rem;
        a {
          padding: 0;
        }
      }
      .championshipsRow {
        margin: 0;
      }
    }
  }
  .header {
    margin: 1rem 0;
    .headerIcon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .championships {
    .label {
      font-size: 15px;
      font-weight: 600;
      margin: 5px 0;
    }
    a {
      margin: 5px 0;
      &:hover {
        color: #f4c400;
      }
    }
  }
}
