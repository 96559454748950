.footerContainer {
  padding: 2rem 10rem;
  font-size: 15px;
  @media screen and (max-width: 768px) {
    padding: 2rem;
    background-color: black !important;

    .footerSection {
      margin-top: 2rem;
      width: 50%;
    }
    .calciocomLogo {
      margin-top: 1rem !important;
    }
  }

  .footerLinks {
    .footerSection {
      .title {
        font-weight: 600;
        margin: 1rem 0;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        color: white;
        margin: 5px 0;
      }

      .links {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        a {
          margin-right: 1rem;
        }
      }
    }
  }

  .footerText {
    margin-top: 2rem;
    a {
      color: white;
    }
    font-size: 12px;
  }

  .calciocomLogo {
    margin: 1rem 0 0 0;
    width: 150px;
    height: auto;
  }
  .footerLogos {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .footerLogosContainer {
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  .footerLogosContainerMobile {
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}
