.mainContainer {
  display: flex;
  background-color: #888;
  justify-content: center;
  align-items: center;

  .container {
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;

    .mobileContainer {
      display: none;
      @media screen and (max-width: 764px) {
        border-bottom: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;
        .linkContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          .splitter {
            color: white;
            text-align: center;
          }
          .link {
            text-align: center;
            a {
              color: white;
              &.active {
                border-bottom: 3px solid #f5a623;
              }
            }
          }
        }
      }
    }

    .desktopContainer {
      display: flex;
      @media screen and (max-width: 576px) {
        padding: 0;
      }
      .linkContainer {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        padding: 0;
        &::-webkit-scrollbar {
          height: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: white;
          border-radius: 10px;
        }

        .link {
          margin: 0 2rem;
          padding: 2rem 0;
          border-bottom: 5px solid transparent;

          &:first-child {
            margin-left: 10px;
          }
          &:has(.active) {
            border-bottom: 5px solid #f5a623;
          }
          @media screen and (max-width: 764px) {
            &.longWord {
              min-width: 130px;
            }
            &:has(.active) {
              border-bottom: 5px solid transparent;
            }
            a {
              &.active {
                color: #f5a623;
              }
            }
          }

          a {
            color: white;
            &.active {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
