.calciocom-navigation-bar-new {
  background-color: #888888;
  .country-separator-option {
    cursor: default !important;
  }
  .calciocom-navigation-bar-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #888888;
    .hs-menu-container-element {
      margin: 10px;
    }
    .navigation-selector {
      min-width: 200px;
      .ant-select-selection-placeholder {
        font-size: 14px;
      }
    }
    .navigation-title-div {
      font-size: 16px;
      color: #ffffff;
      margin-right: 10px;
    }
    .navigation-already-selected-div {
      font-size: 14px;
      color: #ffffff;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    text-transform: initial;
    .calciocom-navigation-bar-div {
      flex-direction: column;
      align-items: flex-start;
      .navigation-selector {
        min-width: 100%;
      }
      .hs-menu-container-element {
        margin: 6px 0;
        width: 100%;
      }
      .navigation-already-selected-div {
        font-weight: unset;
        font-size: 14px;
      }
      .navigation-title-div {
        font-size: 14px;
        padding-bottom: 5px;
      }
    }
  }
}

.col-main-bonus-list {
  padding: 0 20px 0;

  @media screen and (max-width: 992px) {
    border-right: 0;
  }
}

.row-banner-right {
  margin-bottom: 20px !important;
  @media screen and (max-width: 992px) {
    padding: 0;
  }
}

.pupi-banner-bonus-cc {
  text-align: center;
}

.bookmaker-bonus-row-cc {
  background: #f7f7f7;
  border-radius: 10px;
  font-size: 16px;
  color: #4a4a4a;
  min-height: 120px;

  @media screen and (max-width: 992px) {
    font-size: 15px;
  }

  .main-row {
    padding: 20px;
  }

  .image-col {
    border-radius: 10px;
    min-height: 80px;

    .image {
      max-height: 60px;
      border-radius: 10px;
    }
  }

  .description-col {
    margin-bottom: 5px;

    .description {
      line-height: 20px;
      width: 130px;
    }
  }

  .bonus-col {
    line-height: 20px;

    .bonus {
      line-height: 34px;
      font-size: 28px;
      font-weight: 700;

      @media screen and (max-width: 992px) {
        line-height: 29px;
        font-size: 22px;
      }
    }

    .extra {
      margin-left: 5px;
    }
  }

  .button-col {
    @media screen and (max-width: 992px) {
      margin-top: 20px;
    }

    .text-button-cc {
      width: 100%;
    }
  }

  &.mobile {
    .bonus-col {
      .bonus {
        line-height: 29px;
        font-size: 22px;
      }
    }
  }
}

.bookmaker-bonus-row-new-v-cc {
  background: #eaeaea;
  font-size: 15px;
  color: #4a4a4a;
  min-height: 150px;
  margin: 20px 0;
  border-radius: 10px;

  .main-row {
    padding: 0;
  }

  .image-col {
    border-radius: 10px;
    min-height: 80px;
    padding: 0 15px;

    .image {
      border-radius: 10px;

      @media screen and (max-width: 992px) {
        margin-bottom: 10px;
      }
    }
  }

  .description-col {
    margin-bottom: 5px;
    padding: 0 20px;
    border-right: 1px solid black;

    @media screen and (max-width: 992px) {
      border-right: 0;
    }

    .description {
      line-height: 20px;
    }
  }

  .description-col-right {
    margin-bottom: 5px;
    padding: 0 20px;

    .description {
      line-height: 20px;
    }
  }

  .text-align {
    text-align: left;

    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }

  .bonus-col {
    line-height: 20px;

    .bonus {
      line-height: 34px;
      font-size: 28px;
      font-weight: 700;

      @media screen and (max-width: 992px) {
        line-height: 29px;
        font-size: 24px;
      }
    }

    .extra {
      margin-left: 5px;
    }
  }

  .button-col {
    @media screen and (max-width: 992px) {
      margin-top: 20px;
    }

    .text-button-cc {
      width: 100%;
    }
  }

  &.mobile {
    .bonus-col {
      .bonus {
        line-height: 28px;
        font-size: 22px;
      }
    }

    .button-col {
      margin-top: 0;
      padding: 0 20px;

      .button-review-col {
        margin-bottom: 10px;
      }
    }
    .verify-button {
      margin-bottom: 1rem;
    }
  }

  .text-description-bonus {
    margin: 10px 0 0 10px;
    font-size: 12px;
    text-align: start;

    @media screen and (max-width: 992px) {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .accordion-div-top {
    height: 10.5px;

    @media screen and (max-width: 1399px) and (min-width: 992px) {
      height: 9.5px;
    }

    @media screen and (max-width: 992px) {
      height: auto;
    }
  }

  .accordion-box-bonus {
    min-height: 100px;
    margin: 25px 0;

    @media screen and (max-width: 992px) {
      text-align: justify;
    }

    .title-box-accordion {
      @media screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }
}

.bookmaker-bonus-row-horizontal {
  @media screen and (max-width: 992px) {
    min-height: 290px !important;
  }
  .main-row {
    margin-bottom: 0 !important;
    min-height: 200px !important;
  }
}

.text-button-cc {
  a {
    height: 45px;
    text-align: center;
    border: none;
    min-width: 160px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4a4a4a;
    border-radius: 10px;

    @media screen and (max-width: 992px) {
      width: 100%;
      height: 40px;
    }
  }

  &.yellow {
    a {
      background: #f4c312;
      box-shadow: 0 4px 0 #d5a806;

      &:hover {
        background-color: #dfb51d;
      }
    }
  }

  &.gray {
    a {
      color: #ffffff;
      background: #6e6e6e;
      box-shadow: 0 4px 0 #494a45;

      &:hover {
        background: #ffffff;
        color: #4a4a4a;
      }
    }
  }
}

.text-button-bonus-cc {
  padding: 0 !important;
  width: 100%;

  &.fakeActive {
    a {
      background: #eaeaea !important;
      border: 1px solid #6e6e6e;
      color: #6e6e6e !important;
    }
  }

  a {
    height: 45px;
    text-align: center;
    border: none;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4a4a4a;
    border-radius: 10px;

    @media screen and (max-width: 992px) {
      width: 100%;
      height: 40px;
    }
  }

  &.yellow {
    a {
      background: #f4c312;
      box-shadow: 0 4px 0 #d5a806;

      &:hover {
        background-color: #dfb51d;
      }
    }
  }

  &.gray {
    a {
      color: #ffffff;
      background: #6e6e6e;
      box-shadow: 0 4px 0 #494a45;

      &:hover {
        background: #ffffff;
        color: #4a4a4a;
      }
    }
  }

  &.white {
    a {
      color: #000000;
      background: #ffffff;
      box-shadow: 0 4px 0 #dcdcdc;

      &:hover {
        background: #f0f0f0;
        color: #000000;
      }
    }
  }
  &.green {
    a {
      color: white;
      background: #009b18;
      font-size: 14px;
      height: 30px;
      border-radius: 5px;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }

      &:hover {
        background: #1aaf30;
        color: white;
      }
    }
  }
}

.button-cc {
  height: 45px;
  text-align: center;
  border: none;
  min-width: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4a4a4a;
  border-radius: 10px;

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 40px;
  }

  &:hover {
    cursor: pointer;
  }

  &.yellow {
    background: #f4c312;
    box-shadow: 0 4px 0 #d5a806;

    &:hover {
      background-color: #dfb51d;
    }
  }

  &.gray {
    color: #ffffff;
    background: #6e6e6e;
    box-shadow: 0 4px 0 #494a45;

    &:hover {
      background: #ffffff;
      color: #4a4a4a;
    }
  }
}

.button-bonus-review {
  height: 45px;
  text-align: center;
  border: 1px solid #dcdcdc;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4a4a4a;
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 93px;

  @media screen and (max-width: 1201px) and (min-width: 992px) {
    font-size: 12px;
    min-width: auto;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 40px;
  }

  &:hover {
    cursor: pointer;
  }

  &.yellow {
    background: #f4c312;
    box-shadow: 0 4px 0 #d5a806;

    &:hover {
      background-color: #dfb51d;
    }
  }

  &.gray {
    color: #ffffff;
    background: #6e6e6e;
    box-shadow: 0 4px 0 #494a45;

    &:hover {
      background: #ffffff;
      color: #4a4a4a;
    }
  }

  &.white {
    color: #000000;
    background: #ffffff;
    box-shadow: 0 4px 0 #dcdcdc;

    &:hover {
      background: #f0f0f0;
      color: #000000;
    }
  }
}

.bookmaker-bonus-list-cc {
  .container-div {
    padding: 20px;
    background: #eaeaea;
    border-radius: 10px;

    @media screen and (max-width: 1201px) {
      padding: 0;
    }

    @media screen and (max-width: 992px) {
      padding-right: 12px;
      padding-left: 12px;
      border-radius: 0;
      background: #ffffff;
    }

    .swiper-button-next {
      right: 3px;
    }

    .swiper-button-prev {
      left: 3px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 50%;

      @media screen and (max-width: 992px) {
        top: 50%;
      }

      &:after {
        color: #6e6e6e;
        font-size: 22px;
        font-weight: 700;

        @media screen and (max-width: 992px) {
          font-size: 19px;
        }
      }
    }
  }

  .header-text-row {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #6e6e6e;
    margin-bottom: 10px;
  }

  .header-row {
    mix-blend-mode: normal;
    border-radius: 10px;
    color: #ffffff;
    background: #888888;
    margin-bottom: 20px;

    @media screen and (max-width: 992px) {
      margin-bottom: 10px;
    }

    .header {
      padding-top: 34px;
      padding-bottom: 34px;
      min-height: 120px;
      background: linear-gradient(90deg, #888888 0%, rgba(0, 0, 0, 0) 96%),
        url(https://s3.eu-central-1.wasabisys.com/calcio-com/ui/bookmaker-bonus-title-image.png);
      background-repeat: no-repeat;
      background-position: right;
      // mix-blend-mode: color-dodge;
      background-size: cover;
      border-radius: 10px;
      display: flex;

      @media screen and (max-width: 992px) {
        padding-top: 14px;
        padding-bottom: 14px;
        min-height: 100px;
      }

      .image-section {
        text-align: right;
        width: 20%;

        .bonus-image {
          width: 41px;
          height: 45px;
          margin-right: 10px;
        }
      }

      .text-section {
        text-align: left;
        width: 80%;

        .text-div {
          font-size: 18px;
          line-height: 22px;
          text-transform: uppercase;
          color: #ffffff;
          max-width: 330px;

          .text-bold {
            font-weight: 600;
          }

          @media screen and (max-width: 992px) {
            max-width: 220px;
          }
        }
      }
    }
  }

  .bookmaker-bonus-row:not(:last-child) {
    margin-bottom: 20px;

    @media screen and (max-width: 992px) {
      margin-bottom: 10px;
    }

    .header-row {
      margin-bottom: 10px;
    }
  }

  &.mobile {
    width: 100%;
    .main-row {
      padding-right: 12px;
      padding-left: 12px;
    }

    .main-row {
      margin-bottom: 10px;
    }

    .header-row {
      margin-bottom: 10px;

      .header {
        padding-top: 14px;
        padding-bottom: 14px;
        min-height: 100px;

        .text-section {
          .text-div {
            max-width: 210px;
          }
        }
      }
    }

    .bookmaker-bonus-row:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.prediction-match-header-cc {
  height: 170px;
  background: url("https://s3.eu-central-1.wasabisys.com/calcio-com/ui/prediction-match-header-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;

  @media screen and (max-width: 992px) {
    background: linear-gradient(360deg, #f4c312 -84.33%, rgba(244, 195, 18, 0) 100%),
      linear-gradient(270deg, rgba(59, 53, 53, 0) 0%, #3b3535 47.97%, rgba(59, 53, 53, 0) 100%),
      url("https://s3.eu-central-1.wasabisys.com/calcio-com/ui/prediction-match-header-bg.png");
    background-position: right;
  }

  .home-team-div-image {
    z-index: 2;
    height: 100px;
    width: 100px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }

  .away-team-div-image {
    z-index: 1;
    height: 100px;
    width: 100px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    right: 10px;
    margin-right: 30px;
  }

  .home-team-div-image,
  .away-team-div-image {
    @media screen and (max-width: 992px) {
      height: 50px;
      width: 50px;
      margin-right: 0;
      margin-bottom: 7px;
    }
  }

  .team-image {
    max-width: 60px;
    max-height: 60px;
    height: auto;
    width: auto;

    @media screen and (max-width: 992px) {
      height: 30px;
      width: 30px;
    }
  }
  .team-image.new {
    @media screen and (max-width: 480px) {
      min-height: 50% !important;
      max-height: 50% !important;
      min-width: 50% !important;
      max-width: 50% !important;
    }
  }

  .container-div {
    @media screen and (max-width: 992px) {
      flex-direction: column;
      text-align: center;
      margin-top: 15px;
    }
  }

  .match-info-div {
    @media screen and (max-width: 992px) {
      position: initial;
    }

    z-index: 2;

    .date-row {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      margin-bottom: 8px;

      @media screen and (max-width: 992px) {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 19px;
        margin-top: -15px;
      }
    }

    .teams-row {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 7px;

      @media screen and (max-width: 992px) {
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .description-row {
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;

      @media screen and (max-width: 992px) {
        font-size: 14px;
        line-height: 17px;
      }

      .competition-row {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .calendar-image {
      height: 19.5px;
      width: 18px;
      margin-right: 10px;
    }
  }
}

.news-type-selector-cc {
  height: 45px;
  margin-top: 18px !important;

  @media screen and (max-width: 992px) {
    height: unset;
    margin-top: 0 !important;
  }

  .main-row {
    border-bottom: 2px solid #eaeaea;

    @media screen and (max-width: 992px) {
      border-bottom: 0;
    }
  }

  .type {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #4a4a4a;
    padding-bottom: 15px;

    @media screen and (max-width: 992px) {
      background: #eaeaea;
      border-radius: 10px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      color: #4a4a4a;
      padding: 12px;
      margin-bottom: 7px;
    }

    &:hover,
    &.selected {
      font-weight: 600;
      cursor: pointer;
      padding-bottom: 11px;
      border-bottom: 5px solid #f4c312;

      @media screen and (max-width: 992px) {
        color: white;
        background: #b8b8b8;
        border-radius: 10px;
        padding-bottom: 14px;
        border-bottom: 0;
      }
    }
  }
}

.prediction-match-row-cc {
  border-radius: 10px;
  min-height: 80px;

  .main-row {
    padding: 15px 20px;

    @media screen and (max-width: 992px) {
      padding: 20px 15px;
    }
  }

  &.odd {
    background: #f7f7f7;
  }

  .text-button-cc {
    a {
      width: 100%;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 16px;

      @media screen and (max-width: 992px) {
        font-weight: 500;
        text-transform: none;
        font-size: 15px;
        line-height: 18px;
        min-width: 100%;
      }
    }
  }

  .button-cc {
    width: 100%;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 16px;
    margin-right: 20px;

    @media screen and (max-width: 992px) {
      font-weight: 500;
      text-transform: none;
      font-size: 15px;
      line-height: 18px;
      margin-right: 0;
      min-width: 100%;
    }
  }

  .match-info-col {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4a4a4a;

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .team-info-col {
    align-items: center;

    .team-image-div {
      //height: 50px;
      //width: 50px;
      //background: #ffffff;
      //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      //border-radius: 50px;

      @media screen and (max-width: 992px) {
        height: 40px;
        width: 40px;
        margin-right: 15px;
        margin-left: 10px;
      }

      .team-image {
        max-height: 32px;
        max-width: 32px;
        height: auto;
        width: auto;

        @media screen and (max-width: 992px) {
          height: 22px;
          width: 22px;
        }
      }
    }

    .team-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #4a4a4a;

      @media screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    .vs-div {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #b8b8b8;
      margin-right: 20px;
      margin-left: 20px;

      @media screen and (max-width: 992px) {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 25px;
    }
  }

  .competition-col {
    @media screen and (max-width: 992px) {
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
    }

    .competition-div {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #b8b8b8;

      @media screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 19px;
      }

      .competition-image-div {
        height: 50px;
        width: 50px;
        background: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        margin-right: 20px;

        @media screen and (max-width: 992px) {
          margin-bottom: 10px;
          justify-content: center;
          margin-right: 0;
        }

        .competition-image {
          max-height: 34px;
          max-width: 34px;
          height: auto;
          width: auto;
        }
      }

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }
    }
  }

  &:hover {
    color: #ffffff;
    .competition-div {
      color: #ffffff;
    }
    .match-info-col {
      color: #ffffff;
    }
    background: #6e6e6e;
  }
}

.prediction-match-list-cc {
  .date-col {
    margin-top: 35px;
    margin-bottom: 20px;

    @media screen and (max-width: 992px) {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    .date {
      text-transform: capitalize;
      margin-left: 20px;
      margin-right: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #6e6e6e;

      @media screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .date-border {
      border-bottom: 2px solid #eaeaea;
      flex-grow: 1;
    }
  }
}

.pagination-cc {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 480px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dots {
    font-size: 16px;
    line-height: 19px;
    color: #6e6e6e;
    width: 40px;
    text-align: center;

    @media screen and (max-width: 992px) {
      width: 50px;
    }
  }

  .item {
    a {
      background: #f7f7f7;
      border-radius: 10px;
      height: 40px;
      width: 40px;
      align-items: center;
      justify-content: center;
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #6e6e6e;

      @media screen and (max-width: 992px) {
        height: 50px;
        width: 50px;
      }

      @media screen and (max-width: 480px) {
        height: 35px;
        width: 35px;
      }
    }

    &.selected {
      a {
        background: #6e6e6e;
        color: #ffffff;
        pointer-events: none;
      }
    }

    &:not(.disabled):hover {
      a {
        background: #6e6e6e;
        color: #ffffff;
      }
    }

    &.disabled {
      a {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}

.pagination-frontend-cc {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  .dots {
    font-size: 16px;
    line-height: 19px;
    color: #6e6e6e;
    width: 40px;
    text-align: center;

    @media screen and (max-width: 992px) {
      width: 50px;
    }
  }

  .item {
    background: #f7f7f7;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #6e6e6e;

    @media screen and (max-width: 992px) {
      height: 50px;
      width: 50px;
    }

    &.selected {
      background: #6e6e6e;
      color: #ffffff;
      pointer-events: none;
    }

    &:not(.disabled):hover {
      background: #6e6e6e;
      color: #ffffff;
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.oddspedia-iframe-cc {
  height: 535px;
  margin: -7px 2px -7px -15px;
}

.betreublic-iframe-cc {
  margin: 0 auto;
  align-content: center;
  text-align: center;
  @media screen and (max-width: 576px) {
    height: 420px !important;
  }
}

.betreublic-iframe {
  max-width: 1320px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.title-banner-cc {
  height: 100px;
  width: 100%;

  @media screen and (max-width: 992px) {
    height: 80px;
  }

  .container-div {
    border-radius: 10px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 992px) {
      justify-content: center;
      background: unset !important;
    }

    .icon {
      margin-left: 24px;
      margin-right: 28px;

      @media screen and (max-width: 992px) {
        //color: #F4C312;
        filter: brightness(0) saturate(100%) invert(88%) sepia(15%) saturate(6919%) hue-rotate(354deg) brightness(103%)
          contrast(91%);
        margin-left: 0;
        margin-right: 23px;
        max-width: 32px;
        height: auto;
      }
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
      color: #4a4a4a;

      @media screen and (max-width: 992px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.live-matches-cc {
  .swiper-button-next {
    right: 5px;
  }

  .swiper-button-prev {
    left: 5px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 60%;

    @media screen and (max-width: 992px) {
      top: 30%;
    }

    &:after {
      color: #6e6e6e;
      font-size: 22px;
      font-weight: 700;

      @media screen and (max-width: 992px) {
        color: #eaeaea;
        font-size: 19px;
      }
    }
  }
}

.live-match-card-cc {
  height: 250px;
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(#6e6e6e 45%, #eaeaea 0%);
  width: 100%;

  @media screen and (max-width: 992px) {
    padding: 20px;
    height: unset;
  }

  .top-button-row {
    margin-bottom: 8px;
  }

  .team-image-div {
    height: 80px;
    width: 80px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @media screen and (max-width: 992px) {
      height: 55px;
      width: 55px;
    }

    .team-image {
      max-width: 50px;
      max-height: 50px;
      height: auto;
      width: auto;
      cursor: pointer;

      @media screen and (max-width: 992px) {
        max-width: 33px;
        max-height: 33px;
      }
    }
  }

  .competition-row {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .date-row {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 12px;

    @media screen and (max-width: 1400px) {
      font-size: 11px;
    }
  }

  .result-row {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 7px;
  }

  .time-row {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4a4a4a;
  }

  .center-row {
    margin-right: 20px;
    margin-left: 20px;

    @media screen and (max-width: 992px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .progress-row {
    margin: 2px 20px 8px;

    @media screen and (max-width: 992px) {
      margin-right: 0;
      margin-left: 0;
    }

    .progressbar {
      background-color: #6e6e6e;
      border-radius: 10px;
    }

    .progressbar > div {
      background-color: #f4c312;
      height: 6px;
      border-radius: 10px;
    }
  }

  .team-row {
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 20px;

    @media screen and (max-width: 992px) {
      margin-right: 0;
      margin-left: 0;
    }

    .team-name {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #4a4a4a;
    }

    .vs {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #4a4a4a;
    }
  }

  .text-button-cc {
    margin-left: 50px;
    margin-right: 50px;

    @media screen and (max-width: 992px) {
      margin-right: 20px;
      margin-left: 20px;
    }

    a {
      height: unset;
      min-height: 30px;
      font-size: 15px;
      line-height: 18px;
      padding: 5px;

      @media screen and (max-width: 1400px) {
        font-size: 13px;
      }

      @media screen and (max-width: 992px) {
        min-height: 40px;
        padding: 10px;
      }
    }
  }
}

.news-preview-card-cc {
  .container-div {
    border-radius: 10px;
    overflow: hidden;
  }

  .image-col {
    height: 185px;

    @media screen and (max-width: 992px) {
      height: 200px;
    }

    .image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .text-col {
    padding: 17px 15px 20px !important;
    height: 185px;
    background-color: #ffffff;

    @media screen and (max-width: 992px) {
      height: 180px;
      background-color: #f7f7f7;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #4a4a4a;
      padding-left: 13px;
      padding-right: 13px;
    }

    .date {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #4a4a4a;
      padding-left: 13px;
      padding-right: 13px;
    }

    .text-row {
      margin-bottom: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      height: 55px;
      text-overflow: ellipsis;
    }

    .date-row {
      margin-bottom: 18px;
    }
  }
}

.news-preview-cc {
  background: #f7f7f7;
  border-radius: 10px;

  .container-div {
    padding: 20px;

    @media screen and (max-width: 992px) {
      padding: 0;
    }
  }

  .text-button-cc {
    width: 100%;
  }

  .button-row {
    margin-top: 20px;
    @media screen and (max-width: 992px) {
      .news-preview-card-cc {
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 992px) {
    background: unset;
    border-radius: 0;
  }

  @media screen and (max-width: 992px) {
    .news-preview-card-cc {
      margin-bottom: 20px;
    }
  }

  .news-preview-card-cc {
    .container-div {
      padding: 0;
    }
  }
}

.tabs-cc {
  display: flex;
  width: 100%;

  .container-div {
    width: 100%;
    display: flex;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .tabs {
    display: flex;
  }

  .option-select {
    display: none;

    @media screen and (max-width: 992px) {
      display: flex;
    }
  }

  .option-tab {
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 8px;

    &.active {
      background: #f7f7f7;
    }

    &:hover {
      cursor: pointer;
      background: #f7f7f7;
    }

    .image {
      border-radius: 5px;
      max-height: 25px;
      max-width: 17px;
      height: auto;
      width: auto;
      margin-right: 10px;
    }

    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #4a4a4a;
    }
  }
}

.select-cc {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4a4a4a;
  position: relative;

  .container-div {
    display: block;
    width: 100%;
    border-radius: unset !important;
    padding: 0 !important;
    margin: 0 !important;
    background: unset !important;
  }

  button {
    border: 0;
    width: 100%;
    padding-left: 15px;
    height: 45px;
    text-align: left;
    background: #eaeaea;
    border-radius: 10px;
  }

  button:hover {
    cursor: pointer;
  }

  button::after {
    position: absolute;
    right: 15px;
    top: 13px;
    content: url("https://s3.eu-central-1.wasabisys.com/calcio-com/ui/select-down-arrow.png");
  }

  button.expanded::after {
    content: url("https://s3.eu-central-1.wasabisys.com/calcio-com/ui/select-up-arrow.png");
  }

  .option-element-div {
    display: flex;
    gap: 10px;

    img {
      border-radius: 5px;
      max-height: 25px;
      max-width: 18px;
      height: auto;
      width: auto;
    }
  }

  ul.options {
    display: none;
    list-style: none;
  }

  ul.show {
    padding-top: 10px;
    top: 35px;
    display: block;
    z-index: 100;
    position: absolute;
    background: #eaeaea;
    width: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  ul.options li {
    padding-left: 15px;
    height: 45px;
    display: flex;
    align-items: center;
  }

  ul.options li:active,
  ul.options li:focus,
  ul.options li:hover,
  ul.options li[aria-selected="true"] {
    background: #f4c312;
    cursor: pointer;
    color: white;

    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.group-team-standings-cc {
  .container-div {
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
    background: #f7f7f7;
    border-radius: 0 0 10px 10px;

    @media screen and (max-width: 992px) {
      height: unset;
      overflow-y: unset;
      padding-right: 0;
      border-radius: 0;
      padding-bottom: 0;
      padding-left: 0;
      background: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: #b8b8b8;
      border-radius: 10px;
    }
  }

  thead {
    th {
      height: 50px;
      border: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #4a4a4a;
      text-align: center;

      &.team-image {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      height: 70px;
      border: none;

      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }

      &.odd {
        background: #ffffff;
        @media screen and (max-width: 992px) {
          background: #f7f7f7;
        }
      }
    }

    td {
      border: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #4a4a4a;
      min-width: 50px;
      text-align: center;

      @media screen and (max-width: 992px) {
        min-width: 20px;
        font-size: 18px;
        line-height: 22px;
      }

      &.rank {
        min-width: 20px;
      }

      &.team-image {
        text-align: center;
        width: 90px;
        cursor: pointer;

        @media screen and (max-width: 992px) {
          width: 76px;
        }

        .team-image-div {
          height: 50px;
          width: 50px;
          background: #ffffff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;

          @media screen and (max-width: 992px) {
            height: 40px;
            width: 40px;
          }

          .image {
            max-height: 32px;
            max-width: 32px;
            height: auto;
            width: auto;

            @media screen and (max-width: 992px) {
              height: 22px;
              width: 22px;
            }
          }
        }
      }

      &.team-name {
        text-align: left;
      }
    }
  }

  .pagination-frontend-cc {
    display: none;

    @media screen and (max-width: 992px) {
      display: flex;
      margin-bottom: 70px;
    }
  }
}

.turn-matches-cc {
  .container-div {
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    background: #f7f7f7;
    border-radius: 0 0 10px 10px;
    //height: 790px;
    overflow-y: auto;
    margin-bottom: 1rem;

    @media screen and (max-width: 992px) {
      height: unset;
      // padding-right: 0;
      // border-radius: 0;
      padding-bottom: 0;
      // padding-left: 0;
      // background: #ffffff;
      overflow: unset;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: #b8b8b8;
      border-radius: 10px;
    }
  }

  .header-row {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4a4a4a;
    height: 50px;

    [class*="col"] {
      display: flex;
      align-items: center;
    }
  }

  .match-row {
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 10px;

    @media screen and (max-width: 992px) {
      height: 90px;
      padding: 10px;
    }

    &.odd {
      background: #ffffff;
      @media screen and (max-width: 992px) {
        background: #f7f7f7;
      }
    }

    .date-col {
      @media screen and (max-width: 992px) {
        display: inline-flex;
        margin-bottom: 10px;
      }

      .date {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #4a4a4a;

        @media screen and (max-width: 992px) {
          margin-right: 15px;
        }
      }

      .date-time {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #4a4a4a;
      }
    }

    .teams-div {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      .team-image-div {
        height: 50px;
        width: 50px;
        background: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        @media screen and (max-width: 992px) {
          height: 40px;
          width: 40px;
        }

        .image {
          max-height: 32px;
          max-width: 32px;
          height: auto;
          width: auto;

          @media screen and (max-width: 992px) {
            height: 22px;
            width: 22px;
          }
        }
      }

      .vs {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #b8b8b8;
        margin-right: 8px;
        margin-left: 8px;

        @media screen and (max-width: 992px) {
          margin-right: 5px;
          margin-left: 5px;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .teams {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #4a4a4a;

      @media screen and (max-width: 992px) {
        font-size: 14px;
        line-height: 19px;
        text-align: left;
      }
    }
  }

  .pagination-frontend-cc {
    display: none;

    @media screen and (max-width: 992px) {
      display: flex;
      margin-bottom: 70px;
    }
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  margin-top: 50%;
  margin-bottom: 50%;
}

.ant-spin-dot.ant-spin-dot-spin > i {
  color: #f4c400;
  background-color: #f4c400;
}

.competition-group-team-standings-cc {
  * {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .select-cc {
    margin-bottom: 30px;
  }
}

.competition-tabs-cc {
  &.dark {
    .tabs {
      .option-tab {
        &.active {
          background: #eaeaea;
        }

        &:hover {
          background: #eaeaea;
        }
      }
    }
  }

  .option-tab {
    .label {
      min-width: 50px;
      margin-left: 5px;
    }
  }
}

.competition-group-turn-matches-cc {
  * {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .competition-tabs-cc {
    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
    }
  }

  .group-tabs-cc {
    .container-div {
      background: #eaeaea;
      border-top-right-radius: 10px;
      border-top-left-radius: 0;
      padding-top: 10px;
    }

    @media screen and (max-width: 992px) {
      margin-top: -20px;
      margin-bottom: 30px;
    }

    .tabs {
      .option-tab {
        &.active {
          background: #f7f7f7;
        }

        &:hover {
          background: #f7f7f7;
        }
      }
    }
  }
}

.pupi-banner-cc {
  img {
    height: 250px;
    width: auto;
    border-radius: 10px;

    @media screen and (max-width: 992px) {
      height: auto;
    }
  }
}

.competition-season-team-parameter-standings-cc {
  .container-div {
    padding: 20px 20px 30px;

    background: #f7f7f7;
    border-radius: 0 0 10px 10px;
    height: 1138px;
    overflow-y: auto;

    @media screen and (max-width: 992px) {
      height: unset;
      padding-right: 0;
      border-radius: 0;
      padding-bottom: 0;
      padding-left: 0;
      background: #ffffff;
      overflow: unset;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: #b8b8b8;
      border-radius: 10px;
    }
  }

  .team-parameter-row {
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 10px;

    @media screen and (max-width: 992px) {
      height: 50px;
    }

    &.odd {
      background: #ffffff;
      @media screen and (max-width: 992px) {
        background: #f7f7f7;
      }
    }

    .team-parameter-col {
      display: flex;
      align-items: center;

      padding: 10px 22px;

      @media screen and (max-width: 992px) {
        padding: 5px 20px;
      }

      .rank {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #4a4a4a;
        margin-right: 20px;

        @media screen and (max-width: 992px) {
          font-size: 16px;
          line-height: 19px;
          text-align: left;
        }
      }

      .team-image-div {
        height: 50px;
        width: 50px;
        background: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        @media screen and (max-width: 992px) {
          height: 40px;
          width: 40px;
        }

        .image {
          max-height: 32px;
          max-width: 32px;
          height: auto;
          width: auto;

          @media screen and (max-width: 992px) {
            height: 22px;
            width: 22px;
          }
        }
      }

      .team-name {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #4a4a4a;
        margin-left: 20px;

        @media screen and (max-width: 992px) {
          font-size: 16px;
          line-height: 19px;
          text-align: left;
        }
      }

      .value {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #4a4a4a;
        display: flex;
        flex: 1;
        justify-content: flex-end;

        @media screen and (max-width: 992px) {
          font-size: 16px;
          line-height: 19px;
          text-align: left;
        }
      }
    }
  }

  .pagination-frontend-cc {
    display: none;

    @media screen and (max-width: 992px) {
      display: flex;
      margin-bottom: 70px;
    }
  }
}

.competition-team-parameter-standings-cc {
  * {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .competition-tabs-cc {
    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
    }

    .tabs {
      .option-tab {
        padding-bottom: 20px;
      }
    }
  }

  .parameter-category-tabs {
    margin-top: -10px;

    @media screen and (max-width: 992px) {
      margin-top: 0;
    }

    .container-div {
      background: #f7f7f7;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding-top: 10px;
    }

    @media screen and (max-width: 992px) {
      margin-top: -20px;
      margin-bottom: 30px;
    }

    .tabs {
      .option-tab {
        padding-bottom: 20px;

        &.active {
          background: #eaeaea;
        }

        &:hover {
          background: #eaeaea;
        }
      }
    }
  }

  .parameter-tabs {
    margin-top: -10px;

    @media screen and (max-width: 992px) {
      margin-top: 0;
    }

    .container-div {
      background: #eaeaea;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding-top: 10px;
    }

    @media screen and (max-width: 992px) {
      margin-top: -20px;
      margin-bottom: 30px;
    }

    .tabs {
      .option-tab {
        padding-bottom: 10px;

        &.active {
          background: #f7f7f7;
        }

        &:hover {
          background: #f7f7f7;
        }
      }
    }
  }
}

.competition-season-player-standings-cc {
  .container-div {
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;

    background: #f7f7f7;
    border-radius: 0 0 10px 10px;

    @media screen and (max-width: 992px) {
      height: unset;
      padding-right: 0;
      border-radius: 0;
      padding-bottom: 0;
      padding-left: 0;
      background: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: #b8b8b8;
      border-radius: 10px;
    }
  }

  .header-row {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4a4a4a;
    height: 50px;
    padding: 10px 13px;

    @media screen and (max-width: 992px) {
      padding: 5px 10px;
    }

    [class*="col"] {
      display: flex;
      align-items: center;
    }
  }

  .player-parameter-row {
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 13px;

    @media screen and (max-width: 992px) {
      height: 60px;
      padding: 5px 10px;
    }

    &.odd {
      background: #ffffff;
      @media screen and (max-width: 992px) {
        background: #f7f7f7;
      }
    }

    .rank-col {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #4a4a4a;

      @media screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 19px;
        text-align: left;
      }
    }

    .player-info-col {
      display: flex;
      align-items: center;

      .player-image-div {
        @media screen and (max-width: 992px) {
          height: 40px;
          width: 40px;
        }

        .image {
          max-height: 44px;
          max-width: 44px;
          height: auto;
          width: auto;
          overflow: hidden;
          border: 3px solid #f4c312;
          border-radius: 50%;

          @media screen and (max-width: 992px) {
            height: 40px;
            width: 40px;
          }
        }
      }

      .player-name {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #4a4a4a;
        margin-left: 20px;

        @media screen and (max-width: 992px) {
          font-size: 16px;
          line-height: 19px;
          text-align: left;
          margin-left: 15px;
        }
      }
    }

    .role-team-col {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #4a4a4a;

      .team {
        font-weight: 600;
      }
    }

    .value-col {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #4a4a4a;
      text-align: right;

      @media screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 19px;
        text-align: right;
      }
    }
  }

  .pagination-frontend-cc {
    display: none;

    @media screen and (max-width: 992px) {
      display: flex;
      margin-bottom: 70px;
    }
  }
}

.competition-player-standings-cc {
  * {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .competition-tabs-cc {
    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
    }
  }
}

.telegram-banner-ui {
  .image {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }
}

.e2-betting-modal-cc {
  width: 1040px !important;
  max-width: 90%;

  .date-col {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #4a4a4a;
    margin-left: 20px;

    @media screen and (max-width: 992px) {
      text-align: center;
      margin-top: 5px;
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  .title-col {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-transform: uppercase;
    color: #4a4a4a;
    text-align: center;
  }

  .prediction-match-row-cc {
    margin-bottom: 10px;

    &:hover {
      background: #ffffff;

      .match-info-col {
        color: #4a4a4a;
      }

      .competition-div {
        color: #b8b8b8;
      }
    }
  }

  .ant-modal-content {
    background: #ffffff;
    border-radius: 20px;

    .ant-modal-body {
      padding: 20px;

      [role="img"] {
        display: none;
      }
    }
  }

  .ant-modal-confirm-btns {
    margin: 0 !important;
  }
}

.bet-standing-widget-cc {
  .container-div {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 790px;
    overflow-y: auto;
    background: #f7f7f7;
    border-radius: 10px;

    @media screen and (max-width: 992px) {
      height: unset;
      overflow-y: unset;
      padding-right: 0;
      border-radius: 0;
      padding-bottom: 0;
      padding-left: 0;
      background: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: #b8b8b8;
      border-radius: 10px;
    }
  }

  thead {
    th {
      height: 50px;
      border: none;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      color: #4a4a4a;
      text-align: center;

      @media screen and (max-width: 480px) {
        font-size: 14px;
      }

      &.team-image {
        text-align: center;
      }
    }

    .frequency-label {
      @media screen and (max-width: 480px) {
        font-size: 0;
      }
    }
    .frequency-label:before {
      @media screen and (max-width: 480px) {
        content: "%";
        font-size: 14px;
        line-height: 34px;
      }
    }

    .team-autoimage {
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }

  tbody {
    tr {
      height: 36px;
      border: none;

      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }

      &.odd {
        background: #ffffff;
        @media screen and (max-width: 992px) {
          background: #f7f7f7;
        }
      }
    }

    td {
      border: none;
      font-weight: 600;
      font-size: 13px;
      line-height: 17px;
      color: #4a4a4a;
      min-width: 30px;
      text-align: center;

      @media screen and (max-width: 992px) {
        min-width: 20px;
        font-size: 11px;
        line-height: 22px;
      }

      &.rank {
        min-width: 20px;
      }

      &.team-image {
        text-align: center;
        width: 90px;

        @media screen and (max-width: 992px) {
          width: 76px;
        }
        @media screen and (max-width: 480px) {
          display: none;
        }

        .team-image-div {
          height: 30px;
          width: 30px;
          background: #ffffff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;

          @media screen and (max-width: 992px) {
            height: 30px;
            width: 30px;
          }

          .image {
            max-height: 24px;
            max-width: 24px;
            height: auto;
            width: auto;

            @media screen and (max-width: 992px) {
              height: 22px;
              width: 22px;
            }
          }
        }
      }

      &.team-name {
        text-align: left;
      }
    }
  }

  .pagination-frontend-cc {
    display: none;

    @media screen and (max-width: 992px) {
      display: flex;
      margin-bottom: 70px;
    }
  }
}
.hs-standing {
  .ant-tabs-content-holder {
    border-left: 0 !important;
    margin-left: 0 !important;
  }
  .ant-tabs-tab {
    border-radius: 10px !important;
    font-size: 13px;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-right-color: #f0f0f0;
  }
  .ant-radio-button-wrapper {
    font-size: 13px;
    @media screen and (max-width: 480px) {
      font-size: 11px;
    }
  }
  .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

// new campionato serie-a page

.competition-header {
  .competition-tabs-cc {
    @media screen and (max-width: 992px) {
      margin-bottom: 15px;
    }
    .tabs {
      width: 100%;
      .option-tab {
        flex-basis: 15%;
      }
    }
  }
}

// .(.campionato-new-container) {
//   border-top-left-radius: 0px;
// }

.new-campionato-banner {
  // padding: 10px;
  // border-radius: 10px;
  // background-color: #f4c312;
  // margin-bottom: 10px;
  .module-profile.info-competition.new-module-profile {
    dd {
      width: 40%;
      font-weight: 600;
    }
  }
}
.campionato-new-container {
  background: transparent;
  border-radius: 10px;
  border-top-left-radius: 0;
  @media screen and (max-width: 992px) {
    margin-bottom: 10px;
    border-radius: 10px;
  }
  @media screen and (max-width: 480px) {
    border-top-left-radius: 10px;
  }

  .new-select-squadre {
    background: #f7f7f7;
    .module-select {
      background: #eaeaea;
      .navigation.season-navigation,
      .navigation.round-navigation {
        border: none !important;
      }
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 10px !important;
  }
  .hs-block {
    margin-top: 0;
    .new-team-table {
      .new-teams {
        background: #ffffff;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        .match.match-1 {
          margin: 0 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 5px;
          .team-name {
            font-weight: 500;
            flex-basis: 81%;
          }
          .team-image-div {
            height: 60px;
            width: 60px;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
              span {
                display: flex !important;
                span {
                  display: flex;
                  img {
                    max-height: 40px;
                    max-width: 40px;
                  }
                }
              }
            }
          }
        }
        .match.match-1.odd {
          background: #f7f7f7;
          border-radius: 10px;
        }
        .match.match-1.even {
          margin-top: 0;
          margin-bottom: 0;
          background: #ffffff;
        }
      }
    }
    .new-module-teams {
      max-height: 982px;
      overflow-y: auto;
      border-radius: 10px;
      margin-top: 10px;
      @media screen and (max-width: 480px) {
        max-height: inherit;
      }
      .new-module-teams-header {
        background: white;
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        p {
          font-size: 14px;
          padding: 10px 10px 0 10px;
          font-weight: 500;
        }
        .new-squadra-name {
          flex-basis: 75.5%;
          margin-left: 14px;
        }
      }
    }
    .new-module-teams::-webkit-scrollbar {
      width: 10px !important;
    }
    .new-module-teams::-webkit-scrollbar-thumb {
      width: 10px;
      background: #b8b8b8;
      border-radius: 10px;
    }
  }
  .title-banner-cc.ultime-partite {
    background: #f7f7f7;
  }
  .match.finished.odd {
    background: #f7f7f7;
  }
}

// matchgameplan component
.paddingFive:has(.new-calendario-risultati, .new-classifica-tab, .new-statistiche-giocatori, .new-statistiche-squadre, .new-squadre-container, .albo-doro-container) {
  margin: 0 !important;
}
.hs-block.hs-teams,
.new-calendario-risultati {
  background: transparent;
  .hs-block.hs-gameplan {
    .new-module-last-matches {
      display: flex;
      align-items: center;
      padding: 10px;
      @media screen and (max-width: 480px) {
        p {
          flex-basis: 50% !important;
        }
        p:last-child {
          text-align: end;
        }
        p:nth-child(2) {
          display: none;
        }
      }
      p {
        font-size: 14px;
        padding: 10px 10px 0 10px;
        font-weight: 500;
        flex-basis: 30%;
      }
    }
    .empty-match-date {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      flex-basis: 136px;
      margin: 20px;
      span {
        flex-basis: 25%;
        @media screen and (max-width: 480px) {
          flex-basis: 100%;
        }
      }
      .date-split-line {
        flex-basis: 100%;
        height: 2px;
        background: #eaeaea;
        align-self: center;
      }
    }
    .empty-match-date.none {
      display: none;
    }
    .module-gameplan {
      background: #fff;
      margin-top: 10px;
      border-radius: 10px;
      @media screen and (max-width: 480px) {
        overflow-x: hidden !important;
      }
      .match.finished.odd {
        border-radius: 10px;
      }
      .match.finished {
        margin: 0 10px;
        padding: 10px;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        .match-result {
          flex-basis: 20%;
          text-align: start;
          @media screen and (max-width: 480px) {
            text-align: center;
          }
        }
        .team-shortname {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        .team-name-home {
          text-align: center;
          display: flex;
          align-items: center;
          @media screen and (max-width: 480px) {
            display: none;
          }
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
          p {
            width: 20px;
            text-align: center;
            margin: 0;
            align-items: center;
          }
        }
        .new-versus {
          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #b8b8b8;
            text-align: center;
            margin-bottom: 0;
          }
        }
        .team-image {
          width: 100px;
          padding: 0 20px;
          @media screen and (max-width: 480px) {
            width: 80px;
            padding: 0 10px;
          }
          .team-image-div {
            height: 60px;
            width: 60px;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
              span {
                display: flex !important;
                span {
                  display: flex;
                  img {
                    max-height: 40px;
                    max-width: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .module-gameplan::-webkit-scrollbar {
      width: 10px !important;
    }
    .module-gameplan::-webkit-scrollbar-thumb {
      width: 10px;
      background: #b8b8b8;
      border-radius: 10px;
    }
  }
}
.new-calendario-risultati {
  .team-name-home {
    text-align: center;
    display: flex;
    align-items: center;
    margin-left: 50px;
    p {
      width: 20px;
      text-align: center;
      margin: 0;
      align-items: center;
    }
  }
}

// pagina calendario e risultati

.new-calendario-risultati,
.new-classifica-tab {
  border-radius: 10px;
  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
  .hs-block.hs-gameplan {
    background: #ffffff;
    border-radius: 10px;
  }
  .new-hs-select {
    padding-bottom: 10px;
    grid-template-columns: 1fr 1fr;
    // grid-template-columns: 1fr 1fr 1fr ;
    @media screen and (max-width: 992px) {
      flex-direction: column;
      display: flex;
    }
    .module-select {
      background: #eaeaea;
      .navigation.season-navigation,
      .navigation.round-navigation {
        border: none !important;
      }
    }
  }
  .new-module-last-matches {
    display: none !important;
  }
  .module-gameplan {
    padding-top: 5px;
  }
}
.banner-video-footer {
  padding: 0 5px;
}
.tabs-competition-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #4a4a4a;
}
.banner-video-footer-generali {
  margin-top: 10px !important;
}

// pagina campionato tab classifica

.new-classifica-tab {
  .standing.odd,
  .even {
    height: 80px;
  }
  .team-image-div {
    height: 50px !important;
    width: 50px !important;
    a {
      display: flex;
      span {
        display: flex;
        width: 35px !important;
        height: 35px !important;
      }
    }
  }
}

// pagina campionato tab squadre

.new-squadre-tab {
  margin-top: 10px;
  border-radius: 10px;
}

// pagina campionato tab albo d'oro

.albo-doro-container {
  border-radius: 10px;
  border-top-right-radius: 0;
  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
  .team-image-div {
    height: 50px;
    width: 50px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      span {
        display: flex !important;
        span {
          display: flex;
          img {
            max-height: 40px;
            max-width: 40px;
          }
        }
      }
    }
  }
  .team-image-div.none {
    display: none;
  }
  .hs-block.hs-teams {
    margin-top: 10px;
    .module-teams {
      border-radius: 10px;
      padding: 10px;
      background: #ffffff;
      .new-team-table {
        background: #ffffff;
        border-radius: 10px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        .new-matches-list {
          display: flex;
          border-radius: 10px;
          height: 60px;
          align-items: center;
          .new-competition-season-name {
            flex-basis: 20%;
            margin-left: 10px;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .new-matches-list.odd {
          background: #f7f7f7;
        }
      }
      .albo-doro-table-header {
        background: white;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 0;
        .albo-doro-year {
          margin-left: 10px;
          flex-basis: 24%;
        }
      }
    }
  }
  .hs-block-header {
    display: none;
  }
}

// new pagina statistiche giocatori

.new-statistiche-giocatori {
  .statistiche-giocatori-container {
    border-radius: 10px;
    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
    .stat-giocatori-wrapper {
      background: #ffffff;
      border-radius: 10px;
      .module-statistics.statistics {
        padding: 10px;
        @media screen and (max-width: 480px) {
          padding: 0;
        }
        .stat-giocatori-table-header {
          display: flex;
          font-size: 14px;
          font-weight: 500;
          padding: 10px 0;
          p {
            text-align: center;
            flex-basis: 20%;
            font-size: 1.6rem;
            font-weight: 600;
          }
          .sgt-number {
            flex-basis: 5%;
            @media screen and (max-width: 480px) {
              flex-basis: 10%;
            }
          }
          .sgt-name {
            flex-basis: 22%;
            @media screen and (max-width: 480px) {
              flex-basis: 59%;
            }
          }
          .sgt-role {
            flex-basis: 18%;
            @media screen and (max-width: 480px) {
              display: none;
            }
          }
          .sgt-team {
            flex-basis: 12%;
            @media screen and (max-width: 480px) {
              display: none;
            }
          }
          .sgt-gol {
            flex-basis: 25%;
            @media screen and (max-width: 480px) {
              display: none;
            }
          }
        }
        .entry {
          border-radius: 10px;
          height: 80px;
          display: flex !important;
          flex-direction: row;
          position: static;
          @media screen and (max-width: 480px) {
            margin-top: 0;
          }
          .person_stats-rank {
            flex-basis: 5%;
          }
          .person-image.person-image-home.person-image- {
            position: static;
            margin: 0 10px;
            @media screen and (max-width: 480px) {
              margin: 0;
            }

            a {
              @media screen and (max-width: 480px) {
                width: 45px;
                height: 45px;
              }
            }
          }
          .role-name.role-name- {
            flex-basis: 14%;
            text-align: start;
            font-size: 16px;
            @media screen and (max-width: 480px) {
              display: none;
            }
          }
          .team-name.team-name- {
            flex-basis: 20%;
            text-align: start;
            font-size: 1.6rem;
            margin-left: 5px;
            @media screen and (max-width: 480px) {
              display: none;
            }
          }
          .person_stats-playing.person_stats-playing-list,
          .person_stats-score.person_stats-score- {
            flex-basis: 11%;
            background-color: transparent;
            text-align: start;
          }
          .person_stats-score_penalty.person_stats-score_penalty-,
          .person_stats-score.person_stats-score_per_playing,
          .person-name.person-name-list {
            flex-basis: 20%;
            margin-left: 25px;
          }
          .person-name.person-name-list {
            @media screen and (max-width: 480px) {
              font-size: 14px;
              margin-left: 10px;
              flex-basis: 55%;
            }
          }
          .role-name.role-name-,
          .team-name.team-name-,
          .team-name.team-name-,
          .person_stats-playing.person_stats-playing-list,
          .person_stats-score.person_stats-score-,
          .person_stats-score_penalty.person_stats-score_penalty-,
          .person_stats-score.person_stats-score_per_playing,
          .person-name.person-name-list,
          .person_stats-rank {
            position: static;
            align-self: center;
          }
          .person_stats-rank {
            @media screen and (max-width: 480px) {
              margin-left: 5px;
            }
          }
        }
        .entry.even {
          background: #ffffff;
        }
        .entry.odd {
          background: #f7f7f7;
        }
        .entry.odd::after,
        .entry.middle::after {
          display: none !important;
        }
      }
    }
  }
  .title-banner-cc {
    margin-bottom: 20px;
  }
  .new-hs-select {
    width: 50%;
    display: flex;
    .module-select {
      margin-top: 20px;
      background: #eaeaea;
      .navigation.season-navigation,
      .navigation.round-navigation {
        border: none !important;
      }
    }
  }
  .new-select-container {
    display: flex;
    align-items: center;
    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
    .new-hs-select {
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: 0;
      }
    }
    .new-select {
      width: 50%;
      margin: 0 10px;
      margin-top: 10px;
      border: none;
      background: #eaeaea;
      height: 30px;
      padding-left: 5px;
      padding-right: 5px;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }
}

// new tab statistiche squadre

.statistiche-squadre-container {
  border-radius: 10px;
  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
  .stat-squadre-wrapper {
    padding-bottom: 5px;
    background: #ffffff;
    margin-top: 10px;
    border-radius: 10px;
    .module-select.new-select-container {
      @media screen and (max-width: 992px) {
        flex-direction: column;
      }
      .hs-select.new-hs-select,
      .new-select {
        padding-top: 0;
        @media screen and (max-width: 992px) {
          width: 100%;
          margin-bottom: 0;
        }
      }
    }
    .module-statistics.statistics {
      padding: 10px;
      @media screen and (max-width: 480px) {
        padding: 0;
      }
      .stat-giocatori-table-header {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 0;
        p {
          text-align: center;
          flex-basis: 20%;
          font-size: 1.6rem;
          font-weight: 600;
        }
        .sgt-number {
          flex-basis: 5%;
          @media screen and (max-width: 480px) {
            flex-basis: 10%;
          }
        }
        .sgt-name {
          flex-basis: 21%;
          @media screen and (max-width: 480px) {
            flex-basis: 48%;
            text-align: end;
          }
        }
        .sgt-role {
          flex-basis: 18%;
        }
        .sgt-team {
          flex-basis: 12%;
        }
        .sgt-gol {
          flex-basis: 25%;
        }
      }
      .entry {
        border-radius: 10px;
        height: 80px;
        display: flex !important;
        flex-direction: row;
        position: static;
        align-items: center;
        .person_stats-rank {
          flex-basis: 5%;
        }
        .person-image.person-image-home.person-image- {
          position: static;
          margin: 0 10px;
          height: 60px;
          width: 60px;
          background: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .role-name.role-name- {
          flex-basis: 14%;
          text-align: start;
        }
        .team-name.team-name- {
          flex-basis: 20%;
          text-align: start;
          font-size: 1.6rem;
          margin-left: 5px;
        }
        .person_stats-playing.person_stats-playing-list,
        .person_stats-score.person_stats-score- {
          flex-basis: 20%;
          background-color: transparent;
          text-align: center;
        }
        .person_stats-score_penalty.person_stats-score_penalty-,
        .person_stats-score.person_stats-score_per_playing,
        .person-name.person-name-list {
          flex-basis: 30%;
          margin-left: 24px;
        }
        .role-name.role-name-,
        .team-name.team-name-,
        .team-name.team-name-,
        .person_stats-playing.person_stats-playing-list,
        .person_stats-score.person_stats-score-,
        .person_stats-score_penalty.person_stats-score_penalty-,
        .person_stats-score.person_stats-score_per_playing,
        .person-name.person-name-list,
        .person_stats-rank {
          position: static;
          align-self: center;
        }
        .person_stats-rank {
          @media screen and (max-width: 480px) {
            margin-left: 5px;
          }
        }
      }
      .entry.even {
        background: #ffffff;
      }
      .entry.odd {
        background: #f7f7f7;
      }
      .entry.odd::after,
      .entry.middle::after {
        display: none !important;
      }
    }
  }
  .new-hs-select {
    width: 50%;
    display: flex;
    .module-select {
      margin-top: 20px;
      background: #eaeaea;
      .navigation.season-navigation,
      .navigation.round-navigation {
        border: none !important;
      }
    }
  }
  .new-select-container {
    display: flex;
    align-items: center;
    .new-select {
      width: 50%;
      margin: 0 10px;
      margin-top: 10px;
      border: none;
      background: #eaeaea;
      height: 30px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.new-squadre-container {
  border-radius: 10px;
  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
  .new-squadra-name {
    flex-basis: 79.5% !important;
  }
}
.hs-block.hs-profile.hs-new-profile {
  margin-top: 0;
  .new-main-competition-header {
    width: 100%;
    height: 250px;
    padding: 10px;
    background: #f4c312;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    background-image: linear-gradient(90deg, #f4c312 33.47%, rgba(244, 195, 18, 0) 100%),
      url("../src/app/assets/images/banner-background.png");
    @media screen and (max-width: 480px) {
      margin-right: 10px;
      padding: 0;
    }
    .inside-banner {
      padding: 5px;
      background: #f7f7f7;
      border-radius: 10px;
      display: flex;
      .competition-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .competition-logo {
          position: relative !important;
        }
      }
      .new-module-profile-container {
        display: flex;
        align-items: center;
        .new-module-profile {
          display: table;
          width: 100%;
          margin: auto;
          .new-module-profile-pair {
            display: table-row;
            justify-content: center;
            .new-module-profile-name-value,
            .new-module-profile-state-value,
            .new-module-profile-team-amount-value,
            .new-module-profile-winner-value {
              font-size: 15px;
              font-weight: 600;
            }
          }
          .new-module-profile-pair > div {
            display: table-cell;
            padding: 5px;
            vertical-align: middle;
            font-size: 15px;
          }
        }
      }
    }
    .banner-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      flex-direction: column;
      flex-basis: 30%;
      left: -60px;
      @media screen and (max-width: 992px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .banner-ball {
        position: absolute;
        top: 70%;
        transform: scale(0.8);
        display: flex;
        flex-direction: column;
        width: 20%;
      }
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }
}
.hs-page-header.hs-page-header-competition {
  display: flex !important;
}

.mobile-container-title {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
    margin-bottom: 5px;
    p {
      margin-top: 20px;
      margin-left: 14px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
.new-tooltip-container {
  background-color: #000000 !important;
  color: white !important;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.bookmaker-container-mobile {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
    margin-top: 10px !important;

    .bookmaker-bonus-list-cc.mobile .main-row {
      width: 100%;
    }
    .bookmaker-bonus-row-cc.mobile.bookmaker-bonus-row-horizontal.col {
      display: flex;
      justify-content: center;
    }
  }
}

.switch-toggle-container {
  display: flex;
  .new-switch-toggle {
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    input:checked + .slider {
      background-color: #2196f3;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.seo-list-ul {
  list-style-type: disc !important;
  margin-left: 20px !important;
  padding: 5px !important;
  li {
    margin-bottom: 5px;
    list-style-type: disc;
  }
}
.seo-a-tag {
  text-decoration: underline;
}
.title-between-seo {
  margin: 20px 0 !important;
}

// new bookmaker style

.bookmaker-bonus-row {
  .new-bookmaker-bonus-row {
    justify-content: center;
    display: flex;
    align-items: center;

    .main-row {
      width: 100%;
      .inforow {
        cursor: pointer;
        @media screen and (max-width: 992px) {
          justify-content: center;
        }
      }
    }
  }
}
.bookmaker-bonus-row.first {
  .new-bookmaker-bonus-row {
    position: relative;
    border: 2px solid #fcde20;
    background-size: 100% 2px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
  }
  .new-bookmaker-bonus-row::before {
    background: no-repeat center top
      url(https://www.sitiscommesse.com/wp-content/themes/generel/new-homepage-assets/images/ico-gold-medal.png) !important;
    content: "";
    position: absolute;
    width: 32px;
    height: 50px;
    top: -1px;
    left: 7px;
    border-radius: 6px;
    font-size: 11px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    background: #171a2e;
    z-index: 1;
  }
}
.bookmaker-bonus-row.second {
  .new-bookmaker-bonus-row {
    position: relative;

    border: 2px solid #b2d1d1;
    background-size: 100% 2px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
  }
  .new-bookmaker-bonus-row::before {
    background: no-repeat center top
      url(https://www.sitiscommesse.com/wp-content/themes/generel/new-homepage-assets/images/ico-silver-medal.png) !important;
    content: "";
    position: absolute;
    width: 31px;
    height: 50px;
    top: -1px;
    left: 7px;
    border-radius: 6px;
    font-size: 11px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    background: #171a2e;
    z-index: 1;
  }
}
.bookmaker-bonus-row.third {
  .new-bookmaker-bonus-row {
    position: relative;

    border: 2px solid #dda877;
    background-size: 100% 2px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
  }
  .new-bookmaker-bonus-row::before {
    background: no-repeat center top
      url(https://www.sitiscommesse.com/wp-content/themes/generel/new-homepage-assets/images/ico-bronze-medal.png) !important;
    content: "";
    position: absolute;
    width: 31px;
    height: 50px;
    top: -2px;
    left: 7px;
    border-radius: 6px;
    font-size: 11px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    background: #171a2e;
    z-index: 1;
  }
}

// new player/team stats page for the bookmaker widget

.new-bookmaker-slider-container {
  @media only screen and (min-width: 400px) and (max-width: 768px) {
    display: none !important;
  }
  .hs-block-header {
    margin-top: 20px;
  }
}
.bookmaker-bonus-list-slider {
  @media screen and (max-width: 480px) {
    display: none;
  }
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #eaeaea;
  border-radius: 10px;
  .swiper {
    padding: 10px 35px;
    margin: 0 5px;
    .swiper-button-prev {
      left: 0px !important;
    }
    .swiper-button-next {
      right: 0px !important;
    }
    .swiper-wrapper {
      background-color: transparent !important;

      .swiper-slide {
        border-radius: 10px;
        background-color: #f7f7f7;
        @media only screen and (min-width: 768px) and (max-width: 992px) {
          width: 37% !important;
          margin-right: 35px !important;
        }
        @media only screen and (min-width: 992px) and (max-width: 1200px) {
          width: 28% !important;
        }
        .bookmakersliderow-container {
          padding: 5px;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 275px;
          .bookmaker-slide-image {
            margin: 10px 0;
            img {
              border-radius: 10px;
              padding: 0;
            }
          }
          .slider-star-review,
          .slider-description {
            font-size: 14px;
            margin: 10px 0;
            font-weight: 500;
            height: 100%;
            span {
              font-weight: 700;
            }
          }
          .slider-cta {
            margin: 10px 0;
            @media only screen and (min-width: 768px) and (max-width: 992px) {
              width: 80% !important;
            }
          }
        }
      }
    }
  }
}
.bookmaker-bonus-list-slider.whiteBg {
  background-color: white;
}
.bookmaker-slider {
  margin-top: 0 !important;
  @media screen and (max-width: 480px) {
    display: none;
  }
}

// new style for pagina scommesse

.pagina-seo-scommesse {
  .new-bookmaker-slider-container {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .scommesse-img-guide-link {
    img {
      cursor: pointer;
    }
  }
  .scommesse-accordion {
    margin-top: 20px !important;
  }
}
.scommesse-table {
  td {
    padding: 1.5rem 0.5rem !important;
    text-align: start !important;
    border-bottom-width: 0 !important;
  }
  .table-title {
    text-align: center !important;
  }
  .odd {
    background-color: #eaeaea;
  }
}
.scommesse-tab-container {
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin: 0 !important;
  .tab-wrapper {
    border-radius: 10px 10px 10px 10px !important;
    background-color: white;
    padding: 0 !important;
    .ant-tabs-content-holder {
      padding: 15px 15px !important;
      border-radius: 10px !important;
      margin-bottom: 30px;
      margin-top: 20px;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      display: none !important;
    }
    .ant-tabs-nav {
      margin-bottom: 0 !important;
      .ant-tabs-nav::before {
        display: none !important;
      }
      .ant-tabs-nav-wrap {
        background-color: #f7f7f7;
        .ant-tabs-nav-list {
          flex: 5;
          justify-content: space-between;
        }
        .ant-tabs-tab.ant-tabs-tab-active,
        .ant-tabs-tab {
          border-radius: 10px 10px 0 0 !important;
          border: none !important;
        }
        .ant-tabs-tab.ant-tabs-tab-active {
          background-color: white;
          .ant-tabs-tab-btn {
            color: rgba(0, 0, 0, 0.85) !important;
            font-weight: 500;
          }
        }
        .ant-tabs-tab {
          background-color: #eaeaea;
          padding: 8px 60px !important;
          .ant-tabs-tab-btn {
            color: #b8b8b8;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.bookmaker-mobile-scommesse {
  @media screen and (min-width: 576px) {
    display: none !important;
  }
}

// bookmaker widget style
.bookmaker-banner-widget {
  background-color: #e7e7e7;
  border-radius: 5px;
  padding: 8px 5px 5px 5px;
  margin: 0 auto !important;
  max-height: 100%;
  height: 100%;
}
.new-bookmaker-bonus-widget-container {
  align-items: center;

  @media screen and (max-width: 992px) {
    display: none;
  }
  .bookmaker-bonus-widget-container {
    display: flex;
    padding: 5px;
    flex-direction: row;
    font-size: 13px;
    align-items: center;
    .main-bookmaker-row {
      align-items: center;
    }
    .bonus {
      font-weight: 600;
      padding: 0 !important;
    }
    .image-col-container img {
      border-radius: 10px;
    }
    .button-col-container {
      .tosite-button {
        margin-right: 0 !important;
      }
      .text-button-bonus-cc a {
        height: 35px;
        font-size: 15px;
      }
    }
  }
}
.new-bookmaker-bonus-widget-container:last-child {
  .bonus-hr {
    display: none !important;
  }
}

// new bookmaker bonus specific page

.selectedBookmakerPageContainer {
  display: flex;
  .bookmakerSelectorContainer {
    display: none;
    @media screen and (max-width: 992px) {
      visibility: visible;
      margin: 1rem 0;
      .ant-select {
        width: 100%;
        .ant-select-selector {
          border-radius: 10px !important;
          background-color: #eaeaea;
          height: 60px !important;
          padding: 15px 11px !important;
          .ant-select-selection-item {
            font-weight: 600;
          }
        }
      }
    }
  }
  .selectedBookmakerSidewidget {
    background: #eaeaea;
    padding: 20px;
    border-radius: 10px;
    height: 100%;
    max-height: 50em;
    position: sticky;
    top: 0;
    @media screen and (max-width: 992px) {
      display: none;
    }
    .headerBookmakerInfo {
      padding-bottom: 30px;
      border-bottom: 2px solid darkgray;
      .bookmakerImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        img {
          max-height: 100%;
          max-width: 100%;
          vertical-align: middle;
          border-radius: 10px;
        }
      }
      .bookmakerReviewContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .numberAndStarReviewContainer {
          margin: 10px 0 0 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .numberReview {
            font-size: 20px;
            font-weight: 600;
          }
        }
        .bonusDescriptionDiv {
          font-size: 16px;
          font-weight: 500;
          margin: 10px 0;
          flex-direction: column;
          display: flex;
          align-items: center;
          span {
            font-weight: 700;
          }
        }
        .tosite-button {
          width: 65%;
        }
      }
    }
    .bodyBookmakerInfo {
      padding-bottom: 10px;
      border-bottom: 2px solid darkgray;
    }
    .bodyBookmakerInfo,
    .paymentMethodsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .licenseContainer {
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
        .licenseImageContainer {
          img {
            width: 35%;
          }
        }
      }
      .paymentMethodsContainer {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        .paymentMethodsTitle {
          font-size: 16px;
          text-align: center;
        }
        .paymentMethodsImages {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          flex-grow: 1 1 0;
          .paymentMethodImageContainer {
            width: 25%;
            img {
              height: auto;
            }
          }
        }
      }
      .clientSupportContainer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        p,
        span {
          font-size: 16px;
          text-align: center;
        }
        span {
          font-weight: 600;
        }
      }
    }
    .footerBookmakerInfo {
      margin-top: 20px;
      .bookmakerWebsiteContainer {
        text-align: center;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .selectedBookmakerSideReview {
    .mainReviewContainer {
      margin-bottom: 20px;
      .mainReviewTitle {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .mainReview {
        font-size: 16px;
      }
    }
    .proConsContainer {
      padding: 0 5px;
      .proConsTitleContainer {
        background: #eaeaea;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .proConsTitle {
          font-size: 24px;
        }
      }
      .proConsTableContainer {
        padding: 0;
        border-left: 1px solid darkgray;
        border-right: 1px solid darkgray;
        border-bottom: 1px solid darkgray;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .proContainer {
          padding: 20px 10px 10px 10px;
          display: flex;
          flex-direction: column;
          .pro {
            display: flex;
            align-items: center;
            .nextImgContainer {
              display: flex;
              align-items: center;
            }
            .proText {
              margin-left: 10px;
              font-size: 16px;
            }
          }
        }
        .conContainer {
          padding: 20px 10px 10px 10px;
          border-top: 1px solid darkgray;
          .con {
            display: flex;
            align-items: center;
            .nextImgContainer {
              display: flex;
              align-items: center;
            }
            .conText {
              font-size: 16px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .selectedBookmakerLongReviewContainer {
    margin-top: 30px;
    .selectedBookmakerLongReviewText {
      p {
        font-size: 16px;
        span {
          a {
            color: #3c3c3b !important;
            text-decoration: none;
          }
        }
      }
      ol {
        font-size: 16px;
        li {
          list-style: disc;
        }
      }
      h2 {
        span {
          font-size: 24px;
        }
      }
      h3 {
        span {
          font-size: 24px;
        }
      }
      ul {
        margin: 20px 10px;
        font-size: 16px;
        li {
          margin: 10px 0 10px 10px;
          list-style-type: disc;
        }
      }
    }
  }
}

// new table for bookmaker review page

.paymentsTableContainer {
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  .paymentsTableContainerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 480px) {
      overflow-x: scroll;
    }
    .table-title {
      display: flex;
      background-color: #eaeaea;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .table-cell {
        display: flex;
        justify-content: center;
        flex: 1;
        padding: 15px 15px;
        text-align: center;
        border: 1px solid black;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
      .table-cell:first-child {
        border-left: none;
      }
      .table-cell:last-child {
        border-right: none;
      }
    }
    .table-content {
      display: flex;
      align-items: center;
      .table-content-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 15px 15px;
        border: 1px solid black;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        height: 80px;
        img {
          max-height: 100%;
          object-fit: contain;
        }
      }
      .table-content-cell:last-child {
        border-right: 1px solid black;
      }
    }
    .table-content:last-child {
      .table-content-cell:first-child {
        border-bottom-left-radius: 10px;
      }
      .table-content-cell:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }
  .antd-table {
    .ant-table-content::-webkit-scrollbar-thumb {
      background-color: #adb5bd;
    }
    .ant-table-content::-webkit-scrollbar {
      background-color: transparent !important;
      height: 10px !important;
    }
    .ant-table-content::-webkit-scrollbar-track {
      background-color: transparent !important;
      box-shadow: none !important;
    }
    .ant-table-cell {
      text-align: center;
    }
  }
}
// new textarea
.text-section {
  a {
    color: #f4c400 !important;
  }
}

// new quote-pronostici page style

.prediction-match-row-container {
  margin-top: 50px !important;
  .date-col {
    margin-bottom: 10px;
    margin-top: 0 !important;
  }
}

.prediction-match-row-cc {
  margin-top: 30px !important;
  .competition-image-div {
    height: 45px;
    width: 45px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    margin-right: 20px;

    @media screen and (max-width: 992px) {
      margin-bottom: 10px;
      justify-content: center;
      margin-right: 0;
    }

    .competition-image {
      max-height: 34px;
      max-width: 34px;
      height: auto;
      width: auto;
    }
  }
  .team-image-div {
    //height: 45px;
    //width: 45px;
    //background: #ffffff;
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    //border-radius: 50px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //margin: auto;
    //
    //@media screen and (max-width: 992px) {
    //  height: 40px;
    //  width: 40px;
    //}

    .image {
      max-height: 32px;
      max-width: 32px;
      height: auto;
      width: auto;

      @media screen and (max-width: 992px) {
        height: 22px;
        width: 22px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    flex-direction: row;
    .competition-banner {
      width: 45%;
    }
    .main-row {
      height: 100% !important;
      .team-info-col {
        flex-direction: row;
        margin-top: 40px !important;

        .vs-div {
          width: 10%;
          text-align: center;
        }
      }
    }
    .button-pronostico {
      margin-top: 15px;
      max-height: 46px;
      .text-button-cc {
        a {
          padding: 0 15px !important;
        }
      }
    }
  }
  .competition-banner-container {
    display: flex !important;
    margin: 0;
    position: relative;
    .competition-banner {
      position: absolute;
      top: -15px;
      padding: 8px;
      background: #3c3c3c;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      span {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        color: white;
      }
    }
  }
  .main-row {
    //height: 155px;
    justify-content: space-between;
    .competitionBannerInfoContainer {
      display: none;
    }
    .bookmakers-container-col {
      display: flex;
      flex-direction: column;

      .bookmaker-container-title-value {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        padding: 5px;
        background: #eaeaea;
        margin: 0;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
        span {
          color: #4a4a4a;
        }
      }
      .bookmakers-container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        background: white;
        padding: 5px;
        // border-bottom-left-radius: 10px;
        // border-bottom-right-radius: 10px;
        .bookmaker {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 5px;
          padding-bottom: 0;
          flex: 1;
          max-width: 32%;
          .bookmaker-image-div {
            padding: 5px;
            img {
              border-radius: 10px;
            }
          }
          .bookmaker-value {
            color: black;
            span {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
        .bookmaker:last-child {
          border-right: none;
        }
      }
    }
    .button-pronostico {
      display: flex;
      align-self: center;
      justify-content: center;
    }
    .match-info-col {
      flex-direction: column;
      display: none;
    }
    .competition-col {
      display: none;
      .competition-div {
        display: flex;
      }
    }
    .team-info-col {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      .teams-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .team-name {
          margin-top: 10px;
          text-align: center;
          width: 100px;
          color: rgb(136, 136, 136);
          font-size: 16px;
        }
      }
    }
  }
}
.prediction-match-row-cc:hover {
  background: #f7f7f7;
  //.competition-banner {
  //  span {
  //    color: #4a4a4a;
  //  }
  //}
  .bookmaker-value {
    color: black;
  }
  .match-info-col {
    color: #4a4a4a;
  }
  .competition-div {
    color: #b8b8b8;
  }
}

// new text for competitions
.competitionDescriptionContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  a {
    color: #f4c400 !important;
  }
}

.backoffice-form-competition {
  .label-country-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .image-col {
    display: flex;
    justify-content: flex-end;
  }
}

// style for new table on the bookmakers review page

.bookmakerReviewCompetitionTableContainer {
  margin: 10px 0 10px 0 !important;

  @media screen and (max-width: 576px) {
    overflow-x: auto;
    table {
      thead {
        tr {
          th {
            font-size: 14px !important;
          }
        }
      }
      tbody {
        box-shadow: none !important;
        border: 1px solid rgba(0, 0, 0, 0.3) !important;
        border-top: none !important;
        tr {
          td {
            text-align: start;
            font-size: 12px !important;
          }
        }
      }
    }
  }
  table {
    thead {
      tr {
        th {
          background-color: #f4c312;
          height: 3em;
          font-weight: 800;
          font-size: 17px;
          color: black;
        }
        th:first-child {
          border-top-left-radius: 10px;
        }
        th:last-child {
          border-top-right-radius: 10px;
          border-right: none;
        }
      }
    }
    tbody {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      tr {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .bookmakerReviewCompetitionTableContainer::-webkit-scrollbar-thumb {
    background-color: #adb5bd;
  }
  .bookmakerReviewCompetitionTableContainer::-webkit-scrollbar {
    background-color: transparent !important;
    height: 5px !important;
  }
}

// redirection modal style

.textButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.goRedirectPageContainer {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  img {
    object-fit: contain;
  }
  .redirectionModalContainer {
    z-index: 3;
    position: absolute;
    border: 1px solid white;
    top: 15%;
    background-color: white;
    border-radius: 20px;
    margin: 0 30%;
    .redirectionModalContainerTitle {
      background: linear-gradient(90deg, #f4c20f 42.52%, rgba(237, 204, 86, 0) 94.5%);
      border-top-left-radius: 20px;
      padding: 10px;
    }
    .handStopiconWrapper {
      background-color: #cf6e6e;
      border-radius: 10px;
      margin: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: auto;
      padding: 10px;
    }
    .redirectModalContentContainer {
      padding: 15px;
      .ant-progress-outer {
        padding-right: 0 !important;
      }
      .ant-progress-text {
        display: none !important;
      }
      .redirectBtnsContainer {
        margin-top: 5px;
        margin-bottom: 25px;

        display: flex;
        justify-content: flex-end;
        margin-top: 25px;
        .stayOnCalcioBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          border-color: unset !important;
          background-color: #a1a1a1 !important;
          border-radius: 20px;
          margin-right: 20px;
          padding: 20px !important;
        }
        .goToSiteBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          border-color: unset !important;
          border-radius: 20px;
          background-color: #f4c20f;
          padding: 20px !important;
        }
        .goToSiteBtn:hover {
          span,
          a {
            color: white;
          }
        }
      }
      h2 {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.2rem;
      }
    }
    @media screen and (max-width: 576px) {
      top: 5%;
      margin: 20px;
    }
  }
}

.goRedirectPageContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #dcb732 -2.28%, rgba(246, 223, 142, 0) 97.67%);
  z-index: 1;
  pointer-events: none;
}

// nuovo stile newsletter

.newsletterMainContainer {
  position: relative;
  background-image: url("../src/app/assets/images/newsletterContainer.png");
  background-repeat: no-repeat;
  background-size: cover;
  .newsletterHeaderContainer {
    .newsletterHeaderTitleContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      .newsletterHeaderTitle {
        font-size: 2.4rem;
        font-weight: 500;
        color: white;
        font-style: italic;
        text-align: center;
        strong {
          font-weight: 600;
        }
      }
    }
    .newsletterHeaderInfoContainer {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: white;
      padding: 0 20px 15px 20px;
      .newsletterHeaderInfo {
        font-size: 2rem;
        font-weight: 500;
        &.textac {
          align-self: center;
        }
      }
      .newsletterHeaderImageContainer {
        position: absolute;
        top: 5%;
        right: 0;
      }
      .newsletterHeaderInfoList {
        ul {
          padding-left: 20px;
          list-style-type: disc;
          margin-top: 20px;
          font-size: 1.5rem;
          li {
            list-style-type: disc;
          }
        }
      }
    }
  }
  .newsletterBodyMainContainer {
    background-color: #f7f7f7;
    margin: 0 20px 20px 20px;
    border-radius: 10px;
    .newsletterNameInputContainer {
      padding: 20px 20px 0 20px;
      .newsletterNameInput,
      .newsletterEmailInput {
        border-radius: 10px;
        height: 45px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          font-size: 1.4rem;
          padding: 0 10px;
          width: 100%;
          border: none;
        }
        input:focus-visible {
          outline: none;
        }
      }
      .newsletterEmailInput {
        margin-top: -10px;
      }
    }
    .newsletterChecksMainContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0 20px 0 20px;
      .hs-widget-input-div {
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        .hs-widget-input-value-div {
          font-size: 1.4rem;
          font-weight: 500;
        }
        .newsletterCheckBox {
          display: flex;
          align-self: flex-start;
          input {
            width: 1.3em;
            height: 1.3em;
            background-color: white;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #ddd;
            appearance: none;
            outline: none;
            cursor: pointer;
            margin-right: 10px;
          }
          input:checked {
            background-color: #f4c312;
            border: 1px solid #f4c312;
          }
        }
      }
    }
  }
  .newsletterFooterMainContainer {
    height: 5px;
  }
  .text-button-cc.yellow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 0;
    button {
      width: 100%;
      background: #f4c312;
      box-shadow: 0 4px 0 #d5a806;
      outline: none;
      border: none;
      height: 45px;
      text-align: center;
      border: none;
      min-width: 160px;
      align-items: center;
      justify-content: center;
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #4a4a4a;
      border-radius: 10px;
    }
    button:hover {
      background-color: #dfb51d;
    }
  }
  .inputValidationErrors {
    color: red;
    margin-left: 20px;
  }
}
.newsletterMainContainer.horizontal {
  background-image: url("../src/app/assets/images/newsletterbghorizontal.png");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  .newsletterHeaderContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    .newsletterHeaderImageContainer {
      position: absolute;
      left: -15px;
      width: 30%;
    }
    .newsletterTitleAndInfoContainer {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 55%;
      .newsletterHeaderInfoContainer {
        padding: 0;
      }
    }
  }
  .newsletterBodyMainContainer {
    margin: 15px;
    .newsletterInputsContainerHorizontal {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      .newsletterNameInputContainer {
        padding: 20px 0 0 20px;
        width: 100%;
        .newsletterEmailInput {
          margin-top: 0;
        }
      }
      .newsletterNameInputContainer:has(.newsletterEmailInput) {
        margin-right: 15px;
      }
      .inputValidationErrors {
        color: red;
        margin-left: 0px;
      }
    }
    .newsletterChecksMainContainer {
      justify-content: flex-start;
      align-items: flex-start;
      .hs-widget-input-div {
        margin-top: 10px;
        .newsletterCheckBox {
          align-self: center;
        }
        .hs-widget-input-value-div {
          font-size: 1rem;
        }
      }
    }
    .hs-widget-newsletter-button-div {
      padding: 0;
      .text-button-cc {
        padding: 15px;
      }
    }
  }
}

.privacy-container {
  h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  p {
    font-size: 1.5rem;
  }
  a {
    color: #f4c400 !important;
  }
}

.matchesWithoutJs {
  display: none;
}

.bookmakers-container-prediction-quotes {
  display: flex;
  flex-direction: column;

  &.yellow {
    .bookmaker-container-title-value {
      background-color: #ffc107;
    }
  }
  .bookmaker-container-title-value {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    background: #eaeaea;
    margin: 0;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    span {
      color: #4a4a4a;
    }
  }
  .bookmakers-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    background: white;
    padding: 5px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    border: 1px solid #eaeaea;
    .bookmaker {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      padding-bottom: 0;
      flex: 1;
      max-width: 32%;
      .bookmaker-image-div {
        padding: 5px;
      }
      .bookmaker-value {
        color: black;
        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .bookmaker:last-child {
      border-right: none;
    }
  }
}

// new cta to stats

.match-to-stats-cta {
  margin-left: 15px;
  a {
    text-decoration: underline;
  }
  @media screen and (max-width: 576px) {
    .text-button-cc {
      a {
        min-width: 95px;
        font-size: 12px;
      }
    }
  }
}

.match-result {
  margin-left: 10%;
}
// Stile H2 per titoli statistiche
.competitionSeasonLabel {
  h2 {
    font-size: 14px;
    font-weight: 400;
  }
}

// Nuova pagina quote maggiorate

.quoteMaggiorateContainer {
  padding: 0 2px !important;
  .bonusQuotasListContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .bonusQuotaCard {
      margin: 10px;
      width: calc(50% - 20px);
      .card {
        border-radius: 10px;
        border: 1px solid rgb(245, 245, 245);
        display: flex;
        background-color: rgb(245, 245, 245);
        .cardHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgb(245, 245, 245);
          .cardImage {
            width: 100%;
            img {
              border-top-left-radius: 10px;
            }
          }
          .cardExpirationDate {
            padding: 5px;
            font-size: 12px;
            text-align: center;
          }
        }
        .cardTitle {
          font-size: 16px;
          padding: 10px;
          background-color: white;
          height: 110px;
          display: flex;
          justify-content: center;
          align-items: center;
          .cardTitleContent {
            p {
              margin: 0;
            }
          }
        }
        .claimButton {
          padding: 20px 60px;
        }
      }
    }
    .bonusQuotaCard.expired {
      opacity: 50%;
      cursor: not-allowed;
      .claimButton {
        a {
          cursor: not-allowed;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .bonusQuotaCard {
        width: calc(50% - 20px);

        .card {
          .cardHeader {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            .cardImage {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              border-radius: 10px;
            }
          }
          .cardTitle {
            height: 150px;
          }
          .claimButton {
            padding: 20px 30px;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .bonusQuotaCard {
        width: calc(50% - 20px);
      }
    }
    @media screen and (max-width: 480px) {
      .bonusQuotaCard {
        width: calc(100% - 20px);
      }
    }
  }
}
.bonusQuotaPageDescription {
  font-size: 16px;
  text-align: justify;
  margin-top: 15px !important;
  a {
    color: #f4c400 !important;
  }
}
.textButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .text-button-bonus-cc {
    padding: 0 !important;
    width: 100%;
    .yellow {
      height: 45px;
      text-align: center;
      border: none;
      align-items: center;
      justify-content: center;
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #4a4a4a;
      border-radius: 10px;
    }
  }
}

// nuovo componente per la description delle squadre

.team-description-container {
  margin: 20px 0 20px 0 !important;

  .team-description {
    .teamDescriptionContainer {
      .teamDescription {
        font-size: 16px;
        text-align: justify;
        a {
          color: #f4c400 !important;
        }
        p {
          margin: 0;
        }
      }
    }
  }
}

// nuova modale pagina scommesse

.tutorialModalContainer {
  .ant-modal-content {
    border-radius: 30px;
    .ant-modal-header {
      background: linear-gradient(90deg, #f4c20f 42.52%, rgba(237, 204, 86, 0) 94.5%);
      border-radius: 30px 0;
      border-bottom: none;
    }
  }
  .tutorialButtonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .tutorialButton {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: unset !important;
      border-radius: 20px;
      background-color: #f4c20f;
      padding: 20px !important;
      color: white;
    }
    .tutorialButton:hover {
      color: white !important;
    }
    .tutorialButton:focus:not(:focus-visible) {
      color: white !important;
    }
  }
  .tutorialModal {
    margin-top: 30px;

    .tutorialHeader {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-bottom: 10px;
    }
    .tutorialImage.mobileView {
      max-height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 400px;
        margin: 0 auto;
      }
    }
    .tutorialNavigationContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .right {
        transform: none;
      }

      .arrow {
        font-size: 24px;
        cursor: pointer;
        border: none;
      }

      .dots {
        display: flex;
        gap: 10px;

        .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #ccc;
          cursor: pointer;

          &.active {
            background-color: #f4c20f;
          }
        }
      }
    }
  }
}

.tutorialModalContainer.mobileView {
  max-width: calc(90vw - 16px) !important;

  @media screen and (max-width: 767px) {
    .ant-modal {
      max-width: calc(90vw - 16px);
      margin: 8px auto;
    }
  }
}

// nuovo stile ranking giocatori pagina squadre
.playerCompetitionSeasonStatsTitle {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin: 20px 0 !important;
}
.bestPlayersByContainer {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  margin: 15px 0;
  .headerContainer {
    padding: 0 20px;
    .parameterName {
      display: flex;
      justify-content: flex-end;
    }
  }
  .playerStatContainer {
    margin-top: 10px;
    .playerParameterRow {
      padding: 10px 0;
      margin: 0 10px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .playerInfo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .playerImage {
        margin-right: 10px;
        img {
          max-height: 44px;
          max-width: 44px;
          height: auto;
          width: auto;
          overflow: hidden;
          border: 3px solid #f4c312;
          border-radius: 50%;
        }
      }
    }
    .playerParameterRow.even {
      background-color: white;
    }
  }
}

.menuSplitter {
  font-weight: 500;
}

.filterSelector {
  margin: 5px 0 !important;
  &.ant-select {
    .ant-select-selector {
      border: 1px solid #888 !important;
      border-radius: 5px !important;
      background-color: transparent !important;
    }
  }
}

.hs-filter-menu {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.filterMobileContainer {
  display: block !important;
  .championshipsRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 5px;
  }
}

.footerTextContainer {
  h1 {
    margin: 2rem 0;
  }
}

.newMenuContainer {
  display: flex;
}

.competitionsQuotePageContainer {
  @media screen and (max-width: 992px) {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-bottom: 2px solid #eaeaea !important;
    .bet-competition {
      .type {
        color: #4a4a4a;
        background: transparent;
        border-radius: unset;
        font-weight: 500;
        border-bottom: 3px solid transparent;
        &:hover {
          font-weight: 600;
          border-bottom: 3px solid #f4c312;
        }
        &.selected {
          font-weight: 600;
          border-bottom: 3px solid #f4c312;
        }
      }
    }
  }
}
.selectedBookmakerSidewidget.mobile {
  display: none;
  @media screen and (max-width: 992px) {
    padding: 10px;
    display: block;
    position: relative;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    .bodyBookmakerInfo {
      border-top: 2px solid darkgray;
    }
  }
}

.whiteSpace {
  display: none;
  @media screen and (max-width: 992px) {
    z-index: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    position: sticky;
    top: 0;
    height: 10px;
    width: 100%;
  }
}

.headerBookmakerInfoContainer {
  display: none;
  @media screen and (max-width: 992px) {
    z-index: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background: #eaeaea;
    border-radius: 10px;
    position: sticky;
    top: 9px;
    margin-bottom: -8px;

    .bookmakerReviewContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .numberAndStarReviewContainer {
        display: none;
      }
      .bonusDescriptionDiv {
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0;
        flex-direction: column;
        display: flex;
        align-items: center;
        span {
          font-weight: 700;
        }
      }
      .tosite-button {
        width: 65%;
      }
    }
    .bookmakerImageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        max-height: 100%;
        max-width: 100%;
        vertical-align: middle;
        border-radius: 10px;
      }
    }
  }
}

#squadre {
  scroll-margin-top: 130px;
  @media screen and (max-width: 992px) {
    scroll-margin-top: 200px;
  }
}
#classifica,
#giornata,
#marcatori {
  scroll-margin-top: 140px;
  @media screen and (max-width: 992px) {
    scroll-margin-top: 220px;
  }
}
.selectantd {
  border: 1px solid #888 !important;
  @media screen and (max-width: 992px) {
    border: unset !important;
    .ant-select-selector {
      border: unset !important;
      border-radius: unset !important;
      background-color: transparent !important;
      color: black !important;
    }
    .ant-select-arrow {
      color: black !important;
    }
  }
  .ant-select-selector {
    border-radius: 5px !important;
    background-color: transparent !important;
    color: white;
  }
  @media screen and (min-width: 992px) {
    .ant-select-arrow {
      color: white !important;
    }
  }
}
.group-standing-tab-new {
  margin-bottom: 0;
  .option-tab {
    background-color: #eaeaea;
  }
}

.new-groupStanding {
  margin-top: 0 !important;
  .container-div {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.group-standing-tab-new {
  .container-div {
    background-color: #eaeaea;
    border-top-right-radius: 10px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    .option-tab {
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.newCompetitionTabs {
  margin-bottom: -8px;
  @media screen and (max-width: 992px) {
    margin-bottom: 1rem;
  }
  .container-div {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-left: 21.7%;
    @media screen and (max-width: 1400px) {
      margin-left: 25.2%;
    }
    @media screen and (max-width: 1200px) {
      margin-left: 30.2%;
    }
    @media screen and (max-width: 992px) {
      margin-left: 30.2%;
      margin-bottom: 1rem;
    }
    .option-tab {
      &.active {
        background-color: #eaeaea;
      }
      background-color: transparent;
    }
  }
}
.pageTitle {
  margin: 1rem 0 !important;
}
.headerSeoContainer {
  h1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
.competition-tabs-cc.competition-new-tabs {
  @media screen and (min-width: 992px) {
    display: none;
  }
}
.divergentMatchContainer {
  padding: 1rem;
}
.scommesseSeo {
  padding: 0 2px !important;
}
.paddingLeftZero {
  padding-left: 0 !important;
}
.paddingRightZero {
  padding-right: 0 !important;
}
.paddingZeroTwo {
  padding: 0 2px !important;
}
.paddingOFive {
  padding: 0 5px !important;
}
.championshipColumn {
  @media screen and (max-width: 992px) {
    padding: 0 !important;
  }
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}
.container {
  @media screen and (min-width: 992px) {
    padding: 0 !important;
  }
}
.newButtonStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    margin-top: 12px;
    border-radius: 10px;
    padding: 1.5rem 2.5rem;
    background-color: var(--bs-yellow) !important;
    font-size: 16px;
    font-weight: 700;
  }
}

.matchesDateContainer {
  margin: 0 !important;
}
.card-body {
  .guide-list-date-div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }
}
