.container {
  margin: 0 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-direction: column;

  .card {
    background-color: #eaeaea;
    margin: 0;
    border-radius: 5px;
    min-width: 100%;

    .body {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      gap: 10px;

      .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        gap: 10px;

        .stars {
          .condition {
            margin-left: 5px;
          }
        }

        .stars,
        .image {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .review {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        gap: 5px;
        width: 100%;

        .text {
          text-align: center;
          line-height: 1.1;
          span {
            font-weight: 600;
          }
          .extra {
            font-weight: 500;
          }
        }

        .button {
          width: 100%;
        }
      }
    }
  }
}
